import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "1530px",
  height: "100%",
  margin: "0 auto",
  padding: "0 20px",

  [theme.upDown("lg", "xl")]: {
    maxWidth: "1230px",
  },

  [theme.upDown("sm", "md")]: {
    maxWidth: "830px",
  },

  [theme.upDown("md", "lg")]: {
    maxWidth: "1040px",
  },
}));

export const Content = styled("div")(({ theme }) => ({
  width: "100%",

  "& h3": {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },

  "& > div": {
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    gap: "60px",

    "&  a": {
      transition: "color .3s linear",
      color: "blue",

      "&:hover": {
        textDecoration: "underline",
      },
    },

    "& h1": {
      marginBottom: "10px",
      fontSize: "22px",
      fontWeight: "bold",
    },

    "& h2": {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
    },

    [theme.down("lg")]: {
      flexDirection: "column",
      gap: "0",
      marginBottom: "10px",
    },
  },
}));

export const MainInformation = styled("div")(({ theme }) => ({
  "& div": {
    marginBottom: "10px",
    "& article": {
      "& p": {
        fontSize: "15px",
        lineHeight: "1.5",
        paddingBottom: "10px",
        marginBottom: "10px",
      },

      "& ul": {
        paddingBottom: "10px",
        fontSize: "15px",
        lineHeight: "1.5",

        "& li": {
          listStylePosition: "inside",
        },
      },
      "& figure": {
        margin: "20px 0",
        textAlign: "center",

        "& img": {
          width: "100%",
          maxWidth: "800px",
          height: "auto",
          borderRadius: "10px",
        },

        "& figcaption": {
          fontSize: "0.9em",
          color: "#66666",
        },
      },

      "& table": {
        width: "100%",
        maxWidth: "800px",
        borderCollapse: "collapse",
        marginBottom: "1em",

        "& th, td": {
          border: "1px solid #ccc",
          padding: "8px",
        },
      },
    },
  },
}));

export const OtherArticles = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: "20px",

  border: "1px solid #e0e0e0",
  borderRadius: "10px",
}));

export const Lists = styled("ul")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(0,1fr))",
  rowGap: "8px",
  width: "100%",
  listStyle: "none",

  "& li": {
    display: "flex",
    width: "100%",
    alignItems: "center",

    "& img": {
      width: "24px",
      height: "24px",
      marginRight: "10px",
    },

    "& a": {
      color: [theme.palette.hoverButtonBg],
      fontSize: "16px",
      textDecoration: "underline",

      "&:hover": {
        textDecoration: "none",
      },
    },
  },

  [theme.down("md")]: {
    gridTemplateColumns: "repeat(2, minmax(0,1fr))",

    "& li": {
      fontSize: "12px",
    },
  },
}));

export const Card = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "350px",
  height: "100%",
  border: "2px solid #e0e0e0",
  borderRadius: "10px",
  padding: "20px 30px",

  "& div": {
    "& h4": {
      margin: "10px 0",
      fontSize: "16px",
      fontWeight: "bold",
    },

    "& p": {
      fontSize: "15px",
      letterSpacing: "0.5px",
    },
  },
}));

export const Image = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBottom: "15px",
  borderBottom: "1px solid #e0e0e0",
}));

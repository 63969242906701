const metaKeywords = {
  ru: {
    homePage: "",
    aeroflotInfo:
      "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
    airastanaInfo:
      "Air Astana, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    aircairoInfo:
      "Air Cairo, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    airchinaInfo:
      "Air China, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    airmanasInfo:
      "Air Manas, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    asianaAirlinesInfo:
      "Asiana Airlines, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    aviaTrafficInfo:
      "Avia Traffic Company, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azalInfo:
      "AZAL, Azerbaijan Airlines, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azimuthInfo:
      "Azimuth, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    azurairInfo:
      "Azur Air, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    belaviaInfo:
      "Belavia, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet sankt peterburg",
    uzairways:
      "aviabilet, авиабилет, Uzbekistan Airways, авиабилеты Ташкент, рейсы Узбекистан, купить билет на самолет, авиаперелеты",
    s7Info:
      "viabilet, авиабилет, S7 Airlines, авиабилеты Ташкент, рейсы S7, авиабилеты Москва, купить авиабилет дешево",
    turkishInfo:
      "aviabilet, авиабилет, Turkish Airlines, авиабилеты Ташкент, рейсы Turkish Airlines, авиабилеты Стамбул, купить авиабилет дешево",
    qanotsharqInfo:
      "qanot sharq, авиабилеты, авиабилеты ташкент, дешёвые авиабилеты, билеты узбекистан, билеты москва ташкент, qanot sharq расписание",
    uralInfo:
      "aviabilet, авиабилет, Ural Airlines, авиабилеты Ташкент, рейсы Ural Airlines, авиабилеты Москва, купить авиабилет дешево",
    pobedaInfo:
      "pobeda, авиабилеты, авиабилеты ташкент, дешёвые авиабилеты, билеты узбекистан, билеты москва ташкент, pobeda расписание",
    spicejetInfo:
      "aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet hindiston, aviabilet delhi, spicejet uzbekistan, spicejet aviabilet, arzon aviabilet, авиабилеты Индия, авиабилеты Ташкент Дели",
      airindiaInfo: "Air India, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Дели, авиабилет Мумбаи",
      centrumairInfo: "Centrum Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Ташкент, авиабилет Санкт-Петербург",
      chinasouthernInfo: "China Southern, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Пекин, авиабилет Гуанчжоу",
      emiratesInfo: "Emirates, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Дубай, авиабилет Ташкент",
      ikarInfo: "Ikar, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Сочи, авиабилет Красноярск",
      sereneairInfo: "Serene Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Карачи, авиабилет Исламабад, авиабилет Лахор",
      flydubaiInfo: "Flydubai, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Дубай, авиабилет Самарканд",
      gulfairInfo: "Gulf Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Манама, авиабилет Дубай",
      georgianairwaysInfo: "Georgian Airways, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Тбилиси, авиабилет Батуми",
      iraqiairwaysInfo: "Iraqi Airways, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Багдад, авиабилет Москва, авиабилет Дубай",
      iranairInfo: "Iran Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Тегеран, авиабилет Москва, авиабилет Стамбул",
      jazeeraairInfo: "Jazeera Airways, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Кувейт, авиабилет Дубай",
      armenianairlinesInfo: "Armenian Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Ереван, авиабилет Гюмри",
      koreanairInfo: "Korean Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Сеул, авиабилет Москва, авиабилет Ташкент",
      kuwaitairInfo: "Kuwait Airways, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Кувейт, авиабилет Дубай",
      lufthansaInfo: "Lufthansa, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Франкфурт, авиабилет Берлин",
      middleeasternairlinesInfo: "Middle Eastern Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Бейрут, авиабилет Дубай",
      mywayairlinesInfo: "Myway Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Тбилиси, авиабилет Батуми",
      egyptairInfo: "EgyptAir, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Каир, авиабилет Шарм-эль-Шейх",
      nordwindairlinesInfo: "Nordwind Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Санкт-Петербург, авиабилет Сочи",
      salamairInfo: "Salam Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Маскат, авиабилет Дубай",
      airblueInfo: "Airblue, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Исламабад, авиабилет Лахор, авиабилет Карачи",
      pegasusairlinesInfo: "Pegasus Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Москва, авиабилет Стамбул, авиабилет Анталия",
      pakistanairlineInfo: "Pakistan Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Карачи, авиабилет Лахор, авиабилет Исламабад",
      qeshmairlinesInfo: "Qeshm Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Тегеран, авиабилет Дубай, авиабилет Стамбул",
      syrianairInfo: "Syrian Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Дамаск, авиабилет Москва, авиабилет Дубай",
      kamairInfo: "Kam Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Кабул, авиабилет Москва, авиабилет Дубай",
      saudiaairlinesInfo: "Saudia Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Джидда, авиабилет Эр-Рияд, авиабилет Москва",
      somonairInfo: "Somon Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Душанбе, авиабилет Москва, авиабилет Санкт-Петербург",
      turkmenistanairlinesInfo: "Turkmenistan Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Ашхабад, авиабилет Москва, авиабилет Дубай",
      vistaraairlinesInfo: "Vistara Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Дели, авиабилет Мумбаи, авиабилет Москва",
      mahanairInfo: "Mahan Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Тегеран, авиабилет Москва, авиабилет Дубай",
      omanairInfo: "Oman Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Маскат, авиабилет Москва, авиабилет Дубай",
      flynasairlinesInfo: "Flynas Airlines, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Джидда, авиабилет Эр-Рияд, авиабилет Дубай",
      kishairInfo: "Kish Air, авиабилет, авиабилет цены, авиабилет дешевые, авиабилет Тегеран, авиабилет Дубай, авиабилет Шираз",
      indigoInfo: "IndiGo Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Дели, авиабилет Мумбаи, авиабилет Калькутта, купить авиабилеты, авиабилеты IndiGo",
      nepalAirlinesInfo: "Nepal Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Катманду, авиабилет Покхара, авиабилет в Непал, купить авиабилеты, авиабилеты Nepal Airlines",
      bimanBangladeshInfo: "Biman Bangladesh Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Дакка, авиабилет Читтагонг, авиабилет в Бангладеш, купить авиабилеты, авиабилеты Biman Bangladesh",
      sriLankanAirlinesInfo: "SriLankan Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Коломбо, авиабилет в Шри-Ланку, купить авиабилеты, авиабилеты SriLankan Airlines",
      chinaEasternInfo: "China Eastern Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Пекин, авиабилет Шанхай, авиабилет в Китай, купить авиабилеты, авиабилеты China Eastern",
      hainanAirlinesInfo: "Hainan Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Хайнань, авиабилет в Китай, купить авиабилеты, авиабилеты Hainan Airlines",
      xiamenAirlinesInfo: "Xiamen Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Сямынь, авиабилет в Китай, купить авиабилеты, авиабилеты Xiamen Airlines",
      beijingCapitalInfo: "Beijing Capital Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Пекин, авиабилет в Китай, купить авиабилеты, авиабилеты Beijing Capital Airlines",
      luckyAirInfo: "Lucky Air, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет в Китай, купить авиабилеты, авиабилеты Lucky Air",
      miatMongolianInfo: "MIAT Mongolian Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Улан-Батор, авиабилет в Монголию, купить авиабилеты, авиабилеты MIAT Mongolian",
      airSerbiaInfo: "Air Serbia, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Белград, авиабилет в Сербию, купить авиабилеты, авиабилеты Air Serbia",
      airMontenegroInfo: "Air Montenegro, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Подгорица, авиабилет в Черногорию, купить авиабилеты, авиабилеты Air Montenegro",
      taromInfo: "Tarom, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Бухарест, авиабилет в Румынию, купить авиабилеты, авиабилеты Tarom",
      bulgariaAirInfo: "Bulgaria Air, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет София, авиабилет в Болгарию, купить авиабилеты, авиабилеты Bulgaria Air",
      aegeanAirlinesInfo: "Aegean Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Афины, авиабилет в Грецию, купить авиабилеты, авиабилеты Aegean Airlines",
      ukraineIntlAirlinesInfo: "Ukraine International Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Киев, авиабилет в Украину, купить авиабилеты, авиабилеты UIA",
      windroseAirlinesInfo: "Windrose Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет в Украину, купить авиабилеты, авиабилеты Windrose",
      skyUpAirlinesInfo: "SkyUp Airlines, авиабилет, авиабилеты цены, авиабилеты дешевые, авиабилет Киев, авиабилет в Украину, купить авиабилеты, авиабилеты SkyUp",
  },
  en: {
    homePage: "",
    aeroflotInfo:
      "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
    spicejetInfo:
      "aviabilet, aviabilet narxlari, aviabilet arzon, aviabilet hindiston, spicejet uzbekistan, spicejet toshkent delhi, hindiston aviabilet, arzon aviachipta, Dehli reyslari",
      iraeroInfo: "Iraero, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Irkutsk, flights to Yakutsk",
      airindiaInfoEn: "Air India, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Delhi, flights to Mumbai",
      centrumairInfoEn: "Centrum Air, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Tashkent, flights to Saint Petersburg",
      chinasouthernInfoEn: "China Southern, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Beijing, flights to Guangzhou",
      emiratesInfoEn: "Emirates, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Dubai, flights to Tashkent",
      ikarInfoEn: "Ikar, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Sochi, flights to Krasnoyarsk",
      sereneairInfoEn: "Serene Air, airline ticket, flight ticket prices, cheap tickets, flights to Karachi, flights to Islamabad, flights to Lahore",
      flydubaiInfoEn: "Flydubai, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Dubai, flights to Samarkand",
      gulfairInfoEn: "Gulf Air, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Manama, flights to Dubai",
      georgianairwaysInfoEn: "Georgian Airways, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Tbilisi, flights to Batumi",
      iraqiairwaysInfoEn: "Iraqi Airways, airline ticket, flight ticket prices, cheap tickets, flights to Baghdad, flights to Moscow, flights to Dubai",
      iranairInfoEn: "Iran Air, airline ticket, flight ticket prices, cheap tickets, flights to Tehran, flights to Moscow, flights to Istanbul",
      jazeeraairInfoEn: "Jazeera Airways, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Kuwait, flights to Dubai",
      armenianairlinesInfoEn: "Armenian Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Yerevan, flights to Gyumri",
      koreanairInfoEn: "Korean Air, airline ticket, flight ticket prices, cheap tickets, flights to Seoul, flights to Moscow, flights to Tashkent",
      kuwaitairInfoEn: "Kuwait Airways, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Kuwait, flights to Dubai",
      mywayairlinesInfoEn: "Myway Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Tbilisi, flights to Batumi",
      middleeasternairlinesInfoEn: "Middle Eastern Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Beirut, flights to Dubai",
      lufthansaInfoEn: "Lufthansa, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Frankfurt, flights to Berlin",
      egyptairInfoEn: "EgyptAir, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Cairo, flights to Sharm El Sheikh",
      nordwindairlinesInfoEn: "Nordwind Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Saint Petersburg, flights to Sochi",
      salamairInfoEn: "Salam Air, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Muscat, flights to Dubai",
      airblueInfoEn: "Airblue, airline ticket, flight ticket prices, cheap tickets, flights to Islamabad, flights to Lahore, flights to Karachi",
      pegasusairlinesInfoEn: "Pegasus Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Moscow, flights to Istanbul, flights to Antalya",
      pakistanairlineInfoEn: "Pakistan Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Karachi, flights to Lahore, flights to Islamabad",
      qeshmairlinesInfoEn: "Qeshm Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Tehran, flights to Dubai, flights to Istanbul",
      syrianairInfoEn: "Syrian Air, airline ticket, flight ticket prices, cheap tickets, flights to Damascus, flights to Moscow, flights to Dubai",
      kamairInfoEn: "Kam Air, airline ticket, flight ticket prices, cheap tickets, flights to Kabul, flights to Moscow, flights to Dubai",
      saudiaairlinesInfoEn: "Saudia Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Jeddah, flights to Riyadh, flights to Moscow",
      somonairInfoEn: "Somon Air, airline ticket, flight ticket prices, cheap tickets, flights to Dushanbe, flights to Moscow, flights to Saint Petersburg",
      turkmenistanairlinesInfoEn: "Turkmenistan Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Ashgabat, flights to Moscow, flights to Dubai",
      vistaraairlinesInfoEn: "Vistara Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Delhi, flights to Mumbai, flights to Moscow",
      mahanairInfoEn: "Mahan Air, airline ticket, flight ticket prices, cheap tickets, flights to Tehran, flights to Moscow, flights to Dubai",
      omanairInfoEn: "Oman Air, airline ticket, flight ticket prices, cheap tickets, flights to Muscat, flights to Moscow, flights to Dubai",
      flynasairlinesInfoEn: "Flynas Airlines, airline ticket, flight ticket prices, cheap tickets, flights to Jeddah, flights to Riyadh, flights to Dubai",
      kishairInfoEn: "Kish Air, airline ticket, flight ticket prices, cheap tickets, flights to Tehran, flights to Dubai, flights to Shiraz",
      indigoInfo: "IndiGo Airlines, airfare, ticket prices, cheap tickets, Delhi ticket, Mumbai ticket, Kolkata ticket, buy tickets, IndiGo tickets",
      nepalAirlinesInfo: "Nepal Airlines, airfare, ticket prices, cheap tickets, Kathmandu ticket, Pokhara ticket, Nepal ticket, buy tickets, Nepal Airlines tickets",
      bimanBangladeshInfo: "Biman Bangladesh Airlines, airfare, ticket prices, cheap tickets, Dhaka ticket, Chittagong ticket, Bangladesh ticket, buy tickets, Biman Bangladesh tickets",
      sriLankanAirlinesInfo: "SriLankan Airlines, airfare, ticket prices, cheap tickets, Colombo ticket, Sri Lanka ticket, buy tickets, SriLankan Airlines tickets",
      chinaEasternInfo: "China Eastern Airlines, airfare, ticket prices, cheap tickets, Beijing ticket, Shanghai ticket, China ticket, buy tickets, China Eastern tickets",
      hainanAirlinesInfo: "Hainan Airlines, airfare, ticket prices, cheap tickets, Hainan ticket, China ticket, buy tickets, Hainan Airlines tickets",
      xiamenAirlinesInfo: "Xiamen Airlines, airfare, ticket prices, cheap tickets, Xiamen ticket, China ticket, buy tickets, Xiamen Airlines tickets",
      beijingCapitalInfo: "Beijing Capital Airlines, airfare, ticket prices, cheap tickets, Beijing ticket, China ticket, buy tickets, Beijing Capital Airlines tickets",
      bulgariaAirInfo: "Bulgaria Air, airfare, ticket prices, cheap tickets, Sofia ticket, Bulgaria ticket, buy tickets, Bulgaria Air tickets",
      aegeanAirlinesInfo: "Aegean Airlines, airfare, ticket prices, cheap tickets, Athens ticket, Greece ticket, buy tickets, Aegean Airlines tickets",
      ukraineIntlAirlinesInfo: "Ukraine International Airlines, airfare, ticket prices, cheap tickets, Kyiv ticket, Ukraine ticket, buy tickets, UIA tickets",
      windroseAirlinesInfo: "Windrose Airlines, airfare, ticket prices, cheap tickets, Ukraine ticket, buy tickets, Windrose Airlines tickets",
      skyUpAirlinesInfo: "SkyUp Airlines, airfare, ticket prices, cheap tickets, Kyiv ticket, Ukraine ticket, buy tickets, SkyUp Airlines tickets",
  },
  uz: {
    homePage: "",
    aeroflotInfo:
      "Аэрофлот, aviabilet, aviabilet narxlari, aviabilet дешевые, aviabilet moskva, aviabilet narxlari toshkent, moskva aviabilet narxlari, купить авиабилеты, авиабилеты Аэрофлот",
    uzairways:
      "aviabilet, авиабилет, Uzbekistan Airways, авиабилеты Ташкент, рейсы Узбекистан, купить билет на самолет, авиаперелеты",
    s7Info: "aviabilet, aviabilet narxlari, S7 Airlines, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
    turkishInfo:
      "aviabilet, aviabilet narxlari, Turkish Airlines, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
    qanotsharqInfo:
      "qanot sharq, aviabilet, aviabilet narxlari, aviabilet tashkent, aviabilet moskva, aviabilet sankt peterburg, qanot sharq reyslari",
    uralInfo:
      "aviabilet, aviabilet narxlari, Ural Airlines, ural, aviabilet Toshkent, samolyot chiptalari, havo yo‘nalishlari",
    pobedaInfo:
      "pobeda, aviabilet, aviabilet narxlari, aviabilet tashkent, aviabilet moskva, aviabilet sankt peterburg, pobeda reyslari",
    spicejetInfo:
      "cheap flights, cheap tickets, SpiceJet Uzbekistan, SpiceJet Tashkent Delhi, flights to India, budget airline India, Tashkent to Delhi flights, affordable airfare, book flights online",
      airindiaInfo: "Air India, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Dehli reyslari, Mumbay reyslari",
      centrumairInfo: "Centrum Air, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Toshkent reyslari, Sankt-Peterburg reyslari",
      chinasouthernInfo: "China Southern, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Pekin reyslari, Guanchjou reyslari",
      emiratesInfo: "Emirates, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Dubay reyslari, Toshkent reyslari",
      ikarInfo: "Ikar, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Sochi reyslari, Krasnoyarsk reyslari",
      sereneairInfo: "Serene Air, aviabilet, aviabilet narxlari, arzon aviabilet, Karachi reyslari, Islamabad reyslari, Lahor reyslari",
      flydubaiInfo: "Flydubai, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Dubay reyslari, Samarqand reyslari",
      gulfairInfo: "Gulf Air, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Manama reyslari, Dubay reyslari",
      georgianairwaysInfo: "Georgian Airways, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Tbilisi reyslari, Batumi reyslari",
      iraqiairwaysInfo: "Iraqi Airways, aviabilet, aviabilet narxlari, arzon aviabilet, Bag‘dod reyslari, Moskva reyslari, Dubay reyslari",
      iranairInfo: "Iran Air, aviabilet, aviabilet narxlari, arzon aviabilet, Tehron reyslari, Moskva reyslari, Istanbul reyslari",
      jazeeraairInfo: "Jazeera Airways, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Quvayt reyslari, Dubay reyslari",
      armenianairlinesInfo: "Armenian Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Yerevan reyslari, Gyumri reyslari",
      koreanairInfo: "Korean Air, aviabilet, aviabilet narxlari, arzon aviabilet, Seul reyslari, Moskva reyslari, Toshkent reyslari",
      kuwaitairInfo: "Kuwait Airways, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Quvayt reyslari, Dubay reyslari",
      lufthansaInfo: "Lufthansa, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Frankfurt reyslari, Berlin reyslari",
      middleeasternairlinesInfo: "Middle Eastern Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Bayrut reyslari, Dubay reyslari",
      mywayairlinesInfo: "Myway Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Tbilisi reyslari, Batumi reyslari",
      egyptairInfo: "EgyptAir, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Qohira reyslari, Sharm-al-Shayx reyslari",
      nordwindairlinesInfo: "Nordwind Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Sankt-Peterburg reyslari, Sochi reyslari",
      salamairInfo: "Salam Air, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Maskat reyslari, Dubay reyslari",
      pegasusairlinesInfo: "Pegasus Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Moskva reyslari, Istanbul reyslari, Antalya reyslari",
      airblueInfo: "Airblue, aviabilet, aviabilet narxlari, arzon aviabilet, Islomobod reyslari, Lahor reyslari, Karachi reyslari",
      pakistanairlineInfo: "Pakistan Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Karachi reyslari, Lahor reyslari, Islomobod reyslari",
      qeshmairlinesInfo: "Qeshm Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Tehron reyslari, Dubay reyslari, Istanbul reyslari",
      syrianairInfo: "Syrian Air, aviabilet, aviabilet narxlari, arzon aviabilet, Damashq reyslari, Moskva reyslari, Dubay reyslari",
      kamairInfo: "Kam Air, aviabilet, aviabilet narxlari, arzon aviabilet, Kobul reyslari, Moskva reyslari, Dubay reyslari",
      saudiaairlinesInfo: "Saudia Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Jidda reyslari, Riyod reyslari, Moskva reyslari",
      somonairInfo: "Somon Air, aviabilet, aviabilet narxlari, arzon aviabilet, Dushanbe reyslari, Moskva reyslari, Sankt-Peterburg reyslari",
      turkmenistanairlinesInfo: "Turkmenistan Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Ashxobod reyslari, Moskva reyslari, Dubay reyslari",
      vistaraairlinesInfo: "Vistara Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Dehli reyslari, Mumbay reyslari, Moskva reyslari",
      mahanairInfo: "Mahan Air, aviabilet, aviabilet narxlari, arzon aviabilet, Tehron reyslari, Moskva reyslari, Dubay reyslari",
      omanairInfo: "Oman Air, aviabilet, aviabilet narxlari, arzon aviabilet, Maskat reyslari, Moskva reyslari, Dubay reyslari",
      flynasairlinesInfo: "Flynas Airlines, aviabilet, aviabilet narxlari, arzon aviabilet, Jidda reyslari, Riyod reyslari, Dubay reyslari",
      kishairInfo: "Kish Air, aviabilet, aviabilet narxlari, arzon aviabilet, Tehron reyslari, Dubay reyslari, Shiraz reyslari",
      indigoInfo: "IndiGo Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Dehli aviabileti, Mumbay aviabileti, Kolkata aviabileti, aviabilet sotib olish, IndiGo aviabiletlari",
      nepalAirlinesInfo: "Nepal Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Katmandu aviabileti, Pokhara aviabileti, Nepalga aviabilet, aviabilet sotib olish, Nepal Airlines aviabiletlari",
      bimanBangladeshInfo: "Biman Bangladesh Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Dakka aviabileti, Chittagong aviabileti, Bangladeshga aviabilet, aviabilet sotib olish, Biman Bangladesh aviabiletlari",
      sriLankanAirlinesInfo: "SriLankan Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Kolombo aviabileti, Shri-Lankaga aviabilet, aviabilet sotib olish, SriLankan Airlines aviabiletlari",
      chinaEasternInfo: "China Eastern Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Pekin aviabileti, Shanxay aviabileti, Xitoyga aviabilet, aviabilet sotib olish, China Eastern aviabiletlari",
      hainanAirlinesInfo: "Hainan Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Xaynan aviabileti, Xitoyga aviabilet, aviabilet sotib olish, Hainan Airlines aviabiletlari",
      xiamenAirlinesInfo: "Xiamen Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Xiamen aviabileti, Xitoyga aviabilet, aviabilet sotib olish, Xiamen Airlines aviabiletlari",
      beijingCapitalInfo: "Beijing Capital Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Pekin aviabileti, Xitoyga aviabilet, aviabilet sotib olish, Beijing Capital Airlines aviabiletlari",
      luckyAirInfo: "Lucky Air, aviabilet, aviabilet narxlari, arzon aviabiletlar, Xitoyga aviabilet, aviabilet sotib olish, Lucky Air aviabiletlari",
      miatMongolianInfo: "MIAT Mongolian Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Ulan-Bator aviabileti, Mo‘g‘ulistonga aviabilet, aviabilet sotib olish, MIAT Mongolian aviabiletlari",
      airSerbiaInfo: "Air Serbia, aviabilet, aviabilet narxlari, arzon aviabiletlar, Belgrad aviabileti, Serbiyaga aviabilet, aviabilet sotib olish, Air Serbia aviabiletlari",
      airMontenegroInfo: "Air Montenegro, aviabilet, aviabilet narxlari, arzon aviabiletlar, Podgoritsa aviabileti, Chernogoriyaga aviabilet, aviabilet sotib olish, Air Montenegro aviabiletlari",
      taromInfo:  "Tarom, aviabilet, aviabilet narxlari, arzon aviabiletlar, Buxarest aviabileti, Ruminiyaga aviabilet, aviabilet sotib olish, Tarom aviabiletlari",
      bulgariaAirInfo: "Bulgaria Air, aviabilet, aviabilet narxlari, arzon aviabiletlar, Sofiya aviabileti, Bolgariyaga aviabilet, aviabilet sotib olish, Bulgaria Air aviabiletlari",
      aegeanAirlinesInfo: "Aegean Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Afina aviabileti, Gretsiyaga aviabilet, aviabilet sotib olish, Aegean Airlines aviabiletlari",
      ukraineIntlAirlinesInfo: "Ukraine International Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Kiyev aviabileti, Ukrainaga aviabilet, aviabilet sotib olish, UIA aviabiletlari",
      windroseAirlinesInfo: "Windrose Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Ukrainaga aviabilet, aviabilet sotib olish, Windrose aviabiletlari",
      skyUpAirlinesInfo: "SkyUp Airlines, aviabilet, aviabilet narxlari, arzon aviabiletlar, Kiyev aviabileti, Ukrainaga aviabilet, aviabilet sotib olish, SkyUp aviabiletlari",
  },
};

export default metaKeywords;

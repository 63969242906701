import classNames from "classnames";
import _ from "lodash";
import { AiOutlineRollback } from "react-icons/ai";
import { PiHandbagSimple, PiSuitcaseRolling } from "react-icons/pi";
import { RiRepeatFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useState } from "react";

import { getServiceClass, normalizePrice } from "../../../utils";

import { PricesBlock, SubmitButton, TariffItem, TariffsButton, Wrapper } from "./style";
import { TariffItemClass, TariffItemDetails, TariffItemIcons } from "../RecommendationTarrifsComponent/style";
import RecommendationTarrifsComponent from "../RecommendationTarrifsComponent";
import { BiChevronDownCircle } from "react-icons/bi";
import { RulesModalComponent } from "../../rulesModal";
import { prebook } from "../../../protocols";
import { setBrandRules } from "../../../store/resultDataSlice";
import { DotsLoadingComponent } from "../../ui";

const RecommendationPricesComponent = ({ recommendation }) => {
  const { t } = i18next;

  const { brandFares, session, brandRules } = useSelector(state => state.resultData);
  const searchParams = useSelector(state => state.searchParams);
  const { currency } = useSelector(state => state.appConfigs);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowTariffs, setIsShowTarrifs] = useState(false);
  const [isLoadingRules, setIsLoadingRules] = useState(false);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  const {
    total_price,
    brandRecId,
    rec_id,
    brand_name: brandName,
    has_branded_tariffs: hasBrandedTariffs,
  } = recommendation;

  const { routes } = recommendation;
  const departureSegment = routes[0].segments[0];
  const { is_refund, is_change, baggage, hand_luggage, hand_luggage_weight, baggage_weight } = departureSegment;

  const bookingClick = () => {
    const recId = brandRecId || rec_id;

    navigate(
      `/booking?rec_id=${recId}&session=${session}&adt=${searchParams.passengers.adt}&chd=${searchParams.passengers.chd}&inf=${searchParams.passengers.inf}&ins=${searchParams.passengers.ins}`
    );
  };

  const renderButton = () => {
    let actualTotalPrice = total_price;

    if (brandRecId) {
      const { rec_id } = recommendation;
      const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

      actualTotalPrice = actualBrandFare.total_price;
    }

    return (
      <SubmitButton onClick={bookingClick}>
        {normalizePrice(actualTotalPrice[currency]) || actualTotalPrice} {currency}
      </SubmitButton>
    );
  };

  const handleToggleTarrifs = () => {
    setIsShowTarrifs(prev => {
      if (prev) {
        return false;
      }

      return true;
    });
  };

  const handleOpenRulesModal = () => {
    if (brandRules.length === 0 || !brandRules[brandRecId || rec_id]) {
      setIsLoadingRules(true);

      prebook({ rec_id: brandRecId || rec_id, session_id: session }).then(response => {
        dispatch(
          setBrandRules({
            data: response,
            recId: brandRecId || rec_id,
          })
        );

        setIsRulesModalOpen(true);
        setIsLoadingRules(false);
      });
    } else {
      setIsRulesModalOpen(true);
    }
  };

  const brandRule = brandRules[brandRecId || rec_id];

  return (
    <Wrapper>
      <PricesBlock>
        {renderButton()}
        <TariffItem>
          <TariffItemIcons>
            <div
              className={classNames({ notActive: !hand_luggage })}
              title={hand_luggage ? t("yes_hand_luggage") : t("no_hand_luggage")}
            >
              <PiHandbagSimple />
              {hand_luggage_weight ? <div className="hand-luggage-weight">{hand_luggage_weight}</div> : null}
            </div>

            <div className={classNames({ notActive: !baggage })} title={baggage ? t("yes_baggage") : t("no_baggage")}>
              <PiSuitcaseRolling />
              {baggage_weight ? <div className="baggage-weight">{baggage_weight}</div> : null}
            </div>

            <div className={classNames({ notActive: !is_change })} title={is_change ? t("yes_change") : t("no_change")}>
              <RiRepeatFill />
            </div>

            <div className={classNames({ notActive: !is_refund })} title={is_refund ? t("yes_refund") : t("no_refund")}>
              <AiOutlineRollback />
            </div>
          </TariffItemIcons>
          <TariffItemDetails onClick={handleOpenRulesModal} $isLoading={isLoadingRules}>
            {isLoadingRules ? <DotsLoadingComponent color="#1d3557" /> : t("tariff_rules")}
          </TariffItemDetails>
          <TariffItemClass>{getServiceClass(departureSegment.service_class)}</TariffItemClass>
        </TariffItem>

        {hasBrandedTariffs && (
          <TariffsButton onClick={handleToggleTarrifs}>
            {t("tariffs")}{" "}
            <span className={classNames({ active: isShowTariffs })}>
              <BiChevronDownCircle />
            </span>
          </TariffsButton>
        )}

        {isShowTariffs && (
          <RecommendationTarrifsComponent recId={rec_id} brandRecId={brandRecId} brandName={brandName} />
        )}
      </PricesBlock>

      {brandRule && (
        <RulesModalComponent
          openModal={!isLoadingRules && isRulesModalOpen}
          handleCloseModal={() => setIsRulesModalOpen(false)}
          fareRules={brandRule?.fare_rules}
          routes={brandRule?.routes}
          included={brandRule?.included}
        />
      )}
    </Wrapper>
  );
};

export default RecommendationPricesComponent;

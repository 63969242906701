const metaDescriptions = {
  ru: {
    homePage:
      "Добро пожаловать на сайт BiletBor.me: это лучшее место для бронирования авиабилетов без лишних хлопот. Изучите нашу удобную платформу для планирования путешествий, получения выгодных предложений и индивидуальных рекомендаций. Присоединяйтесь к миллионам довольных путешественников и начните свое следующее приключение с BiletBor.me уже сегодня!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
    airastanaInfo:
      "Air Astana — национальная авиакомпания Казахстана, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    aircairoInfo:
      "Air Cairo — египетская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте, расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    airchinaInfo:
      "Air China — одна из ведущих авиакомпаний Китая, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    airmanasInfo:
      "Air Manas — киргизская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    asianaAirlinesInfo:
      "Asiana Airlines — одна из ведущих авиакомпаний Республики Корея, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    aviaTrafficInfo:
      "Avia Traffic Company — киргизская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azalInfo:
      "AZAL — национальная авиакомпания Азербайджана, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azimuthInfo:
      "Azimuth — российская авиакомпания, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    azurairInfo:
      "Azur Air — российская авиакомпания, выполняющая рейсы из Узбекистана. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    belaviaInfo:
      "Belavia — национальная авиакомпания Беларуси, выполняющая рейсы в Узбекистан. Узнайте о дате основания, флоте и расписании рейсов, а также о том, как купить дешевые авиабилеты.",
    uzairways:
      "Узнайте о Uzbekistan Airways: расписание рейсов, покупка авиабилетов, флот и маршруты. Актуальные цены и информация о перелетах.",
    s7Info:
      "Узнайте о S7 Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
    turkishInfo:
      "Узнайте о Turkish Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
    qanotsharqInfo:
      "Официальная информация о Qanot Sharq – узбекской авиакомпании. Узнайте об истории, флоте и расписании рейсов. Бронируйте дешёвые авиабилеты на biletbor.me.",
    uralInfo:
      "Узнайте о Ural Airlines: покупка авиабилетов, маршруты, флот и расписание рейсов из Узбекистана. Бронируйте билеты онлайн.",
    pobedaInfo:
      "Pobeda Airlines – популярный лоукостер России. Узнайте историю авиакомпании, её флот, расписание рейсов из Узбекистана и купите дешёвые авиабилеты на biletbor.me.",
    spicejetInfo:
      "SpiceJet – ведущая индийская авиакомпания, предлагающая доступные рейсы из Узбекистана в Дели и другие направления. Узнайте о флоте, расписании и бронируйте билеты онлайн.",
      yakutiaInfo: "Yakutia Airlines - российская авиакомпания, выполняющая рейсы в Узбекистан.",
      yamalInfo: "Yamal Airlines - российская региональная авиакомпания.",
      smartaviaInfo: "Smartavia - российская авиакомпания, известная своими бюджетными перелётами.",
      auroraInfo: "Aurora - дальневосточная авиакомпания России.",
      alrosaInfo: "Alrosa - российская авиакомпания.",
      uvtAeroInfo: "UVT Aero - российская региональная авиакомпания.",
      flyaristanInfo: "FlyArystan - казахстанская бюджетная авиакомпания.",
      tezjetInfo: "TezJet - киргизская авиакомпания.",
      turkmenistanInfo: "Turkmenistan Airlines - национальная авиакомпания Туркменистана.",
      arianaInfo: "Ariana Afghan Airlines - национальная авиакомпания Афганистана.",
      georgianAirwaysInfo: "Georgian Airways - национальная авиакомпания Грузии.",
      mywayInfo: "MyWay Airlines - грузинская авиакомпания.",
    airarmeniaInfo: "Aircompany Armenia - армянская авиакомпания.",
      flyoneArmeniaInfo: "FlyOne Armenia - армянская авиакомпания.",
      kishAirInfo: "Kish Air - иранская авиакомпания.",
      qeshmAirInfo: "Qeshm Air - иранская авиакомпания.",
      iranAsemanInfo: "Iran Aseman Airlines - иранская авиакомпания.",
      iraqiAirwaysInfo: "Iraqi Airways - национальная авиакомпания Ирака.",
      middleEastInfo: "Middle East Airlines - ливанская авиакомпания.",
      saudiaInfo: "Saudia - национальная авиакомпания Саудовской Аравии.",
      flynasInfo: "Flynas - бюджетная авиакомпания Саудовской Аравии, выполняющая рейсы в Узбекистан.",
      royalJordanianInfo: "Royal Jordanian - национальная авиакомпания Иордании, выполняющая рейсы в Узбекистан.",
      jordanAviationInfo: "Jordan Aviation - иорданская авиакомпания, выполняющая международные и внутренние рейсы.",
      gulfAirInfo: "Gulf Air - национальная авиакомпания Бахрейна, выполняющая рейсы в Узбекистан.",
      kuwaitAirwaysInfo: "Kuwait Airways - национальная авиакомпания Кувейта, обслуживающая международные маршруты.",
      omanAirInfo: "Oman Air - национальная авиакомпания Омана, известная высоким качеством обслуживания.",
      salamAirInfo: "SalamAir - бюджетная авиакомпания Омана, выполняющая рейсы в Узбекистан.",
      pakistanIntlInfo: "Pakistan International Airlines - национальная авиакомпания Пакистана, выполняющая международные и внутренние рейсы.",
      airblueInfo: "Airblue - пакистанская авиакомпания, известная бюджетными перелётами.",
      sereneAirInfo: "SereneAir - пакистанская авиакомпания, выполняющая международные и внутренние рейсы.",
      airIndiaInfo: "Air India - национальная авиакомпания Индии.",
      indigoInfo: "IndiGo - одна из крупнейших авиакомпаний Индии.",
      vistaraInfo: "Vistara - индийская авиакомпания, известная высоким качеством обслуживания.",
      goFirstInfo: "Go First (бывшая GoAir) - индийская бюджетная авиакомпания.",
      nepalAirlinesInfo: "Nepal Airlines - национальная авиакомпания Непала.",
      bimanInfo: "Biman Bangladesh Airlines - национальная авиакомпания Бангладеша.",
      sriLankanInfo: "SriLankan Airlines - национальная авиакомпания Шри-Ланки.",
      chinaEasternInfo: "China Eastern Airlines - одна из крупнейших авиакомпаний Китая.",
      hainanAirlinesInfo: "Hainan Airlines - одна из крупнейших авиакомпаний Китая.",
      xiamenAirlinesInfo: "Xiamen Airlines - китайская авиакомпания, обслуживающая международные маршруты.",
      beijingCapitalInfo: "Beijing Capital Airlines - китайская авиакомпания, известная бюджетными перелётами.",
    luckyAirInfo: "Lucky Air - китайская авиакомпания, выполняющая внутренние и международные рейсы.",
      cathayPacificInfo: "Cathay Pacific - одна из ведущих авиакомпаний Гонконга.",
      miatInfo: "MIAT Mongolian Airlines - национальная авиакомпания Монголии.",
      airSerbiaInfo: "Air Serbia - национальная авиакомпания Сербии.",
      airMontenegroInfo: "Air Montenegro - черногорская авиакомпания.",
      taromInfo: "Tarom - национальная авиакомпания Румынии.",
      bulgariaAirInfo: "Bulgaria Air - национальная авиакомпания Болгарии.",
      aegeanAirlinesInfo: "Aegean Airlines - одна из ведущих авиакомпаний Греции.",
      ukraineIntlInfo: "Ukraine International Airlines - национальная авиакомпания Украины.",
      windroseInfo: "Windrose Airlines - украинская авиакомпания.",
      skyUpInfo: "SkyUp Airlines - украинская бюджетная авиакомпания.",
  },
  en: {
    homePage:
      "Welcome to BiletBor.me: Your premier destination for hassle-free flight bookings. Explore our user-friendly platform for seamless travel planning, unbeatable deals, and personalized recommendations. Join millions of satisfied travelers and start your next adventure with BiletBor.me today!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
      airastanaInfo: "Air Astana is Kazakhstan’s national airline operating flights to Uzbekistan. Learn about its founding date, fleet, and flight schedules, as well as how to buy cheap tickets.",
      aircairoInfo: "Air Cairo is an Egyptian airline operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      airchinaInfo: "Air China is one of China’s leading airlines operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      airmanasInfo: "Air Manas is a Kyrgyz airline operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      asianaAirlinesInfo: "Asiana Airlines is one of South Korea’s leading airlines operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      aviaTrafficInfo: "Avia Traffic Company is a Kyrgyz airline operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      azalInfo: "AZAL is Azerbaijan’s national airline operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      azimuthInfo: "Azimuth is a Russian airline operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      azurairInfo: "Azur Air is a Russian airline operating flights from Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      belaviaInfo: "Belavia is the national airline of Belarus operating flights to Uzbekistan. Learn about its founding date, fleet, flight schedules, and how to buy cheap airline tickets.",
      uzairways: "Learn about Uzbekistan Airways: flight schedules, ticket booking, fleet, and routes. Find the latest prices and travel information.",
      s7Info: "Learn about S7 Airlines: ticket booking, routes, fleet, and flight schedules from Uzbekistan. Book tickets online.",
      turkishInfo: "Learn about Turkish Airlines: ticket booking, routes, fleet, and flight schedules from Uzbekistan. Book tickets online.",
      qanotsharqInfo: "Official information about Qanot Sharq, an Uzbek airline. Learn about its history, fleet, and flight schedules. Book cheap airline tickets on BiletBor.me.",
      uralInfo: "Learn about Ural Airlines: ticket booking, routes, fleet, and flight schedules from Uzbekistan. Book tickets online.",
      rossiyaInfo: "Rossiya Airlines - одна из ведущих авиакомпаний России. Узнайте о дате основания, парке воздушных судов и расписании рейсов в Узбекистан.",
      pobedaInfo: "Pobeda - российская бюджетная авиакомпания. Узнайте о парке воздушных судов и расписании рейсов.",
      yakutiaInfo: "Yakutia Airlines - российская авиакомпания, выполняющая рейсы в Узбекистан.",
      yamalInfo: "Yamal Airlines - российская региональная авиакомпания.",
      smartaviaInfo: "Smartavia - российская авиакомпания, известная своими бюджетными перелётами.",
      auroraInfo: "Aurora - дальневосточная авиакомпания России.",
      alrosaInfo: "Alrosa - российская авиакомпания.",
      uvtAeroInfo: "UVT Aero - российская региональная авиакомпания.",
      flyaristanInfo: "FlyArystan - казахстанская бюджетная авиакомпания.",
      tezjetInfo: "TezJet - киргизская авиакомпания.",
      turkmenistanInfo: "Turkmenistan Airlines - национальная авиакомпания Туркменистана.",
      arianaInfo: "Ariana Afghan Airlines - национальная авиакомпания Афганистана.",
      georgianAirwaysInfo: "Georgian Airways - национальная авиакомпания Грузии.",
      mywayInfo: "MyWay Airlines - грузинская авиакомпания.",
      airarmeniaInfo: "Aircompany Armenia - армянская авиакомпания.",
      flyoneArmeniaInfo: "FlyOne Armenia - армянская авиакомпания.",
      kishAirInfo: "Kish Air - иранская авиакомпания.",
      qeshmAirInfo: "Qeshm Air - иранская авиакомпания.",
      iranAsemanInfo: "Iran Aseman Airlines - иранская авиакомпания.",
      iraqiAirwaysInfo: "Iraqi Airways - национальная авиакомпания Ирака.",
      middleEastInfo: "Middle East Airlines - ливанская авиакомпания.",
      saudiaInfo: "Saudia - национальная авиакомпания Саудовской Аравии.",
      flynasInfo: "Flynas - бюджетная авиакомпания Саудовской Аравии, выполняющая рейсы в Узбекистан.",
      royalJordanianInfo: "Royal Jordanian - национальная авиакомпания Иордании, выполняющая рейсы в Узбекистан.",
      jordanAviationInfo: "Jordan Aviation - иорданская авиакомпания, выполняющая международные и внутренние рейсы.",
      gulfAirInfo: "Gulf Air - национальная авиакомпания Бахрейна, выполняющая рейсы в Узбекистан.",
      kuwaitAirwaysInfo: "Kuwait Airways - национальная авиакомпания Кувейта, обслуживающая международные маршруты.",
      omanAirInfo: "Oman Air - национальная авиакомпания Омана, известная высоким качеством обслуживания.",
      salamAirInfo: "SalamAir - бюджетная авиакомпания Омана, выполняющая рейсы в Узбекистан.",
      pakistanIntlInfo: "Pakistan International Airlines - национальная авиакомпания Пакистана, выполняющая международные и внутренние рейсы.",
      airblueInfo: "Airblue - пакистанская авиакомпания, известная бюджетными перелётами.",
      sereneAirInfo: "SereneAir - пакистанская авиакомпания, выполняющая международные и внутренние рейсы.",
      airIndiaInfo: "Air India - национальная авиакомпания Индии.",
      indigoInfo: "IndiGo - одна из крупнейших авиакомпаний Индии.",
      vistaraInfo: "Vistara - индийская авиакомпания, известная высоким качеством обслуживания.",
      goFirstInfo: "Go First (бывшая GoAir) - индийская бюджетная авиакомпания.",
      nepalAirlinesInfo: "Nepal Airlines - национальная авиакомпания Непала.",
      bimanInfo: "Biman Bangladesh Airlines - национальная авиакомпания Бангладеша.",
      sriLankanInfo: "SriLankan Airlines - национальная авиакомпания Шри-Ланки.",
      chinaEasternInfo: "China Eastern Airlines - одна из крупнейших авиакомпаний Китая.",
      hainanAirlinesInfo: "Hainan Airlines - одна из крупнейших авиакомпаний Китая.",
      xiamenAirlinesInfo: "Xiamen Airlines - китайская авиакомпания, обслуживающая международные маршруты.",
      beijingCapitalInfo: "Beijing Capital Airlines - китайская авиакомпания, известная бюджетными перелётами.",
      luckyAirInfo: "Lucky Air - китайская авиакомпания, выполняющая внутренние и международные рейсы.",
      cathayPacificInfo: "Cathay Pacific - одна из ведущих авиакомпаний Гонконга.",
      miatInfo: "MIAT Mongolian Airlines - национальная авиакомпания Монголии.",
      airSerbiaInfo: "Air Serbia - национальная авиакомпания Сербии.",
      airMontenegroInfo: "Air Montenegro - черногорская авиакомпания.",
      taromInfo: "Tarom - национальная авиакомпания Румынии.",
      bulgariaAirInfo: "Bulgaria Air - национальная авиакомпания Болгарии.",
      aegeanAirlinesInfo: "Aegean Airlines - одна из ведущих авиакомпаний Греции.",
      ukraineIntlInfo: "Ukraine International Airlines - национальная авиакомпания Украины.",
      windroseInfo: "Windrose Airlines - украинская авиакомпания.",
      skyUpInfo: "SkyUp Airlines - украинская бюджетная авиакомпания.",
    spicejetInfo:
      "SpiceJet is one of India’s leading budget airlines, offering low-cost flights from Uzbekistan to Delhi and beyond. Check out fleet details, schedules, and book online.",
  },
  uz: {
    homePage:
      "BiletBor.me veb-saytiga xush kelibsiz: bu hech qanday muammosiz aviachiptalarni bron qilish uchun eng yaxshi joy. Sayohatni rejalashtirish, ajoyib takliflar va moslashtirilgan tavsiyalar uchun foydalanish uchun qulay platformamiz bilan tanishing. Millionlab mamnun sayohatchilar qatoriga qo'shiling va bugun BiletBor.me bilan keyingi sarguzashtingizni boshlang!",
    aeroflotInfo:
      "Аэрофлот — одна из крупнейших авиакомпаний России. Узнайте о расписании рейсов из Узбекистана, aviabilet narxlari, багаже и флоте, а также о том, как купить дешевые авиабилеты.",
    uzairways:
      "Узнайте о Uzbekistan Airways: расписание рейсов, покупка авиабилетов, флот и маршруты. Актуальные цены и информация о перелетах.",
    s7Info:
      "S7 Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
    turkishInfo:
      "Turkish Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
    qanotsharqInfo:
      "Rasmiy ma’lumotlar: Qanot Sharq aviakompaniyasi haqida bilib oling. Tarix, samolyotlar va reyslar jadvali. Arzon aviabiletlarni biletbor.me orqali band qiling.",
    uralInfo:
      "Ural Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
    pobedaInfo:
      "Pobeda Airlines – Rossiyaning mashhur loukosteri. Aviakompaniya tarixi, samolyot parki, reyslar jadvali va arzon aviabiletlarni biletbor.me orqali bilib oling.",
    spicejetInfo:
      "SpiceJet – Hindistonning yetakchi aviakompaniyalaridan biri bo‘lib, O‘zbekistondan Dehliga va boshqa yo‘nalishlarga arzon parvozlar taklif etadi. Aviapark, reyslar jadvali va narxlar haqida bilib oling.",
      airastanaInfo: "Air Astana - Qozog'istonning milliy aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki va parvoz jadvali, shuningdek, arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      aircairoInfo: "Air Cairo - Misr aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      airchinaInfo: "Air China - Xitoyning yetakchi aviakompaniyalaridan biri bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      airmanasInfo: "Air Manas - Qirg'iziston aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      asianaAirlinesInfo: "Asiana Airlines - Janubiy Koreyaning yetakchi aviakompaniyalaridan biri bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      aviaTrafficInfo: "Avia Traffic Company - Qirg'iziston aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      azalInfo: "AZAL - Ozarbayjonning milliy aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      azimuthInfo: "Azimuth - Rossiyaning aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      azurairInfo: "Azur Air - Rossiyaning aviakompaniyasi bo'lib, O'zbekistondan parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      belaviaInfo: "Belavia - Belarusning milliy aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi. Uning tashkil etilgan sanasi, havo kemalari parki, parvoz jadvali va arzon aviabiletlarni qanday sotib olish haqida bilib oling.",
      uzairways: "Узнайте о Uzbekistan Airways: расписание рейсов, покупка авиабилетов, флот и маршруты. Актуальные цены и информация о перелетах.",
      s7Info: "S7 Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
      turkishInfo: "Turkish Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
      qanotsharqInfo: "Rasmiy ma’lumotlar: Qanot Sharq aviakompaniyasi haqida bilib oling. Tarix, samolyotlar va reyslar jadvali. Arzon aviabiletlarni biletbor.me orqali band qiling.",
      uralInfo: "Ural Airlines reys jadvali, aviabiletlar va marshrutlar haqida batafsil ma’lumot. O‘zbekistondan reyslar va onlayn bron qilish.",
      rossiyaInfo: "Rossiya Airlines - Rossiyaning yetakchi aviakompaniyalaridan biri. Uning tashkil etilgan sanasi, havo kemalari parki va O'zbekistonga parvoz jadvali haqida bilib oling.",
      pobedaInfo: "Pobeda - Rossiyaning arzon aviakompaniyasi. Uning havo kemalari parki va parvoz jadvali haqida bilib oling.",
      yakutiaInfo: "Yakutia Airlines - Rossiya aviakompaniyasi bo'lib, O'zbekistonga parvozlarni amalga oshiradi.",
      yamalInfo: "Yamal Airlines - Rossiyaning hududiy aviakompaniyasi.",
      smartaviaInfo: "Smartavia - Rossiya aviakompaniyasi, arzon parvozlar bilan mashhur.",
      auroraInfo: "Aurora - Rossiyaning Uzoq Sharq aviakompaniyasi.",
      alrosaInfo: "Alrosa - Rossiya aviakompaniyasi.",
      uvtAeroInfo: "UVT Aero - Rossiya hududiy aviakompaniyasi.",
      flyarystanInfo: "FlyArystan - Qozog'istonning arzon aviakompaniyasi.",
      tezjetInfo: "TezJet - Qirg'iziston aviakompaniyasi.",
      turkmenistanInfo: "Turkmenistan Airlines - Turkmanistonning milliy aviakompaniyasi.",
      arianaInfo: "Ariana Afghan Airlines - Afg'onistonning milliy aviakompaniyasi.",
      georgianAirwaysInfo: "Georgian Airways - Gruziyaning milliy aviakompaniyasi.",
      mywayInfo: "MyWay Airlines - Gruziya aviakompaniyasi.",
      airarmeniaInfo: "Aircompany Armenia - Armaniston aviakompaniyasi.",
      flyoneArmeniaInfo: "FlyOne Armenia - Armaniston aviakompaniyasi.",
    kishAirInfo: "Kish Air - Eron aviakompaniyasi.",
      qeshmAirInfo: "Qeshm Air - Eron aviakompaniyasi.",
      iranAsemanInfo: "Iran Aseman Airlines - Eron aviakompaniyasi.",
      iraqiAirwaysInfo: "Iraqi Airways - Iroqning milliy aviakompaniyasi.",
      middleEastInfo: "Middle East Airlines - Livan aviakompaniyasi.",
      saudiaInfo: "Saudia - Saudiya Arabistonining milliy aviakompaniyasi.",
      airIndiaInfo: "Air India - Hindistonning milliy aviakompaniyasi.",
      indigoInfo: "IndiGo - Hindistonning eng yirik aviakompaniyalaridan biri.",
      bimanInfo: "Biman Bangladesh Airlines - Bangladesh milliy aviakompaniyasi.",
      royalJordanianInfo: "Royal Jordanian - Iordaniyaning milliy aviakompaniyasi, O'zbekistonga parvozlarni amalga oshiradi.",
      jordanAviationInfo: "Jordan Aviation - Iordaniya aviakompaniyasi, xalqaro va ichki reyslarni amalga oshiradi.",
      gulfAirInfo: "Gulf Air - Bahraynning milliy aviakompaniyasi, O'zbekistonga parvozlarni amalga oshiradi.",
      kuwaitAirwaysInfo: "Kuwait Airways - Quvaytning milliy aviakompaniyasi, xalqaro yo'nalishlarda xizmat ko'rsatadi.",
      omanAirInfo: "Oman Air - Omanning milliy aviakompaniyasi, yuqori sifatli xizmat bilan mashhur.",
      salamAirInfo: "SalamAir - Omanning arzon aviakompaniyasi, O'zbekistonga parvozlarni amalga oshiradi.",
      pakistanIntlInfo: "Pakistan International Airlines - Pokistonning milliy aviakompaniyasi, xalqaro va ichki yo'nalishlarda xizmat ko'rsatadi.",
      airblueInfo: "Airblue - Pokiston aviakompaniyasi, arzon parvozlar bilan mashhur.",
      sereneAirInfo: "SereneAir - Pokiston aviakompaniyasi, xalqaro va ichki reyslarni amalga oshiradi.",
      airIndiaInfo: "Air India - Hindistonning milliy aviakompaniyasi.",
      indigoInfo: "IndiGo - Hindistonning eng yirik aviakompaniyalaridan biri.",
      spiceJetInfo: "SpiceJet - Hindiston aviakompaniyasi, arzon parvozlar bilan mashhur.",
      vistaraInfo: "Vistara - Hindiston aviakompaniyasi, yuqori sifatli xizmat bilan tanilgan.",
      goFirstInfo: "Go First (avvalgi GoAir) - Hindistonning arzon aviakompaniyasi.",
      nepalAirlinesInfo: "Nepal Airlines - Nepalning milliy aviakompaniyasi.",
      bimanInfo: "Biman Bangladesh Airlines - Bangladesh milliy aviakompaniyasi.",
      sriLankanInfo: "SriLankan Airlines - Shri-Lankaning milliy aviakompaniyasi.",
      chinaEasternInfo: "China Eastern Airlines - Xitoyning yirik aviakompaniyalaridan biri.",
      hainanAirlinesInfo: "Hainan Airlines - Xitoyning yirik aviakompaniyalaridan biri.",
      xiamenAirlinesInfo: "Xiamen Airlines - Xitoy aviakompaniyasi, xalqaro yo'nalishlarda xizmat ko'rsatadi.",
      beijingCapitalInfo: "Beijing Capital Airlines - Xitoy aviakompaniyasi, arzon parvozlar bilan mashhur.",
      luckyAirInfo: "Lucky Air - Xitoy aviakompaniyasi, ichki va xalqaro reyslarni amalga oshiradi.",
      cathayPacificInfo: "Cathay Pacific - Gonkongning yetakchi aviakompaniyalaridan biri.",
      miatInfo: "MIAT Mongolian Airlines - Mo'g'uliston milliy aviakompaniyasi.",
      airSerbiaInfo: "Air Serbia - Serbiyaning milliy aviakompaniyasi.",
      airMontenegroInfo: "Air Montenegro - Chernogoriya aviakompaniyasi.",
      taromInfo: "Tarom - Ruminiyaning milliy aviakompaniyasi.",
      bulgariaAirInfo: "Bulgaria Air - Bolgariyaning milliy aviakompaniyasi.",
      aegeanAirlinesInfo: "Aegean Airlines - Gretsiyaning yetakchi aviakompaniyalaridan biri.",
      ukraineIntlInfo: "Ukraine International Airlines - Ukrainaning milliy aviakompaniyasi.",
      windroseInfo: "Windrose Airlines - Ukraina aviakompaniyasi.",
      skyUpInfo: "SkyUp Airlines - Ukraina arzon aviakompaniyasi.", 
  },
};

export default metaDescriptions;

export const info = {
  ru: {
    auroraAirlines: {
      pageKey: "auroraAirlinesInfo",
      imageUrl: "/images/AK/AU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aurora Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Aurora Airlines — российская авиакомпания, основанная в 2013 году в результате слияния авиакомпаний «Аэрофлот-Норд» и «Владивосток Авиа». Главный офис авиакомпании расположен во Владивостоке. Основная цель — предоставление пассажирских перевозок, включая международные и региональные рейсы, с акцентом на Дальний Восток России.</p>
            <h3>Флот Aurora Airlines</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bombardier Q400</td>
                  <td>~8</td>
                  <td>Региональные рейсы, экономичный расход топлива, вместимость до 78 пассажиров</td>
                </tr>
                <tr>
                  <td>SSJ 100</td>
                  <td>~10</td>
                  <td>Среднемагистральные рейсы, вместимость до 100 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Aurora Airlines выполняет рейсы по России и международным маршрутам, включая:</p>
            <ul>
              <li><b>Владивосток – Москва:</b> регулярные рейсы несколько раз в неделю.</li>
              <li><b>Владивосток – Хабаровск:</b> несколько рейсов в день.</li>
              <li><b>Владивосток – Сеул:</b> международные рейсы несколько раз в неделю.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Aurora Airlines предоставляет надежные и удобные перелёты по регионам Дальнего Востока России и за рубеж, с качественным обслуживанием на борту.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Россия</p>
            <h4>Штаб-квартира:</h4>
            <p>Владивосток</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Владивостока (VVO)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.flyaurora.ru" target="_blank" rel="noreferrer">flyaurora.ru</a>
          `,
        },
      ],
    },
    yamalAirlines: {
      pageKey: "yamalAirlinesInfo",
      imageUrl: "/images/AK/YM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Yamal Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Yamal Airlines — российская авиакомпания, основанная в 1997 году. Компания базируется в Салехарде и выполняет рейсы по северу России, включая отдалённые и труднодоступные регионы Ямала, а также выполняет международные рейсы в страны СНГ и другие направления. Yamal Airlines активно участвует в выполнении перевозок в условиях Крайнего Севера.</p>
            <h3>Флот Yamal Airlines</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SSJ 100</td>
                  <td>~6</td>
                  <td>Комфортабельные самолёты для региональных маршрутов</td>
                </tr>
                <tr>
                  <td>ТУ-204</td>
                  <td>~4</td>
                  <td>Среднемагистральные и дальнемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>~5</td>
                  <td>Небольшие самолёты для внутренних и региональных рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Yamal Airlines выполняет регулярные рейсы по направлениям внутри России и международным маршрутам, включая:</p>
            <ul>
              <li><b>Москва – Салехард:</b> несколько раз в неделю.</li>
              <li><b>Тюмень – Новоуренгой:</b> регулярные рейсы.</li>
              <li><b>Салехард – Ханты-Мансийск:</b> прямые рейсы для жителей северных регионов.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Yamal Airlines предоставляет надёжные и удобные перелёты по регионам Крайнего Севера, а также по другим внутренним и международным направлениям, обеспечивая доступность для пассажиров в труднодоступных районах России.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Россия</p>
            <h4>Штаб-квартира:</h4>
            <p>Салехард</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Салехарда (SLY)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.yamal.aero" target="_blank" rel="noreferrer">yamal.aero</a>
          `,
        },
      ],
    },
    yakutiaAirlines: {
      pageKey: "yakutiaAirlinesInfo",
      imageUrl: "/images/AK/YA.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Yakutia Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Yakutia Airlines — российская авиакомпания, основанная в 2002 году. Компания базируется в Якутске и выполняет внутренние рейсы по России, включая рейсы в отдалённые регионы Сибири и Дальнего Востока. Yakutia Airlines также выполняет международные рейсы в страны Азии.</p>
            <h3>Флот Yakutia Airlines</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~5</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Yak-42</td>
                  <td>~6</td>
                  <td>Местные маршруты, доступность для сложных климатических условий</td>
                </tr>
                <tr>
                  <td>Bombardier CRJ-200</td>
                  <td>~3</td>
                  <td>Короткие и региональные маршруты</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Yakutia Airlines выполняет рейсы по различным направлениям внутри России и международные маршруты, включая:</p>
            <ul>
              <li><b>Якутск – Москва (SVO):</b> несколько раз в неделю.</li>
              <li><b>Якутск – Владивосток (VVO):</b> несколько раз в неделю.</li>
              <li><b>Якутск – Бангкок (BKK):</b> сезонные международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Yakutia Airlines — надёжный перевозчик с уникальными возможностями для перелетов в регионы Сибири и Дальнего Востока, а также с удобным обслуживанием на международных маршрутах.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Россия</p>
            <h4>Штаб-квартира:</h4>
            <p>Якутск</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Якутска (YKS)</p>
            <h4>Вебсайт:</h4>
            <a href="https://yakutia.aero" target="_blank" rel="noreferrer">yakutia.aero</a>
          `,
        },
      ],
    },    
    rossiyaAirlines: {
      pageKey: "rossiyaAirlinesInfo",
      imageUrl: "/images/AK/RO.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Rossiya Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Rossiya Airlines — российская авиакомпания, основанная в 1934 году. Является одной из крупнейших авиакомпаний России и дочерним предприятием группы "Аэрофлот". Компания выполняет как внутренние, так и международные рейсы. Rossiya Airlines известна высоким качеством обслуживания и безопасностью перелетов.</p>
            <h3>Флот Rossiya Airlines</h3>
            <p>Авиакомпания эксплуатирует современные воздушные суда для внутреннего и международного сообщения:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~40</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~30</td>
                  <td>Короткие и региональные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~10</td>
                  <td>Дальнемагистральные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Rossiya Airlines выполняет рейсы по различным направлениям, включая рейсы из России в Узбекистан:</p>
            <ul>
              <li><b>Москва – Ташкент (TAS):</b> несколько раз в неделю.</li>
              <li><b>Москва – Самарканд (SKD):</b> несколько раз в неделю.</li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Rossiya Airlines — это надежный авиаперевозчик с широким выбором рейсов, качественным обслуживанием и безопасными перелётами. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Россия</p>
            <h4>Штаб-квартира:</h4>
            <p>Москва</p>
            <h4>Основной хаб:</h4>
            <p>Шереметьево (SVO), Пулково (LED)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.rossiya-airlines.com" target="_blank" rel="noreferrer">www.rossiya-airlines.com</a>
          `,
        },
      ],
    },
    flyaristan: {
      pageKey: "flyaristanInfo",
      imageUrl: "/images/AK/FS.png",
      generalInfo: [
        {
          content: `<article>
            <h1>FlyAirstan</h1>
            <h2>Дата основания и история</h2>
            <p>FlyAirstan — казахстанская авиакомпания, основанная в 2020 году. Компания базируется в Алматы и осуществляет внутренние рейсы по Казахстану, а также международные рейсы в соседние страны Центральной Азии и на Ближний Восток. FlyAirstan ориентирована на бюджетные путешествия и предоставляет пассажирам доступные цены на авиабилеты.</p>
            <h3>Флот FlyAirstan</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~5</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~3</td>
                  <td>Среднемагистральные рейсы с увеличенной вместимостью</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>FlyAirstan выполняет регулярные рейсы как внутри Казахстана, так и на международных маршрутах:</p>
            <ul>
              <li><b>Алматы – Нур-Султан:</b> несколько раз в неделю.</li>
              <li><b>Алматы – Ташкент (TAS):</b> регулярные рейсы.</li>
              <li><b>Алматы – Дубай (DXB):</b> международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>FlyAirstan предоставляет доступные и удобные рейсы как по Казахстану, так и за рубеж, обеспечивая качественное обслуживание на борту и конкурентоспособные цены на авиабилеты.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Казахстан</p>
            <h4>Штаб-квартира:</h4>
            <p>Алматы</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Алматы (ALA)</p>
            <h4>Вебсайт:</h4>
            <a href="https://flyairstan.com" target="_blank" rel="noreferrer">flyairstan.com</a>
          `,
        },
      ],
    },
    pegasusAir: {
      pageKey: "pegasusAirInfo",
      imageUrl: "/images/AK/PC.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Pegasus Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Pegasus Airlines — турецкая бюджетная авиакомпания, основанная в 1990 году. Компания специализируется на лоукост-перевозках и выполняет рейсы по Европе, Ближнему Востоку и другим регионам.</p>
            <h3>Флот Pegasus Airlines</h3>
            <p>Авиакомпания использует современные воздушные суда для эффективных и комфортных перелётов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~50</td>
                  <td>Коротко- и среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~25</td>
                  <td>Экономичный расход топлива</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Pegasus Airlines выполняет рейсы по разным направлениям:</p>
            <ul>
              <li>
                <b>Стамбул (SAW) – Берлин (BER): </b>ежедневно.
              </li>
              <li>
                <b>Стамбул (SAW) – Лондон (STN): </b>несколько раз в неделю.
              </li>
              <li>
                <b>Анкара (ESB) – Дубай (DXB): </b>регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Pegasus Airlines предлагает доступные перелёты по популярным направлениям. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Турция</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Стамбул</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт имени Сабихи Гёкчен (SAW)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.flypgs.com" target="_blank" rel="noreferrer">www.flypgs.com</a>
          `,
        },
      ],
    },    
    nordwindAir: {
      pageKey: "nordwindAirInfo",
      imageUrl: "/images/AK/N4.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Nordwind Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Nordwind Airlines — российская авиакомпания, основанная в 2008 году. Компания специализируется на чартерных и регулярных международных рейсах, предлагая доступные перелёты по популярным туристическим направлениям.</p>
            <h3>Флот Nordwind Airlines</h3>
            <p>Авиакомпания использует современные воздушные суда для обеспечения комфорта и безопасности пассажиров:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~5</td>
                  <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~10</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~15</td>
                  <td>Коротко- и среднемагистральные перелёты</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Nordwind Airlines выполняет рейсы по разным направлениям:</p>
            <ul>
              <li>
                <b>Москва (SVO) – Анталия (AYT): </b>ежедневно.
              </li>
              <li>
                <b>Москва (SVO) – Дубай (DXB): </b>несколько раз в неделю.
              </li>
              <li>
                <b>Санкт-Петербург (LED) – Сочи (AER): </b>регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Nordwind Airlines — это удобные и доступные перелёты по популярным туристическим маршрутам. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Россия</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Москва</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Шереметьево (SVO)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.nordwindairlines.ru" target="_blank" rel="noreferrer">www.nordwindairlines.ru</a>
          `,
        },
      ],
    },    
lufthansaAir: {
  pageKey: "lufthansaAirInfo",
  imageUrl: "/images/AK/LH.png",
  generalInfo: [
    {
      content: `<article>
        <h1>Lufthansa</h1>
        <h2>Дата основания и история</h2>
        <p>Lufthansa — крупнейшая авиакомпания Германии, основанная в 1953 году. Она является одним из ведущих авиаперевозчиков Европы и участником альянса Star Alliance.</p>
        <h3>Флот Lufthansa</h3>
        <p>Авиакомпания использует широкий спектр современных воздушных судов для обеспечения комфорта и безопасности пассажиров:</p>
        <table>
          <thead>
            <tr>
              <th>Тип самолёта</th>
              <th>Количество (примерно)</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Airbus A320</td>
              <td>~100</td>
              <td>Коротко- и среднемагистральные маршруты</td>
            </tr>
            <tr>
              <td>Boeing 747</td>
              <td>~30</td>
              <td>Флагманские дальнемагистральные рейсы</td>
            </tr>
            <tr>
              <td>Airbus A350</td>
              <td>~20</td>
              <td>Энергоэффективные межконтинентальные полёты</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов</h3>
        <p>Lufthansa выполняет рейсы по множеству направлений:</p>
        <ul>
          <li>
            <b>Франкфурт (FRA) – Нью-Йорк (JFK): </b>ежедневно.
          </li>
          <li>
            <b>Мюнхен (MUC) – Токио (HND): </b>ежедневно.
          </li>
          <li>
            <b>Франкфурт (FRA) – Дубай (DXB): </b>несколько раз в неделю.
          </li>
        </ul>
        <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
        <h3>Вывод</h3>
        <p>Lufthansa — ведущий европейский авиаперевозчик с высоким уровнем сервиса и широким выбором направлений. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
      </article>`,
    },
  ],
  origin: [
    {
      content: `
        <h4>Страна: </h4>
        <p>Германия</p> 
        <h4>Штаб-квартира: </h4> 
        <p>Кёльн</p> 
        <h4>Основной хаб: </h4>   
        <p>Международный аэропорт Франкфурта (FRA)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.lufthansa.com" target="_blank" rel="noreferrer">www.lufthansa.com</a>
      `,
    },
  ],
},
    silkAir: {
      pageKey: "silkAviaInfo",
      imageUrl: "/images/AK/US.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Silk Avia</h1>
            <h2>Дата основания и история</h2>
            <p>Silk Avia — первая региональная авиакомпания Узбекистана, основанная 6 июля 2021 года как дочерняя компания АО «Uzbekistan Airports». Целью создания авиакомпании является стимулирование деловой активности и развитие внутреннего туризма в стране. Первый коммерческий рейс был выполнен 25 апреля 2023 года по маршруту Ташкент — Самарканд.</p>
            <h3>Флот Silk Avia</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ATR 72-600</td>
                  <td>3</td>
                  <td>Вместимость до 70 пассажиров, экономичность на региональных маршрутах</td>
                </tr>
                <tr>
                  <td>ATR 42-600</td>
                  <td>2 (планируется)</td>
                  <td>Вместимость до 50 пассажиров, подходит для менее загруженных направлений</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Silk Avia выполняет регулярные рейсы между городами Узбекистана, включая:</p>
            <ul>
              <li><b>Ташкент – Бухара:</b> до 16 рейсов в неделю.</li>
              <li><b>Ташкент – Термез:</b> до 16 рейсов в неделю.</li>
              <li><b>Нукус – Муйнак:</b> регулярные рейсы с 1 апреля 2024 года.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Silk Avia предлагает комфортные и доступные перелёты внутри Узбекистана, способствуя развитию внутреннего туризма и обеспечивая удобное сообщение между регионами страны.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Узбекистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Ташкент</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Самарканда (SKD)</p>
            <h4>Вебсайт:</h4>
            <a href="https://silk-avia.com" target="_blank" rel="noreferrer">silk-avia.com</a>
          `,
        },
      ],
    },
    tajikAir: {
      pageKey: "tajikAirInfo",
      imageUrl: "/images/AK/7J.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Tajik Air</h1>
            <h2>Дата основания и история</h2>
            <p>Tajik Air — национальная авиакомпания Таджикистана, основанная в 1924 году. Это одна из старейших авиакомпаний в Центральной Азии, выполняющая как внутренние, так и международные рейсы.</p>
            <h3>Флот Tajik Air</h3>
            <table>
              <thead>
                <tr>
                  <th>Тип воздушного судна</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 757</td>
                  <td>~2</td>
                  <td>Дальнемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Ан-28</td>
                  <td>~3</td>
                  <td>Короткие региональные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Tajik Air выполняет рейсы в ключевые направления:</p>
            <ul>
              <li><b>Душанбе (DYU) – Москва (DME):</b> Регулярные рейсы.</li>
              <li><b>Душанбе (DYU) – Дубай (DXB):</b> Международные рейсы.</li>
              <li><b>Худжанд (LBD) – Бишкек (FRU):</b> Региональные рейсы.</li>
            </ul>
            <h3>Заключение</h3>
            <p>Tajik Air играет важную роль в транспортной системе Таджикистана, обеспечивая связь между городами и международными направлениями.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Таджикистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Душанбе</p>
            <h4>Главный хаб:</h4>
            <p>Международный аэропорт Душанбе (DYU)</p>
            <h4>Веб-сайт:</h4>
            <a href="https://www.tajikair.tj" target="_blank" rel="noreferrer">www.tajikair.tj</a>
           ` ,
        },
      ],
    },
    somonAir: {
      pageKey: "somonAirInfo",
      imageUrl: "/images/AK/SZ.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Somon Air</h1>
            <h2>Дата основания и история</h2>
            <p>Somon Air — таджикская авиакомпания, основанная в 2008 году. Это первая частная авиакомпания в Таджикистане, выполняющая как внутренние, так и международные рейсы.</p>
            <h3>Флот Somon Air</h3>
            <table>
              <thead>
                <tr>
                  <th>Тип воздушного судна</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~6</td>
                  <td>Рейсы малой и средней дальности</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Somon Air выполняет рейсы в ключевые направления:</p>
            <ul>
              <li><b>Душанбе (DYU) – Москва (DME):</b> Частые рейсы.</li>
              <li><b>Душанбе (DYU) – Дубай (DXB):</b> Регулярные рейсы.</li>
              <li><b>Худжанд (LBD) – Стамбул (IST):</b> Сезонные рейсы.</li>
            </ul>
            <h3>Заключение</h3>
            <p>Somon Air известна высокими стандартами безопасности и растущим международным присутствием.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Таджикистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Душанбе</p>
            <h4>Главный хаб:</h4>
            <p>Международный аэропорт Душанбе (DYU)</p>
            <h4>Веб-сайт:</h4>
            <a href="https://www.somonair.com" target="_blank" rel="noreferrer">www.somonair.com</a>
            `,
        },
      ],
    },
    kamair: {
      pageKey: "kamAirInfo",
      imageUrl: "/images/AK/RQ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Kam Air</h1>
            <h2>Дата основания и история</h2>
            <p>Kam Air — афганская авиакомпания, основанная в 2003 году. Это первая частная авиакомпания Афганистана, выполняющая как внутренние, так и международные рейсы.</p>
            <h3>Флот Kam Air</h3>
            <p>Авиакомпания использует различные типы воздушных судов для обеспечения безопасности и удобства пассажиров:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~3</td>
                  <td>Среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A340</td>
                  <td>~2</td>
                  <td>Дальнемагистральные перелёты</td>
                </tr>
                <tr>
                  <td>McDonnell Douglas MD-87</td>
                  <td>~2</td>
                  <td>Региональные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Kam Air выполняет рейсы по ключевым направлениям:</p>
            <ul>
              <li>
                <b>Кабул – Дубай (DXB): </b>несколько раз в неделю.
              </li>
              <li>
                <b>Кабул – Стамбул (IST): </b>2-3 раза в неделю.
              </li>
              <li>
                <b>Кабул – Москва (VKO): </b>1-2 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Kam Air — ведущая частная авиакомпания Афганистана с широким выбором направлений. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Афганистан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Кабул</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Кабула (KBL)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.kamair.com" target="_blank" rel="noreferrer">www.kamair.com</a>
          `,
        },
      ],
    },
    ilkarAir: {
      pageKey: "ilkarAirInfo",
      imageUrl: "/images/AK/EO.png",
      generalInfo: [
        {
          content:`  <article>
            <h1>Ilkar Air</h1>
            <h2>Дата основания и история</h2>
            <p>Ilkar Air — частная авиакомпания, основанная в 1995 году. Специализируется на выполнении внутренних и международных рейсов, предлагая премиальные услуги для пассажиров.</p>
            <h3>Флот Ilkar Air</h3>
            <p>Авиакомпания эксплуатирует современный парк самолетов для дальнемагистральных и региональных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A330</td>
                  <td>8</td>
                  <td>Дальнемагистральные международные рейсы</td>
                </tr>
                <tr>
                  <td>Embraer E190</td>
                  <td>12</td>
                  <td>Региональные и короткие маршруты</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Ilkar Air обслуживает более 30 направлений, включая:</p>
            <ul>
              <li><b>Основной хаб — Лондон:</b> частые рейсы.</li>
              <li><b>Основной хаб — Дубай:</b> регулярные рейсы.</li>
              <li><b>Основной хаб — Нью-Йорк:</b> дальнемагистральные международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Ilkar Air известна своей надежностью и международной экспансией, что делает её важным игроком в авиационной отрасли.</p>
          </article> `,
        }
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Неизвестно</p>
            <h4>Штаб-квартира:</h4>
            <p>Город основного хаба</p>
            <h4>Основной хаб:</h4>
            <p>Основной международный аэропорт</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.ilkarair.com" target="_blank" rel="noreferrer">ilkarair.com</a>
          `,
        },
      ],
    },
    flydubaiAir: {
      pageKey: "flydubaiAirInfo",
      imageUrl: "/images/AK/FZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Flydubai</h1>
            <h2>Дата основания и история</h2>
            <p>Flydubai — бюджетная авиакомпания из ОАЭ, основанная в 2008 году. Компания предлагает доступные международные перелёты, охватывая более 100 направлений по всему миру.</p>
            <h3>Флот Flydubai</h3>
            <p>Авиакомпания использует современные воздушные суда для эффективных и комфортных перелётов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737-800</td>
                  <td>~30</td>
                  <td>Среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Boeing 737 MAX</td>
                  <td>~40</td>
                  <td>Современные технологии и экономичность</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Flydubai выполняет рейсы между Дубаем и различными странами, включая Узбекистан:</p>
            <ul>
              <li>
                <b>Ташкент – Дубай (DXB): </b>ежедневно.
              </li>
              <li>
                <b>Самарканд – Дубай (DXB): </b>2-3 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Flydubai — это доступные перелёты с удобным расписанием и качественным сервисом. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Объединённые Арабские Эмираты</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Дубай</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Дубая (DXB)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.flydubai.com" target="_blank" rel="noreferrer">www.flydubai.com</a>
          `,
        },
      ],
    },
    kuwaitAirways: {
      pageKey: "kuwaitAirwaysInfo",
      imageUrl: "/images/AK/KU.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Kuwait Airways</h1>
            <h2>Дата основания и история</h2>
            <p>Kuwait Airways — национальная авиакомпания Кувейта, основанная в 1953 году. Это крупнейший перевозчик в Кувейте, предлагающий как региональные, так и международные рейсы.</p>
            <h3>Флот Kuwait Airways</h3>
            <p>Флот компании включает самолёты для дальнемагистральных и региональных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>6</td>
                  <td>Для дальнемагистральных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>13</td>
                  <td>Для региональных рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Kuwait Airways обслуживает более 40 направлений, включая:</p>
            <ul>
              <li><b>Кувейт – Лондон:</b> регулярные рейсы.</li>
              <li><b>Кувейт – Дубай:</b> регулярные рейсы.</li>
              <li><b>Кувейт – Нью-Йорк:</b> дальнемагистральные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Kuwait Airways предлагает качественные услуги на международных рейсах и является ведущим перевозчиком в регионе.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Кувейт</p>
            <h4>Штаб-квартира:</h4>
            <p>Кувейт-Сити</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Кувейта (KWI)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.kuwaitairways.com" target="_blank" rel="noreferrer">kuwaitairways.com</a>
          `,
        },
      ],
    },
    emiratesAir: {
      pageKey: "emiratesAirInfo",
      imageUrl: "/images/AK/EK.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Emirates</h1>
            <h2>Дата основания и история</h2>
            <p>Emirates — одна из крупнейших авиакомпаний мира, основанная в 1985 году в Дубае (ОАЭ). Авиакомпания известна высоким уровнем сервиса и выполняет рейсы в более чем 150 направлений по всему миру.</p>
            <h3>Флот Emirates</h3>
            <p>Авиакомпания эксплуатирует один из самых современных и комфортабельных авиапарков:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A380</td>
                  <td>~120</td>
                  <td>Двухпалубный лайнер для дальних рейсов</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~140</td>
                  <td>Гибкость в дальних и среднемагистральных перелётах</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Emirates выполняет регулярные рейсы между Дубаем и крупнейшими городами мира, включая Узбекистан:</p>
            <ul>
              <li>
                <b>Ташкент – Дубай (DXB): </b>ежедневно.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Emirates — авиакомпания премиального уровня, предлагающая удобные маршруты и первоклассный сервис. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Объединённые Арабские Эмираты</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Дубай</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Дубая (DXB)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.emirates.com" target="_blank" rel="noreferrer">www.emirates.com</a>
          `,
        },
      ],
    },
    centrumAir: {
      pageKey: "centrumAirInfo",
      imageUrl: "/images/AK/C6.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Centrum Air</h1>
            <h2>Дата основания и история</h2>
            <p>Centrum Air — узбекистанская авиакомпания, основанная в 2022 году. Компания ориентирована на бюджетные и чартерные рейсы, обеспечивая доступные перелёты внутри страны и за её пределами.</p>
            <h3>Флот Centrum Air</h3>
            <p>Авиакомпания использует современные воздушные суда для эффективных и комфортных перелётов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~5</td>
                  <td>Среднемагистральные маршруты</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Centrum Air выполняет рейсы по разным направлениям:</p>
            <ul>
              <li>
                <b>Ташкент – Москва (VKO): </b>несколько раз в неделю.
              </li>
              <li>
                <b>Ташкент – Дубай (DXB): </b>2-3 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Centrum Air — это доступные перелёты с удобным расписанием. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Узбекистан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Ташкент</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Ташкента (TAS)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.centrumair.uz" target="_blank" rel="noreferrer">www.centrumair.uz</a>
          `,
        },
      ],
    },    
    egyptAir: {
      pageKey: "egyptAirInfo",
      imageUrl: "/images/AK/MS.png",
      generalInfo: [
        {
          content: `<article>
            <h1>EgyptAir</h1>
            <h2>Дата основания и история</h2>
            <p>EgyptAir — национальная авиакомпания Египта, основанная в 1932 году. Это одна из старейших авиакомпаний мира и первый авиаперевозчик на Ближнем Востоке. EgyptAir является членом альянса Star Alliance и выполняет регулярные рейсы по всему миру.</p>
            <h3>Флот EgyptAir</h3>
            <p>Авиакомпания использует современные воздушные суда для внутренних и международных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~20</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~30</td>
                  <td>Короткие и региональные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~10</td>
                  <td>Дальнемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~6</td>
                  <td>Высокий комфорт для международных перелётов</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>EgyptAir выполняет рейсы между Египтом и различными странами, включая Узбекистан:</p>
            <ul>
              <li>
                <b>Ташкент – Каир (CAI): </b>2 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>EgyptAir — ведущая авиакомпания Ближнего Востока, предлагающая удобные перелёты и высокий уровень сервиса. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Египет</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Каир</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Каира (CAI)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.egyptair.com" target="_blank" rel="noreferrer">www.egyptair.com</a>
          `,
        },
      ],
    },    
    chinaSouthern: {
      pageKey: "chinaSouthernInfo",
      imageUrl: "/images/AK/CZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>China Southern Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>China Southern Airlines (中国南方航空) — одна из крупнейших авиакомпаний Китая, основанная в 1988 году. Является флагманским перевозчиком страны и входит в число ведущих авиакомпаний мира по пассажирообороту. Осуществляет внутренние и международные рейсы, включая маршруты в Узбекистан.</p>
            <h3>Флот China Southern Airlines</h3>
            <p>Компания эксплуатирует широкий парк современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~200</td>
                  <td>Коротко- и среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~300</td>
                  <td>Гибкий вариант для внутренних и региональных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A350</td>
                  <td>~20</td>
                  <td>Дальнемагистральные рейсы с высокой комфортностью</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~40</td>
                  <td>Энергоэффективные дальнемагистральные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>China Southern Airlines выполняет рейсы из Узбекистана в Китай:</p>
            <ul>
              <li>
                <b>Ташкент – Гуанчжоу (CAN): </b>2-3 раза в неделю.
              </li>
              <li>
                <b>Ташкент – Пекин (PEK): </b>1-2 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>China Southern Airlines — ведущая китайская авиакомпания с широкой маршрутной сетью и современным флотом. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Китай</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Гуанчжоу</p> 
            <h4>Основные хабы: </h4>   
            <p>Гуанчжоу Байюнь (CAN), Пекин Дасин (PKX), Шанхай Пудун (PVG)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.csair.com" target="_blank" rel="noreferrer">www.csair.com</a>
          `,
        },
      ],
    },
    pia: {
      pageKey: "piaInfo",
      imageUrl: "/images/AK/PK.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Pakistan International Airlines (PIA)</h1>
            <h2>Дата основания и история</h2>
            <p>PIA была основана в 1955 году и является национальной авиакомпанией Пакистана. Она обслуживает широкий спектр международных и внутренних рейсов, представляя Пакистан на глобальной арене.</p>
            <h3>Флот PIA</h3>
            <p>Флот PIA состоит из воздушных судов, подходящих для дальних и средних рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>5</td>
                  <td>Для дальнемагистральных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>10</td>
                  <td>Для внутренних и региональных маршрутов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>PIA выполняет рейсы в более 30 направлений, включая:</p>
            <ul>
              <li><b>Исламабад – Лондон:</b> регулярные рейсы.</li>
              <li><b>Карачи – Дубай:</b> регулярные рейсы.</li>
              <li><b>Лахор – Нью-Йорк:</b> дальнемагистральные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>PIA предлагает разнообразие направлений для путешественников, обслуживая как дальние, так и региональные маршруты с высококачественными услугами.</p>
          </article>  `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Пакистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Исламабад</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Карачи (KHI)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.piac.com.pk" target="_blank" rel="noreferrer">piac.com.pk</a>
          `,
        },
      ],
    },
    airblue: {
      pageKey: "airblueInfo",
      imageUrl: "/images/AK/PA.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Airblue</h1>
            <h2>Дата основания и история</h2>
            <p>Airblue была основана в 2003 году и является одной из крупнейших частных авиакомпаний Пакистана. Авиакомпания обслуживает как внутренние рейсы, так и международные направления.</p>
            <h3>Флот Airblue</h3>
            <p>Флот Airblue включает воздушные суда для региональных и международных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>7</td>
                  <td>Для региональных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>4</td>
                  <td>Для дальних рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Airblue выполняет рейсы в более 15 направлений, включая:</p>
            <ul>
              <li><b>Исламабад – Лахор:</b> регулярные рейсы.</li>
              <li><b>Карачи – Дубай:</b> регулярные рейсы.</li>
              <li><b>Исламабад – Манчестер:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Airblue обеспечивает доступные и удобные рейсы для внутренних и международных путешественников.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Пакистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Исламабад</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Исламабада (ISB)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airblue.com" target="_blank" rel="noreferrer">airblue.com</a>
          `,
        },
      ],
    },
      sereneAir: {
        pageKey: "sereneAirInfo",
        imageUrl: "/images/AK/ER.png",
        generalInfo: [
          {
            content:` <article>
              <h1>SereneAir</h1>
              <h2>Дата основания и история</h2>
              <p>SereneAir была основана в 2017 году и является частным авиаперевозчиком в Пакистане. Компания выполняет как внутренние, так и международные рейсы, предлагая удобство и доступные цены для пассажиров.</p>
              <h3>Флот SereneAir</h3>
              <p>Флот SereneAir включает самолёты для коротких и средних рейсов:</p>
              <table>
                <thead>
                  <tr>
                    <th>Тип самолёта</th>
                    <th>Количество (примерно)</th>
                    <th>Особенности</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Boeing 737-800</td>
                    <td>6</td>
                    <td>Для внутренних и региональных рейсов</td>
                  </tr>
                </tbody>
              </table>
              <h3>Маршрутная сеть</h3>
              <p>SereneAir выполняет рейсы в более 10 направлений, включая:</p>
              <ul>
                <li><b>Исламабад – Карачи:</b> регулярные рейсы.</li>
                <li><b>Лахор – Дубай:</b> регулярные рейсы.</li>
                <li><b>Исламабад – Меджид:</b> регулярные рейсы.</li>
              </ul>
              <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
              <h3>Вывод</h3>
              <p>SereneAir предоставляет комфортные и недорогие рейсы как для внутренних, так и международных маршрутов.</p>
            </article>`,
          },
        ],
        origin: [
          {
            content: `
              <h4>Страна:</h4>
              <p>Пакистан</p>
              <h4>Штаб-квартира:</h4>
              <p>Исламабад</p>
              <h4>Основной хаб:</h4>
              <p>Международный аэропорт Исламабада (ISB)</p>
              <h4>Вебсайт:</h4>
              <a href="https://www.sereneair.com" target="_blank" rel="noreferrer">sereneair.com</a>
            `,
          },
        ],
      },
    airIndia: {
      pageKey: "airIndiaInfo",
      imageUrl: "/images/AK/AI.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Air India</h1>
            <h2>Дата основания и история</h2>
            <p>Air India была основана в 1932 году и является национальной авиакомпанией Индии. Компания обслуживает множество международных и внутренних рейсов, являясь одной из крупнейших авиакомпаний в Южной Азии.</p>
            <h3>Флот Air India</h3>
            <p>Флот Air India состоит из современных воздушных судов для дальнемагистральных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>6</td>
                  <td>Для дальних рейсов, экономичность</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>30</td>
                  <td>Для внутренних и региональных маршрутов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Air India выполняет рейсы в более чем 50 направлений, включая:</p>
            <ul>
              <li><b>Дели – Нью-Йорк:</b> регулярные рейсы.</li>
              <li><b>Мумбаи – Лондон:</b> регулярные рейсы.</li>
              <li><b>Дели – Сингапур:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Air India предоставляет широкий выбор направлений и высококачественные услуги для пассажиров по всему миру.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Индия</p>
            <h4>Штаб-квартира:</h4>
            <p>Нью-Дели</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Индиры Ганди (DEL)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airindia.in" target="_blank" rel="noreferrer">airindia.in</a>
          `,
        },
      ],
    },
    vistara: {
      pageKey: "vistaraInfo",
      imageUrl: "/images/AK/UK.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Vistara</h1>
            <h2>Дата основания и история</h2>
            <p>Vistara была основана в 2013 году и является совместным предприятием Tata Sons и Singapore Airlines. Она предоставляет услуги на высоком уровне и выполняет как международные, так и внутренние рейсы.</p>
            <h3>Флот Vistara</h3>
            <p>Флот Vistara состоит из современных воздушных судов для дальних и средних рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>30</td>
                  <td>Для внутренних рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>10</td>
                  <td>Для международных рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Vistara выполняет рейсы по более чем 30 направлениям, включая:</p>
            <ul>
              <li><b>Дели – Мумбаи:</b> регулярные рейсы.</li>
              <li><b>Дели – Сингапур:</b> регулярные рейсы.</li>
              <li><b>Мумбаи – Бангкок:</b> международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Vistara — это премиум-авиакомпания с высококачественным обслуживанием и комфортом для пассажиров.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Индия</p>
            <h4>Штаб-квартира:</h4>
            <p>Гургаон</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Индиры Ганди (DEL)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airvistara.com" target="_blank" rel="noreferrer">airvistara.com</a>
          `,
        },
      ],
    },
    gulfAir: {
      pageKey: "gulfAirInfo",
      imageUrl: "/images/AK/GF.png",
      generalInfo: [
        {
          content: `<article>
                    <h1>Gulf Air</h1>
                    <h2>Дата основания и история</h2>
                    <p>Gulf Air была основана в 1950 году и является национальной авиакомпанией Бахрейна. Компания предоставляет качественные услуги на региональных и международных рейсах.</p>
                    <h3>Флот Gulf Air</h3>
                    <p>Флот Gulf Air состоит из современных и эффективных воздушных судов, адаптированных для различных направлений:</p>
                    <table>
                      <thead>
                        <tr>
                          <th>Тип самолёта</th>
                          <th>Количество (примерно)</th>
                          <th>Особенности</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Boeing 787-9</td>
                          <td>10</td>
                          <td>Для дальнемагистральных международных рейсов</td>
                        </tr>
                        <tr>
                          <td>Airbus A321neo</td>
                          <td>8</td>
                          <td>Для среднемагистральных рейсов</td>
                        </tr>
                      </tbody>
                    </table>
                    <h3>Маршрутная сеть</h3>
                    <p>Gulf Air выполняет рейсы более чем в 50 направлений, включая:</p>
                    <ul>
                      <li><b>Манама – Дубай:</b> регулярные рейсы.</li>
                      <li><b>Манама – Лондон:</b> регулярные рейсы.</li>
                      <li><b>Манама – Мумбаи:</b> регулярные рейсы.</li>
                    </ul>
                    <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
                    <h3>Вывод</h3>
                    <p>Gulf Air – это авиакомпания, предоставляющая качественные услуги и комфортные перелёты на региональных и международных маршрутах.</p>
                  </article> `,
        },
      ],
      origin: [
        {
          content: `
                    <h4>Страна:</h4>
                    <p>Бахрейн</p>
                    <h4>Штаб-квартира:</h4>
                    <p>Манама</p>
                    <h4>Основной хаб:</h4>
                    <p>Международный аэропорт Бахрейна (BAH)</p>
                    <h4>Вебсайт:</h4>
          <a href="https://www.gulfair.com" target="_blank" rel="noreferrer">gulfair.com</a>
          `,
        },
      ],
    },
      omanAir: {
      pageKey: "omanAirInfo",
      imageUrl: "/images/AK/WY.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Oman Air</h1>
            <h2>Дата основания и история</h2>
            <p>Oman Air была основана в 1993 году как национальная авиакомпания Омана. Авиакомпания выполняет как внутренние, так и международные рейсы, обеспечивая комфортные перелёты на дальние расстояния и в соседние страны.</p>
            <h3>Флот Oman Air</h3>
            <p>Флот Oman Air состоит из современных воздушных судов для международных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 787 Dreamliner</td>
                  <td>5</td>
                  <td>Для дальнемагистральных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>6</td>
                  <td>Для средних и дальних маршрутов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Oman Air выполняет рейсы в более чем 50 направлений, включая:</p>
            <ul>
              <li><b>Мускат – Дубай:</b> регулярные рейсы.</li>
              <li><b>Мускат – Лондон:</b> регулярные рейсы.</li>
              <li><b>Мускат – Сингапур:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Oman Air предлагает комфортные и удобные рейсы, соединяя Оман с рядом международных направлений, предоставляя высококачественные услуги.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Оман</p>
            <h4>Штаб-квартира:</h4>
            <p>Мускат</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Мускат (MCT)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.omanair.com" target="_blank" rel="noreferrer">omanair.com</a>
          `,
        },
      ],
    },
    salamAir: {
      pageKey: "salamAirInfo",
      imageUrl: "/images/AK/OV.png",
      generalInfo: [
        {
          content: `<article>
            <h1>SalamAir</h1>
            <h2>Дата основания и история</h2>
            <p>SalamAir была основана в 2016 году и является первым бюджетным авиаперевозчиком Омана. Компания ориентирована на предоставление недорогих рейсов внутри региона и за его пределы.</p>
            <h3>Флот SalamAir</h3>
            <p>Флот SalamAir состоит из экономичных воздушных судов для региональных и международных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A320neo</td>
                  <td>8</td>
                  <td>Для региональных и коротких рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>SalamAir выполняет рейсы в более чем 30 направлений, включая:</p>
            <ul>
              <li><b>Мускат – Дубай:</b> регулярные рейсы.</li>
              <li><b>Мускат – Джидда:</b> регулярные рейсы.</li>
              <li><b>Мускат – Кочин:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>SalamAir предоставляет бюджетные и удобные рейсы для путешественников, предпочитающих доступные цены на перелёты.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Оман</p>
            <h4>Штаб-квартира:</h4>
            <p>Мускат</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Мускат (MCT)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.salamair.com" target="_blank" rel="noreferrer">salamair.com</a>
          `,
        },
      ],
    },
    iranAsemanAirlines: {
      pageKey: "iranAsemanAirlinesInfo",
      imageUrl: "/images/AK/IB.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Iran Aseman Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Iran Aseman Airlines — иранская авиакомпания, основанная в 1980 году. Это один из крупнейших перевозчиков в Иране, обеспечивающий как внутренние, так и международные рейсы. Авиакомпания обслуживает более 40 направлений по всему миру.</p>
            <h3>Флот Iran Aseman Airlines</h3>
            <p>Флот компании состоит из широкофюзеляжных и узкофюзеляжных самолетов для разных типов рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ATR 72-600</td>
                  <td>5</td>
                  <td>Экономичные самолёты для региональных рейсов</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>6</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Iran Aseman Airlines выполняет рейсы по следующим направлениям:</p>
            <ul>
              <li><b>Тегеран – Шарджа:</b> регулярные рейсы.</li>
              <li><b>Тегеран – Дамаск:</b> международные рейсы.</li>
              <li><b>Тегеран – Багдад:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Iran Aseman Airlines предоставляет широкий выбор рейсов как внутри страны, так и за рубежом, обеспечивая удобные условия для пассажиров.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Иран</p>
            <h4>Штаб-квартира:</h4>
            <p>Тегеран</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Тегерана (IKA)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.apa-air.com" target="_blank" rel="noreferrer">apa-air.com</a>
          `,
        },
      ],
    },
    kishAir: {
      pageKey: "kishAirInfo",
      imageUrl: "/images/AK/Y9.jpeg",
      generalInfo: [
        {
          content: `<article>
            <h1>Kish Air</h1>
            <h2>Дата основания и история</h2>
            <p>Kish Air — иранская авиакомпания, основанная в 1993 году. Компания является крупнейшим перевозчиком на острове Киш и предоставляет регулярные рейсы в другие города Ирана и международные направления.</p>
            <h3>Флот Kish Air</h3>
            <p>Флот авиакомпании состоит из воздушных судов для региональных и международных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>5</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Fokker 100</td>
                  <td>2</td>
                  <td>Для региональных рейсов с вместимостью до 100 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Kish Air выполняет рейсы на следующие маршруты:</p>
            <ul>
              <li><b>Киш – Тегеран:</b> регулярные рейсы.</li>
              <li><b>Киш – Шарджа:</b> международные рейсы.</li>
              <li><b>Киш – Дубай:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Kish Air обеспечивает качественные рейсы между островом Киш и другими городами Ирана и ближневосточными странами.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Иран</p>
            <h4>Штаб-квартира:</h4>
            <p>Тегеран</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Киш (KIH)</p>
            <h4>Вебсайт:</h4>
            <a href="https://kishairlines.ir" target="_blank" rel="noreferrer">kishairlines.ir</a>
          `,
        },
      ],
    },
    qeshmAir: {
      pageKey: "qeshmAirInfo",
      imageUrl: "/images/AK/QB.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Qeshm Air</h1>
            <h2>Дата основания и история</h2>
            <p>Qeshm Air — иранская авиакомпания, основанная в 1993 году. Авиакомпания осуществляет рейсы внутри Ирана и на международные маршруты, соединяя Иран с соседними странами и регионами.</p>
            <h3>Флот Qeshm Air</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов для разных типов рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>4</td>
                  <td>Для средних маршрутов с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Fokker 100</td>
                  <td>3</td>
                  <td>Для региональных рейсов с вместимостью до 100 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Qeshm Air обслуживает рейсы по следующим маршрутам:</p>
            <ul>
              <li><b>Тегеран – Киш:</b> регулярные рейсы.</li>
              <li><b>Тегеран – Шарджа:</b> международные рейсы.</li>
              <li><b>Тегеран – Дубай:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Qeshm Air предоставляет удобные рейсы внутри Ирана и в соседние страны.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Иран</p>
            <h4>Штаб-квартира:</h4>
            <p>Тегеран</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Киш (KIH)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.qeshm-air.com" target="_blank" rel="noreferrer" >qeshm-air.com</a>
          `,
        },
      ],
    },
    syrianAir: {
      pageKey: "syrianAirInfo",
      imageUrl: "/images/AK/RB.png",
      generalInfo: [
        {
          content:` <article>
            <h1>SyrianAir</h1>
            <h2>Дата основания и история</h2>
            <p>SyrianAir — национальная авиакомпания Сирии, основанная в 1946 году. Авиакомпания предоставляет внутренние и международные рейсы, являясь важным перевозчиком для региона Ближнего Востока.</p>
            <h3>Флот SyrianAir</h3>
            <p>Флот авиакомпании включает несколько типов самолётов для внутренних и международных маршрутов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>4</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Boing 737</td>
                  <td>2</td>
                  <td>Региональные и международные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>SyrianAir обслуживает рейсы по следующим направлениям:</p>
            <ul>
              <li><b>Дамаск – Бейрут:</b> регулярные рейсы.</li>
              <li><b>Дамаск – Москва:</b> международные рейсы.</li>
              <li><b>Дамаск – Каир:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>SyrianAir является важным перевозчиком в регионе и предоставляет рейсы в страны Ближнего Востока и Европы.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Сирия</p>
            <h4>Штаб-квартира:</h4>
            <p>Дамаск</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Дамаска (DAM)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.syriaair.com" target="_blank" rel="noreferrer">syriaair.com</a>
          `,
        },
      ],
    },
    middleEastAirlines: {
      pageKey: "middleEastAirlinesInfo",
      imageUrl: "/images/AK/ME.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Middle East Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Middle East Airlines (MEA) — национальная авиакомпания Ливана, основанная в 1945 году. Это один из крупнейших авиаперевозчиков на Ближнем Востоке, предлагающий как региональные, так и международные рейсы.</p>
            <h3>Флот Middle East Airlines</h3>
            <p>Флот компании включает современные самолёты для международных маршрутов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>8</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>4</td>
                  <td>Для дальнемагистральных рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Middle East Airlines обслуживает более 30 направлений по всему миру, включая:</p>
            <ul>
              <li><b>Бейрут – Париж:</b> регулярные рейсы.</li>
              <li><b>Бейрут – Нью-Йорк:</b> международные рейсы.</li>
              <li><b>Бейрут – Дубай:</b> регулярные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Middle East Airlines является важным перевозчиком в Ливане и предоставляет широкий выбор рейсов в Европу, Америку и Ближний Восток.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Ливан</p>
            <h4>Штаб-квартира:</h4>
            <p>Бейрут</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Бейрута (BEY)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.mea.com.lb" target="_blank" rel="noreferrer">mea.com.lb</a>
          `,
        },
      ],
    },
    aircompanyArmenia: {
      pageKey: "aircompanyArmeniaInfo",
      imageUrl: "/images/AK/JI.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Aircompany Armenia</h1>
            <h2>Дата основания и история</h2>
            <p>Aircompany Armenia — армянская авиакомпания, основанная в 2003 году. Компания предоставляет внутренние и международные пассажирские перевозки, включая рейсы по направлениям в Европе, Азии и Ближнем Востоке.</p>
            <h3>Флот Aircompany Armenia</h3>
            <p>Флот авиакомпании состоит из воздушных судов, подходящих для коротких и средних расстояний:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>2</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>Служит для более коротких маршрутов с вместимостью до 140 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Aircompany Armenia обслуживает рейсы, включая:</p>
            <ul>
              <li><b>Ереван – Москва:</b> регулярные рейсы.</li>
              <li><b>Ереван – Стамбул:</b> международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Aircompany Armenia предлагает удобные рейсы в Европу и на Ближний Восток с комфортом и надежностью.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Армения</p>
            <h4>Штаб-квартира:</h4>
            <p>Ереван</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Звартноц (EVN)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.aircompanyarmenia.am" target="_blank" rel="noreferrer">aircompanyarmenia.am</a>
          `,
        },
      ],
    },
    arianaAfghanAirlines: {
      pageKey: "arianaAfghanAirlinesInfo",
      imageUrl: "/images/AK/FG.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Ariana Afghan Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Ariana Afghan Airlines — национальная авиакомпания Афганистана, основанная в 1955 году. Она является старейшей авиакомпанией страны, обеспечивая пассажирские перевозки как внутри Афганистана, так и на международных маршрутах, включая страны Азии, Европы и Ближнего Востока.</p>
            <h3>Флот Ariana Afghan Airlines</h3>
            <p>Флот авиакомпании состоит из воздушных судов, ориентированных на средне- и дальнемагистральные рейсы:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A310</td>
                  <td>2</td>
                  <td>Среднемагистральные рейсы с вместимостью до 280 пассажиров</td>
                </tr>
                <tr>
                  <td> Boeing 737-400</td>
                  <td>3</td>
                  <td>Региональные и международные рейсы с вместимостью до 160 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Ariana Afghan Airlines обслуживает рейсы по Афганистану и международным направлениям, включая:</p>
            <ul>
              <li><b>Кабул – Дубай:</b> регулярные рейсы несколько раз в неделю.</li>
              <li><b>Кабул – Пешавар:</b> несколько рейсов в неделю.</li>
              <li><b>Кабул – Исламабад:</b> международные рейсы.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Ariana Afghan Airlines предлагает надежные и доступные рейсы внутри Афганистана и на международные направления.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Афганистан</p>
            <h4>Штаб-квартира:</h4>
            <p>Кабул</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Кабула (KBL)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.flyariana.com" target="_blank" rel="noreferrer">flyariana.com</a>
          `,
        },
      ],
    },
    georgianAirways: {
      pageKey: "georgianAirwaysInfo",
      imageUrl: "/images/AK/GNN.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Georgian Airways</h1>
            <h2>Дата основания и история</h2>
            <p>Georgian Airways — национальная авиакомпания Грузии, основанная в 1994 году. Авиакомпания выполняет регулярные пассажирские рейсы на международные и внутренние направления, обеспечивая доступность транспортных услуг для грузинских и международных пассажиров.</p>
            <h3>Флот Georgian Airways</h3>
            <p>Флот авиакомпании состоит из воздушных судов, которые эффективно обслуживают как международные, так и региональные рейсы:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Embraer 190</td>
                  <td>2</td>
                  <td>Среднемагистральные рейсы с вместимостью до 100 пассажиров</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>Среднемагистральные рейсы, вместимость до 140 пассажиров</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>Georgian Airways выполняет регулярные рейсы, включая:</p>
            <ul>
              <li><b>Тбилиси – Москва:</b> несколько рейсов в неделю.</li>
              <li><b>Тбилиси – Стамбул:</b> регулярные рейсы.</li>
              <li><b>Тбилиси – Милан:</b> международные рейсы несколько раз в неделю.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Georgian Airways предоставляет удобные и надежные рейсы между Грузией и множеством международных направлений.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Грузия</p>
            <h4>Штаб-квартира:</h4>
            <p>Тбилиси</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Тбилиси (TBS)</p>
            <h4>Вебсайт:</h4>
            <a href="http://www.georgian-airways.com" target="_blank" rel="noreferrer">georgian-airways.com</a>
          `,
        },
      ],
    },
    myWayAirlines: {
      pageKey: "myWayAirlinesInfo",
      imageUrl: "/images/AK/MJ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>MyWay Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>MyWay Airlines — грузинская авиакомпания, основанная в 2017 году, специализируется на международных перевозках. Компания была создана для улучшения доступности воздушных перевозок между Грузией и другими странами региона.</p>
            <h3>Флот MyWay Airlines</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов для комфортных рейсов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>2</td>
                  <td>Среднемагистральные рейсы с вместимостью до 180 пассажиров</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>Доступный и экономичный для международных рейсов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>MyWay Airlines выполняет рейсы, включая:</p>
            <ul>
              <li><b>Тбилиси – Стамбул:</b> регулярные рейсы несколько раз в неделю.</li>
              <li><b>Тбилиси – Москва:</b> несколько рейсов в неделю.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>MyWay Airlines предлагает удобные международные рейсы с высоким качеством обслуживания.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Грузия</p>
            <h4>Штаб-квартира:</h4>
            <p>Тбилиси</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Тбилиси (TBS)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.mywayairlines.ge" target="_blank" rel="noreferrer">mywayairlines.ge</a>
          `,
        },
      ],
    },
    koreanAir: {
      pageKey: "koreanAirInfo",
      imageUrl: "/images/AK/KE.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Korean Air</h1>
            <h2>Дата основания и история</h2>
            <p>Korean Air — крупнейшая авиакомпания Южной Кореи, основанная в 1969 году. Она является национальным перевозчиком страны и предлагает широкий спектр международных и внутренних рейсов.</p>
            <h3>Флот Korean Air</h3>
            <p>Авиакомпания использует современные воздушные суда для обеспечения высокого уровня комфорта и безопасности:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~25</td>
                  <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A380</td>
                  <td>~10</td>
                  <td>Флагманские международные рейсы</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~15</td>
                  <td>Современные топливосберегающие лайнеры</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Korean Air выполняет рейсы по разным направлениям:</p>
            <ul>
              <li>
                <b>Сеул (ICN) – Нью-Йорк (JFK): </b>ежедневно.
              </li>
              <li>
                <b>Сеул (ICN) – Лондон (LHR): </b>ежедневно.
              </li>
              <li>
                <b>Сеул (ICN) – Токио (NRT): </b>несколько раз в день.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Korean Air — ведущий авиаперевозчик с высоким уровнем сервиса и широким выбором направлений. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Южная Корея</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Сеул</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Инчхон (ICN)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.koreanair.com" target="_blank" rel="noreferrer">www.koreanair.com</a>
          `,
        },
      ],
    },
    jazeeraAirways: {
      pageKey: "jazeeraAirwaysInfo",
      imageUrl: "/images/AK/J9.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Jazeera Airways</h1>
            <h2>Дата основания и история</h2>
            <p>Jazeera Airways — кувейтская бюджетная авиакомпания, основанная в 2004 году. Компания специализируется на регулярных и чартерных рейсах, обеспечивая удобные и доступные перелёты по Ближнему Востоку, Азии и Европе.</p>
            <h3>Флот Jazeera Airways</h3>
            <p>Авиакомпания использует современные воздушные суда для обеспечения безопасности и комфорта пассажиров:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~10</td>
                  <td>Коротко- и среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A320neo</td>
                  <td>~5</td>
                  <td>Экономичный расход топлива</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Jazeera Airways выполняет рейсы в различные страны, включая Узбекистан:</p>
            <ul>
              <li>
                <b>Кувейт – Ташкент (TAS): </b>1-2 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Jazeera Airways — это доступные и комфортные перелёты по ключевым направлениям. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Кувейт</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Кувейт</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Кувейта (KWI)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.jazeeraairways.com" target="_blank" rel="noreferrer">www.jazeeraairways.com</a>
          `,
        },
      ],
    },    
    mahanAir: {
      pageKey: "mahanAirInfo",
      imageUrl: "/images/AK/W5.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Mahan Air</h1>
            <h2>Дата основания и история</h2>
            <p>Mahan Air — частная иранская авиакомпания, основанная в 1991 году. Компания специализируется на внутренних и международных рейсах, предлагая пассажирам высокий уровень комфорта и широкий выбор направлений.</p>
            <h3>Флот Mahan Air</h3>
            <p>Авиакомпания использует различные типы воздушных судов для обеспечения рейсов разной дальности:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A310</td>
                  <td>~5</td>
                  <td>Дальнемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Airbus A300</td>
                  <td>~10</td>
                  <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                  <td>Airbus A340</td>
                  <td>~7</td>
                  <td>Дальние международные маршруты</td>
                </tr>
                <tr>
                  <td>BAe 146</td>
                  <td>~5</td>
                  <td>Региональные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов</h3>
            <p>Mahan Air выполняет рейсы в различные страны, включая Узбекистан:</p>
            <ul>
              <li>
                <b>Тегеран – Ташкент (TAS): </b>1-2 раза в неделю.
              </li>
            </ul>
            <p>Актуальное расписание уточняйте на <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> или на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>Mahan Air — одна из крупнейших авиакомпаний Ирана, предлагающая качественные услуги и широкий выбор направлений. Забронируйте авиабилеты по лучшим ценам на <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Иран</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Тегеран</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт имени Имама Хомейни (IKA)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.mahan.aero" target="_blank" rel="noreferrer">www.mahan.aero</a>
          `,
        },
      ],
    },
    irAero: {
      pageKey: "irAeroInfo",
      imageUrl: "/images/AK/IO.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>IR AERO</h1>
            <h2>Дата основания и история</h2>
            <p>IR AERO — российская авиакомпания, основанная в 2011 году. Компания ориентируется на выполнение региональных рейсов и обслуживание малых и средних городов России. Первый коммерческий рейс был выполнен в 2011 году, и с тех пор авиакомпания расширяет свою маршрутную сеть по всей территории страны.</p>
            <h3>Флот IR AERO</h3>
            <p>Флот авиакомпании состоит из современных воздушных судов:</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAAB 340</td>
                  <td>5</td>
                  <td>Вместимость до 34 пассажиров, идеален для коротких и региональных рейсов</td>
                </tr>
                <tr>
                  <td>Antonov An-24</td>
                  <td>3</td>
                  <td>Вместимость до 40 пассажиров, подходит для малых аэропортов и удалённых регионов</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршрутная сеть</h3>
            <p>IR AERO выполняет рейсы между различными городами России, включая:</p>
            <ul>
              <li><b>Москва – Сочи:</b> регулярные рейсы несколько раз в неделю.</li>
              <li><b>Мурманск – Архангельск:</b> регулярные рейсы на этих маршрутах.</li>
              <li><b>Владивосток – Хабаровск:</b> регулярные рейсы с 2023 года.</li>
            </ul>
            <p>Актуальное расписание и бронирование билетов доступны на официальном сайте авиакомпании.</p>
            <h3>Вывод</h3>
            <p>IR AERO предлагает удобные и надёжные перелёты по России, специализируясь на региональных маршрутах и обслуживании малых аэропортов.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна:</h4>
            <p>Россия</p>
            <h4>Штаб-квартира:</h4>
            <p>Москва</p>
            <h4>Основной хаб:</h4>
            <p>Международный аэропорт Домодедово (DME)</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.iraero.ru" target="_blank" rel="noreferrer">iraero.ru</a>
          `,
        },
      ],
    },
    indigoAirlines: {
      pageKey: "indigoInfo_ru",
      imageUrl: "/images/AK/6E.png  ",
      generalInfo: [
        {
          content:` <article>
            <h1>IndiGo Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>IndiGo — крупнейшая авиакомпания Индии, основанная в 2006 году. 
            Выполняет внутренние и международные рейсы, предлагая доступные и удобные перелеты.</p>
            <h3>Флот</h3>
            <p>IndiGo Airlines использует в своем парке самолеты Airbus.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолета</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~180</td>
                  <td>Короткие и средние дистанции</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~80</td>
                  <td>Увеличенная пассажировместимость</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>~40</td>
                  <td>Региональные рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршруты и рейсы</h3>
            <p>IndiGo выполняет внутренние рейсы по всей Индии, а также международные рейсы в Азию, Ближний Восток и Европу.</p>
            <h3>Краткий вывод</h3>
            <p>IndiGo Airlines — ведущая авиакомпания Индии, предлагающая доступные и удобные перелеты по стране и за ее пределами.</p>
            </article> `,
        },
      ],
      origin: [
        {
          content: ` 
            <h4>Страна: </h4>
            <p>Индия</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Гургаон</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт имени Индиры Ганди, Дели</p>
            <h4>Веб-сайт:</h4>
            <a href="https://www.goindigo.in" target="_blank" rel="noreferrer">www.goindigo.in</a>
          `,
        },
      ],
    },
  
    alrosaAirlines: {
      pageKey: "alrosaInfo_ru",
      imageUrl: "/images/AK/6R.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Alrosa Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Alrosa Airlines — российская авиакомпания, базирующаяся в Якутии. Основана в 1995 году. 
            Выполняет в основном внутренние рейсы, а также отдельные международные направления.</p>
            <h3>Флот</h3>
            <p>Alrosa Airlines эксплуатирует самолеты Boeing, а также модели советского производства.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолета</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~5</td>
                  <td>Внутренние и короткие международные рейсы</td>
                </tr>
                <tr>
                  <td>Tupolev Tu-154</td>
                  <td>~3</td>
                  <td>Один из исторических самолетов, используется на отдельных маршрутах</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршруты и рейсы</h3>
            <p>Alrosa Airlines выполняет рейсы по России и в отдельные города Центральной Азии.</p>
            <h3>Краткий вывод</h3>
            <p>Alrosa Airlines — авиакомпания, базирующаяся в Якутии, выполняющая полеты по России и в соседние страны.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Россия</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Мирный</p> 
            <h4>Основной хаб: </h4>   
            <p>Аэропорт Мирный</p>
            <h4>Веб-сайт:</h4>
            <a href="https://www.alrosa.aero" target="_blank" rel="noreferrer">www.alrosa.aero</a>
          `,
        },
      ],
    },
    turkmenistanAirlines: {
      pageKey: "turkmenistanInfo_ru",
      imageUrl: "/images/AK/T5.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Turkmenistan Airlines</h1>
            <h2>Дата основания и история</h2>
            <p>Turkmenistan Airlines (Türkmenhowaýollary) — национальная авиакомпания Туркменистана, основанная в 1992 году. 
            Выполняет как международные, так и внутренние рейсы.</p>
            <h3>Флот</h3>
            <p>Turkmenistan Airlines использует в своем парке самолеты Boeing.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолета</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~10</td>
                  <td>Короткие и средние дистанции</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~4</td>
                  <td>Международные дальние рейсы</td>
                </tr>
              </tbody>
            </table>
            <h3>Маршруты и рейсы</h3>
            <p>Turkmenistan Airlines выполняет рейсы из Ашхабада в Европу, Азию и страны СНГ.</p>
            <h3>Краткий вывод</h3>
            <p>Turkmenistan Airlines — главная авиакомпания Туркменистана, выполняющая внутренние и международные рейсы.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Туркменистан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Ашхабад</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Ашхабад</p>
            <h4>Веб-сайт:</h4>
            <a href="https://www.turkmenistanairlines.tm" target="_blank" rel="noreferrer">www.turkmenistanairlines.tm</a>
          `,
        },
      ],
    },    
      saudiaAirlines: {
        pageKey: "saudiaInfo_uz",
        imageUrl: "/images/AK/SV.png",
        generalInfo: [
          {
            "content": `<article>
              <h1>Saudia Airlines</h1>
              <h2>Таъсис этилган йили ва тарих</h2>
              <p>Saudia Airlines (Саудия) 1945 йилда ташкил этилган ва Саудия Арабистонининг миллий авиакомпаниясидир. 
              У халқаро ва маҳаллий йўналишлар бўйича кенг парвоз тармоғига эга.</p>
              <h3>Флоти</h3>
              <p>Saudia ўз флотида Airbus ва Boeing самолётларидан фойдаланади.</p>
              <table>
                <thead>
                  <tr>
                    <th>Самолёт тури</th>
                    <th>Миқдори (тахминан)</th>
                    <th>Хусусиятлари</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Airbus A320</td>
                    <td>~60</td>
                    <td>Қисқа ва ўрта масофаларда парвоз қилади</td>
                  </tr>
                  <tr>
                    <td>Boeing 777</td>
                    <td>~30</td>
                    <td>Халқаро рейслар учун</td>
                  </tr>
                  <tr>
                    <td>Boeing 787</td>
                    <td>~15</td>
                    <td>Иқтисодий ва бизнес-класс учун қулай</td>
                  </tr>
                </tbody>
              </table>
              <h3>Маршрутлар ва рейслар</h3>
              <p>Saudia Airlines Ўзбекистоннинг Тошкент шаҳридан Жидда ва Риёзга тўғридан-тўғри парвозларни амалга оширади.</p>
              <h3>Қисқа хулоса</h3>
              <p>Saudia Airlines — бу замонавий авиапарк ва хизмат кўрсатишнинг юқори стандартларига эга халқаро авиакомпания.</p>
              </article>`,
          },
        ],
        origin: [
          {
            content: `
              <h4>Давлат: </h4>
              <p>Саудия Арабистони</p> 
              <h4>Бош қароргоҳ: </h4> 
              <p>Жидда</p> 
              <h4>Асосий хаб: </h4>   
              <p>Қирол Абдулазиз халқаро аэропорти</p>
              <h4>Веб-сайт:</h4>
              <a href="https://www.saudia.com" target="_blank" rel="noreferrer">www.saudia.com</a>
            `,
          },
        ],
      },
    aeroflot: {
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Аэрофлот</h1>
            <h2>Дата основания и история</h2>
            <p>Аэрофлот (ПАО «Аэрофлот — Российские авиалинии») — одна из старейших авиакомпаний мира,
          основанная в 1923 году. За свою долгую историю прошла путь от монополиста в советский
          период до ведущего международного перевозчика, предлагающего авиабилеты (aviabilet) по
          множеству направлений, включая Узбекистан.</p>
            <h3>Флот Аэрофлота</h3>
            <p>В парке Аэрофлота преимущественно современные самолёты Airbus и Boeing. Линейка включает
            коротко- и среднемагистральные Airbus A320/321, а также дальнемагистральные Boeing 777 и 
            Airbus A330.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Количество (примерно)</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Коротко- и среднемагистральные маршруты</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Дальние рейсы, повышенная комфортность</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Межконтинентальные направления, просторные салоны</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Аэрофлот осуществляет прямые рейсы из следующих городов Узбекистана в Москву (Шереметьево):</p>
            <ul>
              <li>
                <b>Ташкент (TAS): </b>обычно ежедневно (7 раз в неделю).
              </li>
              <li>
                <b>Самарканд (SKD): </b>2–3 раза в неделю.
              </li>
              <li>
                <b>Бухара (BHK): </b>1–2 раза в неделю.
              </li>
            </ul>
            <p>Уточняйте авиабилеты и актуальные дни вылета на официальном сайте или 
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
          <h3>Короткий вывод</h3>
          <p>Аэрофлот — это сочетание многолетнего опыта, широкой маршрутной сети и современного авиапарка. 
          Если вам нужны авиабилеты (aviabilet) в Москву или другие города, обязательно сравните цены 
          и воспользуйтесь акциями на biletbor.me или на официальном сайте.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Россия</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Москва</p> 
            <h4>Основной хаб: </h4>   
            <p>Аэропорт Шереметьево (терминалы B/C), откуда выполняется большинство международных и региональных рейсов</p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
    airastana: {
      name: "airastana",
      pageKey: "airastanaInfo",
      imageUrl: "/images/AK/KC.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Astana</h1>
            <h2>Дата основания и история</h2>
            <p>Air Astana — национальная авиакомпания Республики Казахстан, основанная в 2001 году. С момента запуска она активно развивает маршрутную сеть, предлагая aviabilet дешевые и премиальные тарифы. Компания постоянно совершенствует сервис на борту и качество обслуживания.</p>
            <h3>Флот Air Astana</h3>
            <p>Air Astana эксплуатирует современные самолёты семейств Airbus (A320, A321, A321LR/neo), Boeing (757, 767) и Embraer (E190-E2). Такой флот обеспечивает гибкость в выборе маршрутов и комфорт для пассажиров, ищущих как aviabilet narxlari эконом-класса, так и более просторные места бизнес-класса.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320 / A321 / A321LR</td>
                  <td>Современные модели для ближне- и среднемагистральных рейсов</td>
                </tr>
                <tr>
                  <td>Boeing 757 / 767</td>
                  <td>Надёжные дальнемагистральные лайнеры</td>
                </tr>
                <tr>
                  <td>Embraer E190-E2</td>
                  <td>Удобные региональные самолёты для коротких перелётов</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Astana предлагает прямые и стыковочные рейсы из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> в Алматы или Астану (несколько раз в неделю)
              </li>
              <li>
                <b>Самарканд (SKD): </b>периодически сезонные рейсы (уточняйте на biletbor.me)
              </li>
              <li>
                <b>Бухара (BHK): </b>1–2 раза в неделю.
              </li>
            </ul>
            <p>Далее возможны пересадки на другие маршруты, включая aviabilet moskva, aviabilet sankt peterburg и другие направления. Актуальное расписание смотрите на  
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Короткий вывод</h3>
            <p>Air Astana — динамично развивающаяся авиакомпания Казахстана, предлагающая удобные рейсы между Узбекистаном и многими городами мира. Если вы ищете комфорт и доступные цены, стоит обратить внимание на их aviabilet narxlari и стыковочные возможности для дальнейших перелётов.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Казахстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Алматы</p> 
            <h4>Основной хаб: </h4>   
            <p>Международные аэропорты Алматы и Астаны
              Air Astana осуществляет рейсы в страны СНГ, Азии, Ближнего Востока и Европы, давая пассажирам возможность сравнивать aviabilet moskva и aviabilet sankt peterburg с другими направлениями
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airastana.com" target="_blank" rel="noreferrer">www.airastana.com</a>
          `,
        },
      ],
    },
    aircairo: {
      name: "aircairo",
      pageKey: "aircairoInfo",
      imageUrl: "/images/AK/SM.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Cairo</h1>
            <h2>Дата основания и история</h2>
            <p>Air Cairo — египетская авиакомпания, основанная в 2003 году. Первоначально компания развивалась как чартерный перевозчик, ориентированный на туристические направления, но впоследствии стала выполнять и регулярные рейсы. Air Cairo предлагает aviabilet дешевые направления, включая вылеты из Узбекистана.</p>
            <h3>Флот Air Cairo</h3>
            <p>Air Cairo использует современные самолёты семейства Airbus A320/321. Флот ориентирован на средние дистанции и обеспечивает комфорт в салонах экономического класса и отдельных бизнес-направлениях. При выборе aviabilet narxlari можно найти как базовые, так и улучшенные тарифы.</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Среднемагистральные рейсы, базовый эконом и отдельные бизнес-тарифы</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>Увеличенная пассажировместимость для популярных направлений</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Cairo периодически предлагает рейсы из Узбекистана в Египет (Каир, Шарм-эш-Шейх и т.д.) в зависимости от туристического сезона и спроса.</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> может выполняться в туристический сезон (уточняйте на <a href="https://biletbor.me">biletbor.me)</a>.
              </li>
              <li>
                <b>Другие города: </b>возможны чартеры в пиковые даты.
              </li>
            </ul>
            <p>Далее возможны пересадки на другие маршруты, включая aviabilet moskva, aviabilet sankt peterburg и другие направления. Актуальное расписание смотрите на  
          <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Короткий вывод</h3>
            <p>Air Cairo — удобный выбор для путешествий между Узбекистаном и Египтом, особенно в туристический сезон. Авиакомпания сочетает доступные aviabilet narxlari с регулярными спецпредложениями, что может быть интересно как любителям пляжного отдыха, так и тем, кто ищет новые маршруты.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Египет</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Каир</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Каир (CAI)
               Авиакомпания известна рейсами в Ближний Восток, Европу и некоторые города в Азии, что даёт возможность сравнить aviabilet moskva и aviabilet sankt peterburg при планировании стыковок.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airchina: {
      name: "airchina",
      pageKey: "airchinaInfo",
      imageUrl: "/images/AK/CA.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air China</h1>
            <h2>Дата основания и история</h2>
            <p>Air China — национальный авиаперевозчик Китая, ведущий свою историю с 1988 года, когда из нескольких региональных компаний был сформирован единый бренд. Сегодня Air China считается одной из крупнейших авиакомпаний в Азии, предлагая aviabilet дешевые и премиум-класса на различные направления по всему миру.</p>
            <h3>Флот Air China</h3>
            <p>Air China эксплуатирует широкий спектр самолётов, включая Boeing 737, 777, 787, а также Airbus A320, A330, A350. Разнообразие парка позволяет обеспечивать дальнемагистральные и региональные рейсы, сохраняя высокие стандарты безопасности и сервиса. При покупке aviabilet narxlari можно выбрать различные классы обслуживания (эконом, бизнес, первый класс).</p>
            <table>
              <thead>
                <tr>
                  <th>Тип самолёта</th>
                  <th>Особенности</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Среднемагистральные рейсы, базовый эконом и отдельные бизнес-тарифы</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>Увеличенная пассажировместимость для популярных направлений</td>
                </tr>
              </tbody>
            </table>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air China периодически предлагает прямые и стыковочные рейсы из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> как правило, с пересадкой в Пекине или другом хабе (расписание уточняйте на <a href="https://biletbor.me">biletbor.me</a>).
              </li>
              <li>
                <b>Другие города Узбекистана: </b>могут быть сезонные или чартерные программы по мере роста спроса.
              </li>
            </ul>
            <h3>Короткий вывод</h3>
            <p>Air China — динамично развивающаяся авиакомпания с широкой маршрутной сетью и комфортным сервисом. Она отлично подойдёт тем, кто планирует путешествия из Узбекистана в Китай или другие страны. Сравнивайте aviabilet narxlari и выбирайте оптимальный вариант на <a href="https://biletbor.me">biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Китай</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Пекин</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Пекин Столичный (PEK)
               Также авиакомпания использует аэропорты Чэнду, Шанхая и других крупных городов в качестве дополнительных хабов. Пассажиры могут подобрать aviabilet moskva, aviabilet sankt peterburg или другие маршруты с удобными стыковками.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airmanas: {
      name: "airmanas",
      pageKey: "airmanasInfo",
      imageUrl: "/images/AK/ZM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Manas</h1>
            <h2>Дата основания и история</h2>
            <p>Air Manas — киргизская авиакомпания, начавшая свою деятельность в 2006 году. С момента основания компания развивала сеть внутриреспубликанских и международных рейсов, стремясь предлагать aviabilet дешевые и гибкие условия перелётов.</p>
            <h3>Флот Air China</h3>
            <p>Флот Air Manas обычно включает несколько самолётов Boeing 737 различных модификаций. Эти воздушные суда хорошо подходят для кратко- и среднемагистральных маршрутов, обеспечивая пассажирам доступные aviabilet narxlari и комфортное путешествие.</p>
            <h3>Расписание рейсов из Узбекистана</h3>
            <p>Air Manas периодически открывает и обновляет рейсы из Узбекистана, особенно из:</p>
            <ul>
              <li>
                <b>Ташкента (TAS):</b> до Бишкека и в обратном направлении (график зависит от сезона и спроса)..
              </li>
              <li>
                <b>Другие города: </b>могут предлагаться сезонно или в формате чартерных программ.
              </li>
            </ul>
            <h3>Короткий вывод</h3>
            <p>Air Manas — динамично развивающаяся киргизская авиакомпания, которая может стать удобным вариантом для перелётов между Узбекистаном и Кыргызстаном, а также для стыковок в другие города. Сравнивайте aviabilet narxlari на <a href="https://biletbor.me">biletbor.me</a> и выбирайте наиболее подходящий рейс.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Кыргызстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Бишкек</p> 
            <h4>Основной хаб: </h4>   
            <p>Международный аэропорт Манас (FRU)
               Air Manas выполняет рейсы в страны СНГ и ближайшие регионы, что даёт возможность сравнивать aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airmanas.com" target="_blank" rel="noreferrer">www.airmanas.com</a>
          `,
        },
      ],
    },
    asianaAirlines: {
      name: "asianaAirlines",
      pageKey: "asianaAirlinesInfo",
      imageUrl: "/images/AK/OZ.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Asiana Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Asiana Airlines — крупная авиакомпания Республики Корея, основанная в 1988 году. За время своей работы компания получила широкое признание за высокий уровень сервиса и безопасности, предлагая aviabilet дешевые и премиальные классы на разнообразные направления, включая рейсы в Узбекистан.</p>
        <h3>Флот Asiana Airlines</h3>
        <p>В парк Asiana Airlines входят современные самолёты Airbus (A320, A350) и Boeing (B747, B777). Подобное разнообразие позволяет обслуживать как короткие региональные маршруты, так и дальнемагистральные рейсы. При выборе aviabilet narxlari можно найти билеты в эконом-, бизнес- или первом классе.</p>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Asiana Airlines периодически предлагает прямые или стыковочные рейсы из:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> с пересадкой в Сеуле (в разное время года, в зависимости от сезона).
          </li>
          <li>
            <b>Другие города:</b> возможны чартерные или сезонные рейсы при повышенном туристическом спросе.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Asiana Airlines — подходящий выбор для тех, кто ценит комфорт и безопасность. Если вы планируете полёт из Узбекистана в Сеул или далее, сравните aviabilet narxlari и выбирайте удобный класс обслуживания. Подробности и акции ищите на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Республика Корея</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Сеул</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Инчхон (ICN). Asiana Airlines также имеет важные маршруты из аэропорта Гимпо и других корейских городов, что позволяет пассажирам выбрать удобные aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.flyasiana.com" target="_blank" rel="noreferrer">www.flyasiana.com</a>
      `,
        },
      ],
    },
    aviaTraffic: {
      name: "aviaTraffic",
      pageKey: "aviaTrafficInfo",
      imageUrl: "/images/AK/AT.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Avia Traffic</h1>
        <h2>Дата основания и история</h2>
        <p>Avia Traffic Company — киргизская авиакомпания, ведущая свою деятельность с 2003 года. Она постепенно расширяла маршрутную сеть, предлагая aviabilet дешевые и различные сервисные опции, ориентируясь на удобство и безопасность пассажиров.</p>
        <h3>Флот Avia Traffic</h3>
        <p>В распоряжении Avia Traffic Company обычно несколько самолётов Boeing 737 и Airbus A320:</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Boeing 737</td>
              <td>Идеален для коротко- и среднемагистральных маршрутов</td>
            </tr>
            <tr>
              <td>Airbus A320</td>
              <td>Удобный салон, варианты эконом и бизнес-класса</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Avia Traffic Company предлагает рейсы из:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> В Бишкек (и обратно), частота зависит от сезона.
          </li>
          <li>
            <b>Другие города Узбекистана:</b> Возможны чартеры или дополнительные рейсы в пиковые периоды.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Avia Traffic Company — удобный вариант для перелётов между Кыргызстаном и Узбекистаном. Если вы ищете доступные aviabilet narxlari и приемлемые условия полёта, обязательно обратите внимание на рейсы этой авиакомпании, проверив доступные варианты на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Республика Корея</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Сеул</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Инчхон (ICN). Asiana Airlines также имеет важные маршруты из аэропорта Гимпо и других корейских городов, что позволяет пассажирам выбрать удобные aviabilet moskva или aviabilet sankt peterburg при планировании дальних перелётов.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.aero.kg/" target="_blank" rel="noreferrer">www.aviatraffic.com</a>
      `,
        },
      ],
    },
    azal: {
      name: "azal",
      pageKey: "azalInfo",
      imageUrl: "/images/AK/J2.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azal</h1>
        <h2>Дата основания и история</h2>
        <p>AZAL (Azerbaijan Airlines) — национальный авиаперевозчик Азербайджана, основанный в 1992 году на базе государственной авиационной структуры. С тех пор авиакомпания активно развивается, предлагая пассажирам aviabilet дешевые и премиальные тарифы на разных направлениях.</p>
        <h3>Флот Azal</h3>
        <p>Во флоте AZAL в основном самолёты семейства Airbus (A319, A320, A321) и Boeing (757, 767, 787). Такой состав позволяет обслуживать как ближнемагистральные, так и дальние направления, обеспечивая пассажирам комфорт и разнообразие aviabilet narxlari.</p>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>AZAL периодически выполняет прямые и стыковочные рейсы:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Баку (частота и дни вылета зависят от сезона).
          </li>
          <li>
            <b>Другие города Узбекистана:</b> могут быть чартерные или сезонные рейсы по мере увеличения спроса.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>AZAL (Azerbaijan Airlines) — удобный выбор для перелётов между Узбекистаном и Азербайджаном, а также для дальних рейсов с пересадкой в Баку. Сравните aviabilet narxlari и найдите подходящие варианты на <a href="https://biletbor.me">biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Азербайджан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Азербайджан</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Гейдар Алиев (GYD)
           AZAL выполняет рейсы во многие города Европы, Азии и СНГ, что позволяет сравнивать aviabilet moskva или aviabilet sankt peterburg, а также планировать стыковки при полётах из Узбекистана.</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
      `,
        },
      ],
    },
    azimuth: {
      name: "azimuth",
      pageKey: "azimuthInfo",
      imageUrl: "/images/AK/A4.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azimuth</h1>
        <h2>Дата основания и история</h2>
        <p>Azimuth — российская авиакомпания, основанная в 1993 году. С момента своего основания компания специализируется на региональных перевозках, предоставляя пассажирам aviabilet дешевые и удобные условия для путешествий по России и соседним странам.</p>
        <h3>Флот Azal</h3>
        <p>Флот Azimuth состоит из современных самолётов Embraer ERJ-145 и ATR 72. Эти воздушные суда идеально подходят для коротко- и среднемагистральных маршрутов, обеспечивая пассажирам комфорт и безопасность. При выборе aviabilet narxlari можно найти как эконом, так и бизнес-классы.</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Embraer ERJ-145</td>
              <td>Идеален для региональных и средне-магистральных маршрутов</td>
            </tr>
            <tr>
              <td>ATR 72</td>
              <td>Экономичные самолёты для коротких рейсов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Azimuth предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Москву с пересадкой в Домодедово (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Москву и другие направления.
          </li>
        </ul>
        <h3>Короткий вывод</h3>
        <p>Azimuth — надёжная российская авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Россию или другие страны СНГ, сравните предложения Azimuth на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Аэропорт Домодедово (DME)
           Azimuth выполняет рейсы в различные регионы России, а также в страны СНГ. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие направления с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://azimuth.ru" target="_blank" rel="noreferrer">www.azimuth.ru</a>
      `,
        },
      ],
    },
    azurair: {
      name: "azurair",
      pageKey: "azurairInfo",
      imageUrl: "/images/AK/ZF.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Azur Air</h1>
        <h2>Дата основания и история</h2>
        <p>Azur Air — российская авиакомпания, основанная в 2006 году. С момента своего основания компания специализировалась на чартерных рейсах, обслуживая туристические направления. В последние годы Azur Air расширила свой маршрутный график, предлагая регулярные рейсы и новые направления, что позволяет пассажирам воспользоваться aviabilet дешевые и удобными условиями полёта.</p>
        <h3>Флот Azal</h3>
        <p>Флот Azur Air включает современные самолёты Airbus A320 и Boeing 737. Эти воздушные суда обеспечивают комфорт и безопасность пассажиров на коротко- и среднемагистральных маршрутах. Разнообразие флота позволяет Azur Air предлагать различные aviabilet narxlari в зависимости от класса обслуживания (эконом, бизнес).</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Airbus A320</td>
              <td>Комфортные условия для эконом и бизнес-класса</td>
            </tr>
            <tr>
              <td>Boeing 737</td>
              <td>Надёжность и безопасность для коротко- и среднемагистральных маршрутов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Azur Air предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Москву с пересадкой в Домодедово (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Москву и другие направления.
          </li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Azur Air — надёжная российская авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Россию или другие страны СНГ, сравните предложения Azur Air на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Аэропорт Домодедово (DME)
           Azur Air выполняет рейсы из Москвы в различные туристические направления по всему миру, а также осуществляет перелёты из других городов России. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие маршруты с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://azur-airlines.com/" target="_blank" rel="noreferrer">www.azur-airlines.com</a>
      `,
        },
      ],
    },
    belavia: {
      name: "belavia",
      pageKey: "belaviaInfo",
      imageUrl: "/images/AK/B2.png",
      generalInfo: [
        {
          content: `<article>
        <h1>Белавиа</h1>
        <h2>Дата основания и история</h2>
        <p>Belavia — национальная авиакомпания Республики Беларусь, основанная в 1996 году на основе советского авиаперевозчика Aeroflot. С момента своего основания Belavia активно развивается, расширяя маршрутную сеть и внедряя современные технологии для улучшения качества обслуживания пассажиров. Компания стремится предоставлять aviabilet дешевые и удобные условия для путешествий как внутри Беларуси, так и за её пределами.</p>
        <h3>Флот Azal</h3>
        <p>Флот Belavia включает современные самолёты Airbus (A320, A330) и Boeing (737, 767). Эти воздушные суда обеспечивают высокий уровень комфорта и безопасности на коротко- и дальнемагистральных маршрутах. Разнообразие флота позволяет Belavia предлагать различные aviabilet narxlari в зависимости от класса обслуживания (эконом, бизнес).</p>
        <table>
          <thead>
            <tr>
              <th>Самолёт</th>
              <th>Особенности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Airbus A320</td>
              <td>Комфортные условия для эконом и бизнес-класса</td>
            </tr>
            <tr>
              <td>Boeing 737</td>
              <td>Надёжность и безопасность для коротко- и среднемагистральных маршрутов</td>
            </tr>
          </tbody>
        </table>
        <h3>Расписание рейсов из Узбекистана</h3>
        <p>Belavia предлагает рейсы из следующих городов Узбекистана:</p>
        <ul>
          <li>
            <b>Ташкента (TAS):</b> рейсы в Минск с пересадкой или прямые рейсы (частота зависит от сезона).
          </li>
          <li>
            <b>Самарканд (SKD):</b> сезонные рейсы в Минск и другие направления.
          </li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Belavia — надёжная авиакомпания, предоставляющая доступные aviabilet narxlari и комфортные условия для пассажиров. Если вы планируете путешествие из Узбекистана в Беларусь или другие страны СНГ, сравните предложения Belavia на <a href="https://biletbor.me">biletbor.me</a> и выберите наиболее подходящий рейс.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Беларусь</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Минск</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Минск (MSQ)
           Belavia выполняет рейсы из Минска в множество городов Европы, Азии и Ближнего Востока, а также осуществляет чартерные рейсы в туристические направления. Это позволяет пассажирам выбирать aviabilet moskva, aviabilet sankt peterburg и другие маршруты с удобными пересадками.</p>
        <h4>Вебсайт:</h4>
        <a href="https://belavia.by/" target="_blank" rel="noreferrer">www.belavia.by</a>
      `,
        },
      ],
    },
    uzairways: {
      name: "uzairways",
      pageKey: "uzairwaysInfo",
      imageUrl: "/images/AK/HY.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Uzbekistan Airways</h1>
        <h2>Дата основания и история</h2>
        <p>Uzbekistan Airways – национальная авиакомпания Узбекистана, основанная в 1992 году. За годы работы компания расширила свою маршрутную сеть, предлагая авиаперелеты в Европу, Азию и Северную Америку.</p>
        <h2>Флот Uzbekistan Airways</h2>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 787 Dreamliner</td>
                    <td>7</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>10</td>
                    <td>Среднемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Boeing 767-300</td>
                    <td>6</td>
                    <td>Международные рейсы</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Санкт-Петербург:</strong> 3 раза в неделю</li>
            <li><strong>Самарканд – Дубай:</strong> 2 раза в неделю</li>
            <li><strong>Бухара – Стамбул:</strong> 2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Uzbekistan Airways предлагает комфортные перелеты по многим направлениям. Узнать стоимость билетов и забронировать можно на <a href="https://biletbor.me">Biletbor.me</a>.</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Узбекистан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Ташкент</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Ташкент (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uzairways.com" target="_blank" rel="noreferrer">www.uzairways.com</a>
      `,
        },
      ],
    },
    s7: {
      name: "s7",
      pageKey: "s7Info",
      imageUrl: "/images/AK/S7.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>S7 Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>S7 Airlines – крупнейшая частная авиакомпания России, основанная в 1992 году. Сегодня это один из ведущих авиаперевозчиков страны с развитой маршрутной сетью.</p>
        <h2>Флот S7 Airlines</h2>
        <p>S7 Airlines эксплуатирует современные воздушные суда, в том числе:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>20</td>
                    <td>Средние и дальние маршруты</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>23</td>
                    <td>Внутренние и международные рейсы</td>
                </tr>
                <tr>
                    <td>Embraer E170</td>
                    <td>17</td>
                    <td>Региональные маршруты</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Новосибирск:</strong> 2 раза в неделю</li>
            <li><strong>Самарканд – Москва:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>S7 Airlines предлагает удобные рейсы по России и международным направлениям. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Москва (Домодедово, DME), Новосибирск (Толмачёво, OVB)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.s7.ru" target="_blank" rel="noreferrer">www.s7.ru</a>
      `,
        },
      ],
    },
    turkish: {
      name: "turkish",
      pageKey: "turkishInfo",
      imageUrl: "/images/AK/TK.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Turkish Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Turkish Airlines была основана в 1933 году и является национальным авиаперевозчиком Турции. Сегодня это одна из крупнейших авиакомпаний в мире.</p>
        <h2>Флот S7 Airlines</h2>
        <p>Turkish Airlines эксплуатирует один из крупнейших и самых современных парков самолетов, включая:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 777-300ER</td>
                    <td>33</td>
                    <td>Дальнемагистральные рейсы</td>
                </tr>
                <tr>
                    <td>Airbus A321neo</td>
                    <td>45</td>
                    <td>Средние и короткие рейсы</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>36</td>
                    <td>Региональные направления</td>
                </tr>
            </tbody>
        </table>
        
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Turkish Airlines выполняет регулярные рейсы из Ташкента и других городов Узбекистана:</p>
        <ul>
            <li><strong>Ташкент – Стамбул:</strong> ежедневно</li>
            <li><strong>Самарканд – Стамбул:</strong> 2 раза в неделю</li>
            <li><strong>Бухара – Стамбул:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Turkish Airlines предлагает удобные рейсы по всему миру. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Турция</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Стамбул</p> 
        <h4>Основной хаб:</h4>   
        <p>Новый аэропорт Стамбула (IST)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.turkishairlines.com/" target="_blank" rel="noreferrer">www.turkishairlines.com</a>
      `,
        },
      ],
    },
    qanotsharq: {
      name: "qanotsharq",
      pageKey: "qanotsharqInfo",
      imageUrl: "/images/AK/HH.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Qanot Sharq</h1>
        <h2>Дата основания и история</h2>
        <p>Авиакомпания Qanot Sharq была основана в 1998 году и специализируется на пассажирских и грузовых перевозках. За годы работы компания зарекомендовала себя как надёжный перевозчик, обеспечивающий качественный сервис на международных и внутренних направлениях.</p>
        <h2>Флот Qanot Sharq</h2>
        <p>На сегодняшний день авиакомпания эксплуатирует следующие типы воздушных судов:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320</td>
                    <td>2</td>
                    <td>Международные и внутренние рейсы</td>
                </tr>
                <tr>
                    <td>Boeing 757-200</td>
                    <td>1</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
            </tbody>
        </table>        
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Qanot Sharq выполняет регулярные и чартерные рейсы. Основные направления:</p>
        <ul>
            <li><strong>Ташкент – Москва:</strong> ежедневно</li>
            <li><strong>Ташкент – Санкт-Петербург:</strong> 4 раза в неделю</li>
            <li><strong>Ташкент – Дубай:</strong> 3 раза в неделю</li>
            <li><strong>Ташкент – Стамбул:</strong> 2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Qanot Sharq – это удобные и доступные перелёты по популярным маршрутам. Забронируйте билеты онлайн на <a href="https://biletbor.me">biletbor.me</a> и путешествуйте с комфортом!</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Узбекистан</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Ташкент</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Ташкент (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://qanotsharq.com/" target="_blank" rel="noreferrer">www.qanotsharq.com</a>
      `,
        },
      ],
    },
    ural: {
      name: "ural",
      pageKey: "uralInfo",
      imageUrl: "/images/AK/U6.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Ural Airlines</h1>
        <h2>Дата основания и история</h2>
        <p>Ural Airlines была основана в 1943 году как подразделение «Аэрофлота». В 1993 году компания стала независимой авиакомпанией и с тех пор активно расширяет свою маршрутную сеть, предлагая авиабилеты (aviabilet) на международные и внутренние рейсы.</p>
        <h2>Флот Ural Airlines</h2>
        <p>Ural Airlines располагает современным парком воздушных судов:</p>
        <table>
            <thead>
                <tr>
                    <th>Тип самолёта</th>
                    <th>Количество</th>
                    <th>Основные маршруты</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320</td>
                    <td>20</td>
                    <td>Среднемагистральные рейсы</td>
                </tr>
                <tr>
                    <td>Airbus A321</td>
                    <td>15</td>
                    <td>Дальнемагистральные маршруты</td>
                </tr>
                <tr>
                    <td>Airbus A319</td>
                    <td>10</td>
                    <td>Короткие и региональные рейсы</td>
                </tr>
            </tbody>
        </table>
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Ural Airlines выполняет регулярные рейсы из Ташкента и других городов:</p>
        <ul>
            <li><strong>Ташкент – Москва:</strong> 3 раза в неделю</li>
            <li><strong>Самарканд – Екатеринбург:</strong> 2 раза в неделю</li>
            <li><strong>Фергана – Москва:</strong> 1-2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Ural Airlines предлагает комфортные перелеты по России и за ее пределами. Узнать расписание и забронировать билеты можно на <a href="https://biletbor.me">Biletbor.me.</a></p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Екатеринбург</p> 
        <h4>Основной хаб:</h4>   
        <p>Международный аэропорт Кольцово (SVX)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uralairlines.ru/" target="_blank" rel="noreferrer">www.uralairlines.ru</a>
      `,
        },
      ],
    },
    pobeda: {
      name: "pobeda",
      pageKey: "pobedaInfo",
      imageUrl: "/images/AK/DP.svg",
      generalInfo: [
        {
          content: `<article>
        <h1>Победа</h1>
        <h2>Дата основания и история</h2>
        <p>Авиакомпания <strong>Pobeda</strong> (Победа) – российский лоукостер, основанный в 2014 году. Она входит в группу "Аэрофлот" и предлагает доступные авиабилеты по России и за её пределами.</p>
        <h2>Флот Победы</h2>
        <p>Pobeda использует исключительно современные самолёты Boeing 737-800:</p>
        <table>
          <thead>
              <tr>
                  <th>Тип самолёта</th>
                  <th>Количество</th>
                  <th>Основные маршруты</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Boeing 737-800</td>
                  <td>45</td>
                  <td>Россия, СНГ, Европа</td>
              </tr>
          </tbody>
        </table>
        <h2>Расписание рейсов из Узбекистана</h2>
        <p>Ural Airlines выполняет регулярные рейсы из Ташкента и других городов:</p>
        <ul>
            <li><strong>Ташкент – Москва (VKO):</strong> ежедневно</li>
            <li><strong>Самарканд – Москва (VKO):</strong> 3 раза в неделю</li>
            <li><strong>Фергана – Москва (VKO):</strong> 2 раза в неделю</li>
        </ul>
        <p>Для актуального расписания и aviabilet narxlari рекомендуем посетить <a href="https://biletbor.me">Biletbor.me</a> или официальный сайт авиакомпании.</p>
        <h3>Короткий вывод</h3>
        <p>Pobeda – это удобные и доступные авиаперелёты из Узбекистана в Москву и другие города. Забронируйте билеты онлайн на <a href="https://biletbor.me">Biletbor.me</a> и летайте выгодно!</p>
      </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Страна:</h4>
        <p>Россия</p> 
        <h4>Штаб-квартира:</h4> 
        <p>Москва</p> 
        <h4>Основной хаб:</h4>   
        <p>Московский аэропорт Внуково (VKO)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.flypobeda.ru/" target="_blank" rel="noreferrer">www.flypobeda.ru</a>
      `,
        },
      ],
    },
    spicejet: {
      pageKey: "spicejetInfo",
      imageUrl: "/images/AK/SG.png",
      generalInfo: [
        {
          content: `<article>
          <h1>SpiceJet: Доступные авиаперелеты в Индию и за её пределы</h1>
          <p><a href="https://biletbor.me/airlines/spicejet" target="_blank" rel="noopener noreferrer">https://biletbor.me/airlines/spicejet</a></p>

          <h2>Дата основания и история</h2>
          <p>SpiceJet – одна из крупнейших бюджетных авиакомпаний Индии, основанная в 2005 году. Авиакомпания предлагает доступные тарифы на внутренние и международные рейсы, ориентируясь на широкий круг пассажиров.</p>
          <h2>Флот SpiceJet</h2>
          <table>
            <thead>
              <tr>
                <th>Самолет</th>
                <th>Количество</th>
                <th>Основное назначение</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boeing 737</td>
                <td>~50</td>
                <td>Международные и внутренние рейсы</td>
              </tr>
              <tr>
                <td>Bombardier Q400</td>
                <td>~30</td>
                <td>Короткие региональные маршруты</td>
              </tr>
            </tbody>
          </table>

          <h2>Расписание рейсов из Узбекистана</h2>
          <ul>
            <li><b>Ташкент – Дели (DEL):</b> 3 раза в неделю</li>
          </ul>
          <p>Подробное расписание и цены можно посмотреть на <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a>.</p>

          <h2>Вывод</h2>
          <p>SpiceJet – это удобный и экономичный вариант для путешествий по Индии и за её пределы. Забронируйте билет на <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a> прямо сейчас.</p>
        </article>`,
        },
      ],
      origin: [
        {
          content: `
          <h4>Страна:</h4>
          <p>Индия</p>
          <h4>Штаб-квартира:</h4>
          <p>Гургаон, штат Харьяна</p>
          <h4>Главные хабы:</h4>
          <p>Международный аэропорт Дели (DEL), Международный аэропорт Хайдерабада (HYD)</p>
          <h4>Веб-сайт:</h4>
          <a href="https://www.spicejet.com" target="_blank" rel="noopener noreferrer">www.spicejet.com</a>
        `,
        },
      ],
    },
  },

  uz: {
    yakutiaAirlines: {
      pageKey: "yakutiaAirlinesInfo",
      imageUrl: "/images/AK/YA.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Yakutia Airlines</h1>
                  <h2>Tashkil etilgan sana va tarixi</h2>
                  <p>Yakutia Airlines — Rossiya aviakompaniyasi bo‘lib, 2002-yilda tashkil etilgan. Kompaniya Yakutsk shahrida joylashgan va Rossiya ichki reyslarini amalga oshiradi, jumladan, Sibir va Uzoq Sharqning olis hududlariga. Yakutia Airlines, shuningdek, Osiyo davlatlariga xalqaro reyslarni ham amalga oshiradi.</p>
                  <h3>Yakutia Airlines floti</h3>
                  <p>Aviakompaniya quyidagi zamonaviy samolyotlarni ekspluatatsiya qiladi:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Samolyot turi</th>
                              <th>Taxminiy soni</th>
                              <th>Xususiyatlari</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>~5</td>
                              <td>O‘rta masofali reyslar</td>
                          </tr>
                          <tr>
                              <td>Yak-42</td>
                              <td>~6</td>
                              <td>Mahalliy yo‘nalishlar, murakkab iqlim sharoitlariga mos</td>
                          </tr>
                          <tr>
                              <td>Bombardier CRJ-200</td>
                              <td>~3</td>
                              <td>Qisqa va mintaqaviy reyslar</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Yo‘nalish tarmog‘i</h3>
                  <p>Yakutia Airlines quyidagi yo‘nalishlar bo‘ylab parvozlarni amalga oshiradi:</p>
                  <ul>
                      <li><b>Yakutsk – Moskva (SVO):</b> haftasiga bir necha marta.</li>
                      <li><b>Yakutsk – Vladivostok (VVO):</b> haftasiga bir necha marta.</li>
                      <li><b>Yakutsk – Bangkok (BKK):</b> mavsumiy xalqaro reyslar.</li>
                  </ul>
                  <p>Eng so‘nggi jadval va chiptalarni kompaniyaning rasmiy veb-saytida topishingiz mumkin.</p>
                  <h3>Xulosa</h3>
                  <p>Yakutia Airlines — Sibir va Uzoq Sharq yo‘nalishlari bo‘ylab noyob imkoniyatlar taqdim etuvchi ishonchli aviakompaniya.</p>
              </article> `,
          },
      ],
      origin: [
          {
              content: `
                      <h4>Mamlakat:</h4>
                      <p>Rossiya</p>
                      <h4>Bosh ofis:</h4>
                      <p>Yakutsk</p>
                      <h4>Asosiy xab:</h4>
                      <p>Yakutsk xalqaro aeroporti (YKS)</p>
                      <h4>Veb-sayt:</h4>
                      <a href="https://yakutia.aero" target="_blank" rel="noreferrer">yakutia.aero</a>
              `,
          },
      ],
  },
  rossiyaAirlines: {
      pageKey: "rossiyaAirlinesInfo",
      imageUrl: "/images/AK/RO.svg",
      generalInfo: [
          {
              content:` <article>
                  <h1>Rossiya Airlines</h1>
                  <h2>Tashkil etilgan sana va tarixi</h2>
                  <p>Rossiya Airlines — 1934-yilda tashkil etilgan Rossiya aviakompaniyasi. Bu kompaniya Rossiyaning eng yirik aviakompaniyalaridan biri bo‘lib, "Aeroflot" guruhining sho‘ba korxonasi hisoblanadi. Aviakompaniya ichki va xalqaro yo‘nalishlar bo‘ylab parvozlarni amalga oshiradi.</p>
                  <h3>Rossiya Airlines floti</h3>
                  <p>Kompaniya quyidagi samolyotlarni ekspluatatsiya qiladi:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Samolyot turi</th>
                              <th>Taxminiy soni</th>
                              <th>Xususiyatlari</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320/321</td>
                              <td>~40</td>
                              <td>O‘rta masofali reyslar</td>
                          </tr>
                          <tr>
                              <td>Boeing 737</td>
                              <td>~30</td>
                              <td>Qisqa va mintaqaviy yo‘nalishlar</td>
                          </tr>
                          <tr>
                              <td>Airbus A330</td>
                              <td>~10</td>
                              <td>Uzoq masofali reyslar</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Yo‘nalish tarmog‘i</h3>
                  <p>Rossiya Airlines Rossiyadan O‘zbekistonga quyidagi yo‘nalishlar bo‘ylab parvozlarni amalga oshiradi:</p>
                  <ul>
                      <li><b>Moskva – Toshkent (TAS):</b> haftasiga bir necha marta.</li>
                      <li><b>Moskva – Samarqand (SKD):</b> haftasiga bir necha marta.</li>
                  </ul>
                  <p>Eng so‘nggi jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan topishingiz mumkin.</p>
                  <h3>Xulosa</h3>
                  <p>Rossiya Airlines — sifatli xizmat va xavfsiz parvozlar taqdim etuvchi ishonchli aviakompaniya. Eng yaxshi narxlarda chipta bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
              </article> `,
          },
      ],
      origin: [
          {
              content: `
                      <h4>Mamlakat:</h4>
                      <p>Rossiya</p>
                      <h4>Bosh ofis:</h4>
                      <p>Moskva</p>
                      <h4>Asosiy xab:</h4>
                      <p>Sheremetyevo (SVO), Pulkovo (LED)</p>
                      <h4>Veb-sayt:</h4>
                      <a href="https://www.rossiya-airlines.com" target="_blank" rel="noreferrer">www.rossiya-airlines.com</a>
             `,
          },
      ],
  },
    yamalAirlines: {
      pageKey: "yamalAirlinesInfo",
      imageUrl: "/images/AK/YM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Yamal Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Yamal Airlines — Rossiyaning aviakompaniyasi bo‘lib, 1997-yilda tashkil etilgan. Kompaniya Salyekhard shahrida joylashgan bo‘lib, Rossiyaning shimoliy hududlariga, jumladan, chekka va qiyin yetib boriladigan hududlarga reyslarni amalga oshiradi.</p>
            <h3>Yamal Airlines parki</h3>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SSJ 100</td>
                  <td>~6</td>
                  <td>Mahalliy va o‘rta masofali reyslar uchun</td>
                </tr>
                <tr>
                  <td>ТУ-204</td>
                  <td>~4</td>
                  <td>O‘rta va uzoq masofali reyslar</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>~5</td>
                  <td>Mahalliy va mintaqaviy reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalish tarmog‘i</h3>
            <p>Yamal Airlines Rossiya va xalqaro yo‘nalishlar bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
            <ul>
              <li><b>Moskva – Salyekhard:</b> haftasiga bir necha marta muntazam reyslar.</li>
              <li><b>Tumyen – Novy Urengoy:</b> muntazam reyslar.</li>
              <li><b>Salyekhard – Xanti-Mansiysk:</b> muntazam reyslar.</li>
            </ul>
            <h3>Xulosa</h3>
            <p>Yamal Airlines Rossiyaning shimoliy hududlarida ishonchli aviaparvozlarni taqdim etuvchi kompaniyalardan biridir.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Rossiya</p>
            <h4>Bosh ofis:</h4>
            <p>Salyekhard</p>
            <h4>Asosiy havo bandargohi:</h4>
            <p>Salyekhard xalqaro aeroporti (SLY)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.yamal.aero" target="_blank" rel="noreferrer">yamal.aero</a>
          `,
        },
      ],
    },
    auroraAirlines: {
      pageKey: "auroraAirlinesInfo",
      imageUrl: "/images/AK/AU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aurora Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Aurora Airlines — Rossiyaning aviakompaniyasi bo‘lib, 2013-yilda "Aeroflot-Nord" va "Vladivostok Avia" kompaniyalari birlashishi natijasida tashkil etilgan. Bosh ofisi Vladivostok shahrida joylashgan. Asosiy maqsadi – yo‘lovchi tashish xizmatlarini ko‘rsatish, jumladan, xalqaro va mahalliy reyslar bilan Rossiyaning Uzoq Sharq mintaqasiga xizmat qilish.</p>
            <h3>Aurora Airlines parki</h3>
            <p>Kompaniya zamonaviy samolyotlar parkiga ega:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bombardier Q400</td>
                  <td>~8</td>
                  <td>Mahalliy reyslar uchun, yoqilg‘i tejamkorligi, 78 yo‘lovchiga mo‘ljallangan</td>
                </tr>
                <tr>
                  <td>SSJ 100</td>
                  <td>~10</td>
                  <td>O‘rta masofali reyslar, 100 yo‘lovchiga mo‘ljallangan</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalish tarmog‘i</h3>
            <p>Aurora Airlines Rossiya va xalqaro yo‘nalishlar bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
            <ul>
              <li><b>Vladivostok – Moskva:</b> haftasiga bir necha marta muntazam reyslar.</li>
              <li><b>Vladivostok – Xabarovsk:</b> kuniga bir necha marta parvozlar.</li>
              <li><b>Vladivostok – Seul:</b> haftasiga bir necha marta xalqaro reyslar.</li>
            </ul>
            <p>Joriy jadval va chiptalarni kompaniyaning rasmiy veb-saytidan bilib olish mumkin.</p>
            <h3>Xulosa</h3>
            <p>Aurora Airlines – Rossiyaning Uzoq Sharq mintaqasida va xorijda qulay va ishonchli aviaqatnovlarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Rossiya</p>
            <h4>Bosh ofis:</h4>
            <p>Vladivostok</p>
            <h4>Asosiy havo bandargohi:</h4>
            <p>Vladivostok xalqaro aeroporti (VVO)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.flyaurora.ru" target="_blank" rel="noreferrer">flyaurora.ru</a>
          `,
        },
      ],
    },
    flyaristan: {
      pageKey: "flyaristanInfo",
      imageUrl: "/images/AK/FS.png",
      generalInfo: [
        {
          content: `<article>
            <h1>FlyAirstan</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>FlyAirstan — 2020-yilda tashkil etilgan Oʻzbekiston aviakompaniyasi boʻlib, kompaniya Almatida joylashgan va Oʻzbekiston ichida, shuningdek Markaziy Osiyo va Yaqin Sharq davlatlariga xalqaro parvozlarni amalga oshiradi. FlyAirstan arzon sayohatlarni taklif etadi va yoʻlovchilarga qulay narxlarda aviachiptalarni taqdim etadi.</p>
            <h3>FlyAirstan floti</h3>
            <p>Aviakompaniya zamonaviy havo kemalaridan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~5</td>
                  <td>Oʻrtacha masofali reyslar</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~3</td>
                  <td>Oʻrtacha masofali reyslar, kengaytirilgan sigʻim bilan</td>
                </tr>
              </tbody>
            </table>
            <h3>Yoʻnalish tarmogʻi</h3>
            <p>FlyAirstan Qozogʻiston ichidagi va xalqaro reyslarni muntazam amalga oshiradi:</p>
            <ul>
              <li><b>Almaty – Nur-Sulton:</b> haftada bir necha marta.</li>
              <li><b>Almaty – Toshkent (TAS):</b> muntazam reyslar.</li>
              <li><b>Almaty – Dubay (DXB):</b> xalqaro reyslar.</li>
            </ul>
            <p>Aktual jadval va chipta bronlash imkoniyatlari aviakompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>FlyAirstan Oʻzbekistonda va chet elda qulay narxlarda va sifatli xizmat bilan parvozlarni taqdim etadi, yoʻlovchilarga qulay imkoniyatlar yaratadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat:</h4>
            <p>Qozogʻiston</p>
            <h4>Markaz:</h4>
            <p>Almaty</p>
            <h4>Asosiy havo maydoni:</h4>
            <p>Almaty Xalqaro Aeroporti (ALA)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://flyairstan.com" target="_blank" rel="noreferrer">flyairstan.com</a>
          `,
        },
      ],
    },
    lufthansaAir: {
      pageKey: "lufthansaAirInfo",
      imageUrl: "/images/AK/LH.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Lufthansa</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Lufthansa — Germaniyaning eng yirik aviakompaniyasidir, 1953 yilda tashkil topgan. U Evropaning yetakchi aviatsiya tashuvchilaridan biri bo'lib, Star Alliance alyansining a'zosi hisoblanadi.</p>
            <h3>Lufthansa floti</h3>
            <p>Aviakompaniya yo'lovchilar uchun qulaylik va xavfsizlikni ta'minlash maqsadida keng turdagi zamonaviy samolyotlardan foydalanadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~100</td>
                  <td>Qisqa va o'rtacha masofali yo'nalishlar</td>
                </tr>
                <tr>
                  <td>Boeing 747</td>
                  <td>~30</td>
                  <td>Flagman uzoq masofali reyslar</td>
                </tr>
                <tr>
                  <td>Airbus A350</td>
                  <td>~20</td>
                  <td>Energiya tejovchi xalqaro reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Lufthansa turli yo'nalishlar bo'yicha reyslar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Frankfurt (FRA) – Nyu-York (JFK): </b>har kuni.
              </li>
              <li>
                <b>Myunxen (MUC) – Tokio (HND): </b>har kuni.
              </li>
              <li>
                <b>Frankfurt (FRA) – Dubay (DXB): </b>haftada bir necha marta.
              </li>
            </ul>
            <p>Joriy jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> saytidan yoki aviakompaniyaning rasmiy saytida aniqlang.</p>
            <h3>Xulosa</h3>
            <p>Lufthansa — yuqori xizmat darajasi va keng yo'nalishlar tarmog'iga ega bo'lgan yetakchi evropalik aviakompaniya. Aviabiletlarni eng yaxshi narxlarda <a href="https://biletbor.me">Biletbor.me</a> saytidan buyurtma qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Germaniya</p> 
            <h4>Markaz: </h4> 
            <p>Köln</p> 
            <h4>Asosiy xab: </h4>   
            <p>Frankfurt xalqaro aeroporti (FRA)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.lufthansa.com" target="_blank" rel="noreferrer">www.lufthansa.com</a>
          `,
        },
      ],
    },
    silkAir: {
      pageKey: "silkAviaInfo",
      imageUrl: "/images/AK/US.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Silk Avia</h1>
            <h2>Asos solinish va tarix</h2>
            <p>Silk Avia — O'zbekistonning birinchi mintaqaviy aviakompaniyasi bo'lib, 2021-yil 6-iyulda «Uzbekistan Airports» AJning qiziq kompaniyasi sifatida tashkil etilgan. Aviakompaniyaning maqsadi mamlakatdagi biznes faolligini rag'batlantirish va ichki turizmni rivojlantirishdir. Birinchi tijorat reysi 2023-yil 25-aprelda Toshkent — Samarqand yo'nalishi bo'ylab amalga oshirildi.</p>
            <h3>Silk Avia floti</h3>
            <p>Aviakompaniya zamonaviy havo kemalaridan tashkil topgan floti bilan ta'minlangan:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Maxsus xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ATR 72-600</td>
                  <td>3</td>
                  <td>70 nafar yo'lovchiga mo'ljallangan, mintaqaviy yo'nalishlar uchun iqtisodiy samarali</td>
                </tr>
                <tr>
                  <td>ATR 42-600</td>
                  <td>2 (rejalashtirilgan)</td>
                  <td>50 nafar yo'lovchiga mo'ljallangan, kamroq band yo'nalishlar uchun mos</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalish tarmog'i</h3>
            <p>Silk Avia O'zbekiston shaharlari o'rtasida muntazam reyslarni amalga oshiradi, jumladan:</p>
            <ul>
              <li><b>Toshkent – Buxoro:</b> haftada 16 ta reysgacha.</li>
              <li><b>Toshkent – Termiz:</b> haftada 16 ta reysgacha.</li>
              <li><b>Nukus – Muynak:</b> 2024-yil 1-apreldan boshlab muntazam reyslar.</li>
            </ul>
            <p>Yangilangan jadval va chiptalarni bron qilish uchun aviakompaniyaning rasmiy saytiga tashrif buyurishingiz mumkin.</p>
            <h3>Xulosa</h3>
            <p>Silk Avia O'zbekistonda qulay va arzon uchishlarni taqdim etadi, ichki turizmni rivojlantirishga yordam beradi va mamlakatning mintaqalarini qulay bog'laydi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat:</h4>
            <p>O'zbekiston</p>
            <h4>Shaharda bosh qarorgoh:</h4>
            <p>Toshkent</p>
            <h4>Asosiy hub:</h4>
            <p>Samarqand xalqaro aeroporti (SKD)</p>
            <h4>Vebsayt:</h4>
            <a href="https://silk-avia.com" target="_blank" rel="noreferrer">silk-avia.com</a>
          `,
        },
      ],
    },
    pegasusAir: {
      pageKey: "pegasusAirInfo",
      imageUrl: "/images/AK/PC.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Pegasus Airlines</h1>
            <h2>Asos solinish va tarix</h2>
            <p>Pegasus Airlines — Turkiyaning byudjet aviakompaniyasi bo'lib, 1990-yilda tashkil etilgan. Aviakompaniya arzon narxlarda parvozlarni taqdim etish va Yevropa, Yaqin Sharq va boshqa mintaqalarga reyslar amalga oshirishga ixtisoslashgan.</p>
            <h3>Pegasus Airlines floti</h3>
            <p>Aviakompaniya samarali va qulay parvozlarni ta'minlash uchun zamonaviy havo kemalaridan foydalanadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Maxsus xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~50</td>
                  <td>Qisqa va o'rta masofali yo'nalishlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~25</td>
                  <td>Yonilg'i samaradorligi yuqori</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Pegasus Airlines turli yo'nalishlarga reyslar amalga oshiradi:</p>
            <ul>
              <li><b>Istanbul (SAW) – Berlin (BER):</b> har kuni.</li>
              <li><b>Istanbul (SAW) – London (STN):</b> bir necha marta hafta davomida.</li>
              <li><b>Ankara (ESB) – Dubay (DXB):</b> muntazam reyslar.</li>
            </ul>
            <p>Yangilangan jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy saytida tekshirishingiz mumkin.</p>
            <h3>Xulosa</h3>
            <p>Pegasus Airlines mashhur yo'nalishlarga arzon narxlarda parvozlarni taqdim etadi. Chiptalarni eng yaxshi narxlarda <a href="https://biletbor.me">Biletbor.me</a> saytidan bron qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat:</h4>
            <p>Turkiya</p>
            <h4>Shaharda bosh qarorgoh:</h4>
            <p>Istanbul</p>
            <h4>Asosiy hub:</h4>
            <p>Sabihi Gökçen xalqaro aeroporti (SAW)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.flypgs.com" target="_blank" rel="noreferrer">www.flypgs.com</a>
          `,
        },
      ],
    },
    nordwindAir: {
      pageKey: "nordwindAirInfo",
      imageUrl: "/images/AK/N4.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Nordwind Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Nordwind Airlines — 2008 yilda tashkil etilgan Rossiyaning aviakompaniyasidir. Kompaniya charter va muntazam xalqaro reyslar bilan mashhur bo'lib, mashhur turistik yo'nalishlarda arzon parvozlarni taqdim etadi.</p>
            <h3>Nordwind Airlines floti</h3>
            <p>Aviakompaniya zamonaviy samolyotlarni o'zining yo'lovchilari uchun qulaylik va xavfsizlikni ta'minlash maqsadida ishlatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~5</td>
                  <td>Uzoq masofali yo'nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~10</td>
                  <td>O'rtacha masofali reyslar</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~15</td>
                  <td>Qisqa va o'rtacha masofali parvozlar</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Nordwind Airlines turli yo'nalishlar bo'yicha reyslar amalga oshiradi:</p>
            <ul>
              <li>
                <b> Moskva (SVO) – Antalya (AYT): </b>har kuni.
              </li>
              <li>
                <b> Moskva (SVO) – Dubay (DXB): </b>haftada bir necha marta.
              </li>
              <li>
                <b> Sankt-Peterburg (LED) – Sochi (AER): </b>muntazam reyslar.
              </li>
            </ul>
            <p>Joriy jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> saytidan yoki aviakompaniyaning rasmiy saytida aniqlang.</p>
            <h3>Xulosa</h3>
            <p>Nordwind Airlines — mashhur turistik yo'nalishlarda qulay va arzon parvozlar taqdim etadigan aviakompaniya. Aviabiletlarni eng yaxshi narxlarda <a href="https://biletbor.me">Biletbor.me</a> saytidan buyurtma qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Markaz: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xab: </h4>   
            <p>Sheremetyево xalqaro aeroporti (SVO)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.nordwindairlines.ru" target="_blank" rel="noreferrer">www.nordwindairlines.ru</a>
          `,
        },
      ],
    },
    kamair: {
      pageKey: "kamAirInfo",
      imageUrl: "/images/AK/RQ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Kam Air</h1>
            <h2>Tashkil etilish va tarix</h2>
            <p>Kam Air — Afg'onistonning 2003 yilda tashkil etilgan aviakompaniyasi. Bu Afg'onistondagi birinchi xususiy aviakompaniya bo'lib, ichki va xalqaro reyslarni amalga oshiradi.</p>
            <h3>Kam Air floti</h3>
            <p>Aviakompaniya yo'lovchilarning xavfsizligi va qulayligini ta'minlash uchun turli samolyot turlarini ishlatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Son (taxminan)</th>
                  <th>Maxsus xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~3</td>
                  <td>O'rta masofali yo'nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A340</td>
                  <td>~2</td>
                  <td>Yuqori masofali reyslar</td>
                </tr>
                <tr>
                  <td>McDonnell Douglas MD-87</td>
                  <td>~2</td>
                  <td>Hududiy reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Kam Air quyidagi asosiy yo'nalishlar bo'yicha reyslar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Kobul – Dubay (DXB): </b>haftada bir necha marta.
              </li>
              <li>
                <b>Kobul – Istanbul (IST): </b>haftada 2-3 marta.
              </li>
              <li>
                <b>Kobul – Moskva (VKO): </b>haftada 1-2 marta.
              </li>
            </ul>
            <p>Aktual jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy saytida tekshirib ko'ring.</p>
            <h3>Xulosa</h3>
            <p>Kam Air — Afg'onistonning yetakchi xususiy aviakompaniyasi bo'lib, keng yo'nalishlar tanlovini taqdim etadi. Eng yaxshi narxlarda aviakirishlarni <a href="https://biletbor.me">Biletbor.me</a> orqali bron qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Afg'oniston</p> 
            <h4>Markaz: </h4> 
            <p>Kobul</p> 
            <h4>Asosiy xab: </h4>   
            <p>Kobul xalqaro aeroporti (KBL)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.kamair.com" target="_blank" rel="noreferrer">www.kamair.com</a>
          `,
        },
      ],
    },
    tajikAir: {
      pageKey: "tajikAirInfo",
      imageUrl: "/images/AK/7J.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Tajik Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Tajik Air — Tojikiston milliy aviakompaniyasi bo‘lib, 1924 yilda tashkil etilgan. Bu Markaziy Osiyoning eng qadimgi aviakompaniyalaridan biri bo‘lib, ichki va xalqaro reyslarni amalga oshiradi.</p>
            <h3>Tajik Air aviatsiya parki</h3>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 757</td>
                  <td>~2</td>
                  <td>Uzoq masofali parvozlar</td>
                </tr>
                <tr>
                  <td>An-28</td>
                  <td>~3</td>
                  <td>Qisqa masofali mintaqaviy reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Parvoz jadvali</h3>
            <p>Tajik Air quyidagi asosiy yo‘nalishlarda parvozlarni amalga oshiradi:</p>
            <ul>
              <li><b>Dushanbe (DYU) – Moskva (DME):</b> Muntazam reyslar.</li>
              <li><b>Dushanbe (DYU) – Dubay (DXB):</b> Xalqaro reyslar.</li>
              <li><b>Xo‘jand (LBD) – Bishkek (FRU):</b> Mintaqaviy reyslar.</li>
            </ul>
            <h3>Xulosa</h3>
            <p>Tajik Air Tojikiston transport tizimida muhim rol o‘ynaydi va shaharlar hamda xalqaro yo‘nalishlar o‘rtasida aloqa ta’minlaydi.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Tojikiston</p>
            <h4>Bosh ofis:</h4>
            <p>Dushanbe</p>
            <h4>Asosiy markaz:</h4>
            <p>Dushanbe xalqaro aeroporti (DYU)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.tajikair.tj" target="_blank" rel="noreferrer">www.tajikair.tj</a>
           ` ,
        },
      ],
    },
    somonAir: {
      pageKey: "somonAirInfo",
      imageUrl: "/images/AK/SZ.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Somon Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Somon Air — 2008 yilda tashkil etilgan tojik aviakompaniyasi. Bu Tojikistondagi birinchi xususiy aviakompaniya bo‘lib, ichki va xalqaro reyslarni amalga oshiradi.</p>
            <h3>Somon Air aviaparki</h3>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~6</td>
                  <td>Qisqa va o‘rta masofali parvozlar</td>
                </tr>
              </tbody>
            </table>
            <h3>Parvoz jadvali</h3>
            <p>Somon Air quyidagi asosiy yo‘nalishlarda parvozlarni amalga oshiradi:</p>
            <ul>
              <li><b>Dushanbe (DYU) – Moskva (DME):</b> Tez-tez amalga oshiriladigan reyslar.</li>
              <li><b>Dushanbe (DYU) – Dubay (DXB):</b> Muntazam reyslar.</li>
              <li><b>Xo‘jand (LBD) – Istanbul (IST):</b> Mavsumiy reyslar.</li>
            </ul>
            <h3>Xulosa</h3>
            <p>Somon Air yuqori xavfsizlik standartlari va xalqaro miqyosda kengayib borayotgani bilan mashhur.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Tojikiston</p>
            <h4>Bosh ofis:</h4>
            <p>Dushanbe</p>
            <h4>Asosiy markaz:</h4>
            <p>Dushanbe xalqaro aeroporti (DYU)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.somonair.com" target="_blank" rel="noreferrer">www.somonair.com</a>
            `,
        },
      ],
    },
    flydubaiAir: {
      pageKey: "flydubaiAirInfo",
      imageUrl: "/images/AK/FZ.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Flydubai</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Flydubai — 2008-yilda tashkil etilgan BAAning byudjet aviakompaniyasi. Kompaniya dunyo bo‘ylab 100 dan ortiq yo‘nalishlarni qamrab olgan xalqaro aviaqatnovlarni taklif etadi.</p>
                <h3>Flydubai samolyot parki</h3>
                <p>Aviakompaniya zamonaviy va qulay samolyotlar parkidan foydalanadi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 737-800</td>
                      <td>~30</td>
                      <td>O‘rta masofali yo‘nalishlar</td>
                    </tr>
                    <tr>
                      <td>Boeing 737 MAX</td>
                      <td>~40</td>
                      <td>Zamonaviy texnologiyalar va tejamkorlik</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Parvoz jadvali</h3>
                <p>Flydubai Dubay va turli mamlakatlar, jumladan O‘zbekiston o‘rtasida reyslarni amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Toshkent – Dubay (DXB): </b> har kuni.
                  </li>
                  <li>
                    <b>Samarqand – Dubay (DXB): </b> haftasiga 2-3 marta.
                  </li>
                </ul>
                <p>Eng so‘nggi parvoz jadvali va chiptalar haqida ma’lumotni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Flydubai — qulay jadval va sifatli xizmat ko‘rsatish bilan arzon aviaqatnovlarni taqdim etadi. Eng yaxshi narxlardagi aviachiptalarni <a href="https://biletbor.me">Biletbor.me</a> saytidan band qiling.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>BAA (Birlashgan Arab Amirliklari)</p> 
                <h4>Bosh ofis: </h4> 
                <p>Dubay</p> 
                <h4>Asosiy havo bandargohi: </h4>   
                <p>Dubay xalqaro aeroporti (DXB)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.flydubai.com" target="_blank" rel="noreferrer">www.flydubai.com</a>
          `,
        },
      ],
    },
    ilkarAir: {
      pageKey: "ilkarAirInfo",
      imageUrl: "/images/AK/EO.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Ilkar Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Ilkar Air — 1995-yilda tashkil etilgan xususiy aviakompaniya. U ichki va xalqaro reyslarni amalga oshirib, yo‘lovchilarga premium xizmatlar ko‘rsatadi.</p>
            <h3>Ilkar Air floti</h3>
            <p>Aviakompaniya uzoq masofali va mintaqaviy reyslar uchun zamonaviy samolyotlar ishlatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A330</td>
                  <td>8</td>
                  <td>Uzoq masofali xalqaro reyslar</td>
                </tr>
                <tr>
                  <td>Embraer E190</td>
                  <td>12</td>
                  <td>Mintaqaviy va qisqa masofali reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Ilkar Air 30 dan ortiq yo‘nalishlarni qamrab oladi, jumladan:</p>
            <ul>
              <li><b>Asosiy markaz – London:</b> tez-tez reyslar.</li>
              <li><b>Asosiy markaz – Dubay:</b> muntazam reyslar.</li>
              <li><b>Asosiy markaz – Nyu-York:</b> uzoq masofali xalqaro reyslar.</li>
            </ul>
            <p>Eng so‘nggi jadval va chipta bron qilish ma’lumotlari rasmiy veb-saytda mavjud.</p>
            <h3>Xulosa</h3>
            <p>Ilkar Air ishonchli xizmati va xalqaro rivojlanishi bilan aviatsiya sohasida yetakchi o‘rin egallaydi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Noma’lum</p>
            <h4>Bosh ofis:</h4>
            <p>Asosiy markaz shahri</p>
            <h4>Asosiy markaz:</h4>
            <p>Asosiy xalqaro aeroport</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.ilkarair.com" target="_blank" rel="noreferrer">ilkarair.com</a>
          `,
        },
      ],
    },
    kuwaitAirways: {
      pageKey: "kuwaitAirwaysInfo",
      imageUrl: "/images/AK/KU.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Kuwait Airways</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Kuwait Airways — 1953-yilda tashkil etilgan Kuvayt milliy aviakompaniyasi. Bu Kuvaytdagi eng yirik tashuvchi bo‘lib, mintaqaviy va xalqaro reyslarni taklif qiladi.</p>
            <h3>Kuwait Airways aviaparki</h3>
            <p>Kompaniya uzoq masofali va mintaqaviy reyslar uchun samolyotlardan foydalanadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Taxminiy soni</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>6</td>
                  <td>Uzoq masofali reyslar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>13</td>
                  <td>Mintaqaviy reyslar uchun</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Kuwait Airways 40 dan ortiq yo‘nalishlarga xizmat ko‘rsatadi, jumladan:</p>
            <ul>
              <li><b>Kuvayt – London:</b> doimiy reyslar.</li>
              <li><b>Kuvayt – Dubay:</b> doimiy reyslar.</li>
              <li><b>Kuvayt – Nyu-York:</b> uzoq masofali reyslar.</li>
            </ul>
            <p>Yangilangan jadval va chipta bron qilish aviakompaniyaning rasmiy saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Kuwait Airways xalqaro reyslarda yuqori sifatli xizmatlarni taklif etadi va mintaqadagi yetakchi aviakompaniyalardan biri hisoblanadi.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Kuvayt</p>
            <h4>Bosh ofis:</h4>
            <p>Kuvayt-Siti</p>
            <h4>Asosiy havo bandargohi:</h4>
            <p>Kuvayt xalqaro aeroporti (KWI)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.kuwaitairways.com" target="_blank" rel="noreferrer">kuwaitairways.com</a>
          `,
        },
      ],
    },
    emiratesAir: {
      pageKey: "emiratesAirInfo",
      imageUrl: "/images/AK/EK.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Emirates</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Emirates — dunyoning eng yirik aviakompaniyalaridan biri bo‘lib, 1985-yilda Dubayda (BAA) tashkil etilgan. Kompaniya yuqori darajadagi xizmatlari bilan mashhur bo‘lib, dunyo bo‘ylab 150 dan ortiq yo‘nalishda parvozlarni amalga oshiradi.</p>
                <h3>Emirates samolyot parki</h3>
                <p>Aviakompaniya eng zamonaviy va qulay samolyotlardan foydalanadi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Soni (taxminiy)</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A380</td>
                      <td>~120</td>
                      <td>Ikki qavatli uzoq masofali layner</td>
                    </tr>
                    <tr>
                      <td>Boeing 777</td>
                      <td>~140</td>
                      <td>Uzoq va o‘rta masofali parvozlar uchun moslashuvchanlik</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Reyslar jadvali</h3>
                <p>Emirates Dunyoning yirik shaharlariga, jumladan, O‘zbekistonga ham muntazam reyslar amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Toshkent – Dubay (DXB): </b>har kuni.
                  </li>
                </ul>
                <p>Eng so‘nggi jadval va chiptalar narxini <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Emirates — premium darajadagi aviakompaniya bo‘lib, qulay yo‘nalishlar va birinchi darajali xizmatlarni taklif etadi. Eng yaxshi narxlardagi aviachiptalarni <a href="https://biletbor.me">Biletbor.me</a> saytida bron qiling.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>Birlashtirilgan Arab Amirliklari</p> 
                <h4>Bosh ofis: </h4> 
                <p>Dubay</p> 
                <h4>Asosiy xab: </h4>   
                <p>Dubay xalqaro aeroporti (DXB)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.emirates.com" target="_blank" rel="noreferrer">www.emirates.com</a>
          `,
        },
      ],
    },
    chinaSouthern: {
      pageKey: "chinaSouthernInfo",
      imageUrl: "/images/AK/CZ.png",
      generalInfo: [
        {
          content: `<article>
                <h1>China Southern Airlines</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>China Southern Airlines (中国南方航空) — Xitoyning eng yirik aviakompaniyalaridan biri bo‘lib, 1988-yilda tashkil etilgan. Kompaniya mamlakatning yetakchi tashuvchilaridan biri hisoblanib, yo‘lovchi tashish hajmi bo‘yicha dunyodagi eng yirik aviakompaniyalardan biridir. U ichki va xalqaro yo‘nalishlarda, jumladan, O‘zbekistonga ham parvozlarni amalga oshiradi.</p>
                <h3>China Southern Airlines samolyot parki</h3>
                <p>Kompaniya zamonaviy va keng turdagi samolyotlarni ekspluatatsiya qiladi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A320/321</td>
                      <td>~200</td>
                      <td>Qisqa va o‘rta masofali yo‘nalishlar</td>
                    </tr>
                    <tr>
                      <td>Boeing 737</td>
                      <td>~300</td>
                      <td>Ichki va mintaqaviy yo‘nalishlar uchun moslashuvchan model</td>
                    </tr>
                    <tr>
                      <td>Airbus A350</td>
                      <td>~20</td>
                      <td>Uzoq masofali yo‘nalishlar uchun yuqori qulaylik</td>
                    </tr>
                    <tr>
                      <td>Boeing 787</td>
                      <td>~40</td>
                      <td>Energiya tejovchi uzoq masofali reyslar</td>
                    </tr>
                  </tbody>
                </table>
                <h3>O‘zbekistondan parvoz jadvali</h3>
                <p>China Southern Airlines O‘zbekistondan Xitoyga muntazam reyslarni amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Toshkent – Guanchjou (CAN): </b> haftasiga 2-3 marta.
                  </li>
                  <li>
                    <b>Toshkent – Pekin (PEK): </b> haftasiga 1-2 marta.
                  </li>
                </ul>
                <p>Eng so‘nggi parvoz jadvali va chiptalar haqida ma’lumotni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>China Southern Airlines — keng yo‘nalish tarmog‘iga va zamonaviy samolyot parkiga ega Xitoyning yetakchi aviakompaniyalaridan biri. Eng yaxshi narxlardagi aviachiptalarni <a href="https://biletbor.me">Biletbor.me</a> saytidan band qiling.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>Xitoy</p> 
                <h4>Bosh ofis: </h4> 
                <p>Guanchjou</p> 
                <h4>Asosiy havo bandargohlari: </h4>   
                <p>Guanchjou Bayyun (CAN), Pekin Daxing (PKX), Shanxay Pudun (PVG)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.csair.com" target="_blank" rel="noreferrer">www.csair.com</a>
              `,
        },
      ],
    },
    egyptAir: {
      pageKey: "egyptAirInfo",
      imageUrl: "/images/AK/MS.png",
      generalInfo: [
        {
          content: `<article>
                <h1>EgyptAir</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>EgyptAir — Misrning milliy aviakompaniyasi bo‘lib, 1932-yilda tashkil etilgan. Bu dunyodagi eng qadimgi aviakompaniyalardan biri va Yaqin Sharqdagi birinchi aviataşuvchi hisoblanadi. EgyptAir Star Alliance alyansining a’zosi bo‘lib, dunyo bo‘ylab muntazam reyslarni amalga oshiradi.</p>
                <h3>EgyptAir samolyot parki</h3>
                <p>Aviakompaniya ichki va xalqaro reyslar uchun zamonaviy havo kemalaridan foydalanadi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A320/321</td>
                      <td>~20</td>
                      <td>O‘rta masofali reyslar</td>
                    </tr>
                    <tr>
                      <td>Boeing 737</td>
                      <td>~30</td>
                      <td>Qisqa va mintaqaviy yo‘nalishlar</td>
                    </tr>
                    <tr>
                      <td>Airbus A330</td>
                      <td>~10</td>
                      <td>Uzoq masofali reyslar</td>
                    </tr>
                    <tr>
                      <td>Boeing 787</td>
                      <td>~6</td>
                      <td>Xalqaro parvozlar uchun yuqori qulaylik</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Parvoz jadvali</h3>
                <p>EgyptAir Misr va turli davlatlar, jumladan O‘zbekiston o‘rtasida reyslarni amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Toshkent – Qohira (CAI): </b>haftasiga 2 marta.
                  </li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>EgyptAir – Yaqin Sharqdagi yetakchi aviakompaniyalardan biri bo‘lib, qulay parvozlar va yuqori xizmat darajasini taklif etadi. Eng yaxshi narxlardagi aviachiptalarni <a href="https://biletbor.me">Biletbor.me</a> orqali bron qiling.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>Misr</p> 
                <h4>Bosh ofis: </h4> 
                <p>Qohira</p> 
                <h4>Asosiy havo bandargohi: </h4>   
                <p>Qohira xalqaro aeroporti (CAI)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.egyptair.com" target="_blank" rel="noreferrer">www.egyptair.com</a>
          `,
        },
      ],
    },
    centrumAir: {
      pageKey: "centrumAirInfo",
      imageUrl: "/images/AK/C6.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Centrum Air</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Centrum Air — O‘zbekiston aviakompaniyasi bo‘lib, 2022-yilda tashkil etilgan. Kompaniya arzon va charter reyslarga yo‘naltirilgan bo‘lib, mamlakat ichida va xalqaro yo‘nalishlarda qulay narxlarda parvozlarni ta’minlaydi.</p>
                <h3>Centrum Air samolyot parki</h3>
                <p>Aviakompaniya zamonaviy havo kemalaridan foydalanib, samarali va qulay parvozlarni amalga oshiradi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 737</td>
                      <td>~5</td>
                      <td>O‘rta masofali yo‘nalishlar</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Parvoz jadvali</h3>
                <p>Centrum Air quyidagi yo‘nalishlar bo‘yicha reyslarni amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Toshkent – Moskva (VKO): </b>haftada bir necha marta.
                  </li>
                  <li>
                    <b>Toshkent – Dubay (DXB): </b>haftasiga 2-3 marta.
                  </li>
                </ul>
                <p>Eng dolzarb jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytida tekshiring.</p>
                <h3>Xulosa</h3>
                <p>Centrum Air — qulay jadval va arzon narxlarda parvozlarni taklif etuvchi aviakompaniya. Eng yaxshi narxlarda aviabilet bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytidan foydalaning.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>O‘zbekiston</p> 
                <h4>Bosh ofis: </h4> 
                <p>Toshkent</p> 
                <h4>Asosiy havo bandargohi: </h4>   
                <p>Toshkent xalqaro aeroporti (TAS)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.centrumair.uz" target="_blank" rel="noreferrer">www.centrumair.uz</a>
              `,
        },
      ],
    },
    pia: {
      pageKey: "piaInfo",
      imageUrl: "/images/AK/PK.png",
      generalInfo: [
        {
          content:` <article>
                <h1>Pakistan International Airlines (PIA)</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>PIA 1955-yilda tashkil etilgan bo‘lib, Pokistonning milliy aviakompaniyasi hisoblanadi. U keng xalqaro va ichki reyslar tarmog‘iga ega bo‘lib, Pokistonni dunyo miqyosida namoyon etadi.</p>
                <h3>PIA samolyot parki</h3>
                <p>PIA uzoq va o‘rta masofali yo‘nalishlar uchun mos samolyotlar parkiga ega:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 777</td>
                      <td>5</td>
                      <td>Uzoq masofali xalqaro reyslar uchun</td>
                    </tr>
                    <tr>
                      <td>Airbus A320</td>
                      <td>10</td>
                      <td>Ichki va mintaqaviy yo‘nalishlar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>PIA 30 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Islomobod – London:</b> muntazam reyslar.</li>
                  <li><b>Korachi – Dubay:</b> muntazam reyslar.</li>
                  <li><b>Lahor – Nyu-York:</b> uzoq masofali reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>PIA uzoq va mintaqaviy yo‘nalishlar bo‘ylab keng parvoz imkoniyatlarini taklif etib, sifatli xizmatlar bilan sayohatchilarga xizmat qiladi.</p>
              </article>  `,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Pokiston</p>
                <h4>Bosh ofis:</h4>
                <p>Islomobod</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Korachi xalqaro aeroporti (KHI)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.piac.com.pk" target="_blank" rel="noreferrer">piac.com.pk</a>
              `,
        },
      ],
    },
    airblue: {
      pageKey: "airblueInfo",
      imageUrl: "/images/AK/PA.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Airblue</h1>
            <h2>Tasish va tarix</h2>
            <p>Airblue 2003 yilda tashkil etilgan bo'lib, Pokistonning eng yirik xususiy aviakompaniyalaridan biridir. Aviakompaniya ichki va xalqaro reyslarni amalga oshiradi.</p>
            <h3>Airblue floti</h3>
            <p>Airblue floti mintaqaviy va xalqaro reyslar uchun mo'ljallangan havo kemalarini o'z ichiga oladi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminan)</th>
                  <th>Maxsus xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>7</td>
                  <td>Mintaqaviy reyslar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>4</td>
                  <td>Uzoq masofali reyslar uchun</td>
                </tr>
              </tbody>
            </table>
            <h3>Marshrut tarmog'i</h3>
            <p>Airblue 15 dan ortiq yo'nalishlarda reyslar amalga oshiradi, shu jumladan:</p>
            <ul>
              <li><b>Islomobod – Lahor:</b> muntazam reyslar.</li>
              <li><b>Karachi – Dubay:</b> muntazam reyslar.</li>
              <li><b>Islomobod – Manchester:</b> muntazam reyslar.</li>
            </ul>
            <p>Aktual jadval va chiptalarni bron qilish uchun aviakompaniyaning rasmiy saytiga tashrif buyurishingiz mumkin.</p>
            <h3>Xulosa</h3>
            <p>Airblue ichki va xalqaro sayohatchilar uchun qulay va arzon reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat:</h4>
            <p>Pokiston</p>
            <h4>Markaz:</h4>
            <p>Islomobod</p>
            <h4>Asosiy havo yo'li:</h4>
            <p>Islomobod xalqaro aeroporti (ISB)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.airblue.com" target="_blank" rel="noreferrer">airblue.com</a>
          `,
        },
      ],
    },
    sereneAir: {
      pageKey: "sereneAirInfo",
      imageUrl: "/images/AK/ER.png",
      generalInfo: [
        {
          content:` <article>
                <h1>SereneAir</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>SereneAir 2017-yilda tashkil etilgan bo‘lib, Pokistondagi xususiy aviakompaniyalardan biridir. Kompaniya ichki va xalqaro yo‘nalishlarda parvozlar amalga oshirib, yo‘lovchilarga qulay va arzon xizmatlarni taqdim etadi.</p>
                <h3>SereneAir samolyot parki</h3>
                <p>SereneAir qisqa va o‘rta masofali parvozlar uchun samolyotlarga ega:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 737-800</td>
                      <td>6</td>
                      <td>Ichki va mintaqaviy reyslar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>SereneAir 10 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Islomobod – Korachi:</b> muntazam reyslar.</li>
                  <li><b>Lahor – Dubay:</b> muntazam reyslar.</li>
                  <li><b>Islomobod – Madjid:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>SereneAir ichki va xalqaro yo‘nalishlarda qulay va arzon parvozlarni taqdim etadi.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Pokiston</p>
                <h4>Bosh ofis:</h4>
                <p>Islomobod</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Islomobod xalqaro aeroporti (ISB)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.sereneair.com" target="_blank" rel="noreferrer">sereneair.com</a>
              `,
        },
      ],
    },
    airIndia: {
      pageKey: "airIndiaInfo",
      imageUrl: "/images/AK/AI.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Air India</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Air India 1932-yilda tashkil etilgan bo‘lib, Hindistonning milliy aviakompaniyasi hisoblanadi. Kompaniya ko‘plab xalqaro va ichki yo‘nalishlarda parvozlar amalga oshiradi va Janubiy Osiyodagi eng yirik aviakompaniyalardan biridir.</p>
                <h3>Air India samolyot parki</h3>
                <p>Air India uzoq masofali parvozlar uchun zamonaviy havo kemalariga ega:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 777</td>
                      <td>6</td>
                      <td>Uzoq masofali reyslar, yoqilg‘i tejamkorligi</td>
                    </tr>
                    <tr>
                      <td>Airbus A320</td>
                      <td>30</td>
                      <td>Ichki va mintaqaviy yo‘nalishlar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>Air India 50 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Dehli – Nyu-York:</b> muntazam reyslar.</li>
                  <li><b>Mumbay – London:</b> muntazam reyslar.</li>
                  <li><b>Dehli – Singapur:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Air India butun dunyo bo‘ylab yo‘lovchilar uchun keng yo‘nalishlarni va yuqori sifatli xizmatlarni taqdim etadi.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Hindiston</p>
                <h4>Bosh ofis:</h4>
                <p>Nyu-Dehli</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Indira Gandi xalqaro aeroporti (DEL)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.airindia.in" target="_blank" rel="noreferrer">airindia.in</a>
              `,
        },
      ],
    },
    vistara: {
      pageKey: "vistaraInfo",
      imageUrl: "/images/AK/UK.png",
      generalInfo: [
        {
          content:` <article>
                <h1>Vistara</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Vistara 2013-yilda tashkil etilgan bo‘lib, Tata Sons va Singapore Airlines kompaniyalarining qo‘shma korxonasi hisoblanadi. Aviakompaniya yuqori darajadagi xizmatlarni taklif qilib, xalqaro va ichki reyslarni amalga oshiradi.</p>
                <h3>Vistara samolyot parki</h3>
                <p>Vistara zamonaviy havo kemalariga ega bo‘lib, uzoq va o‘rta masofali reyslar uchun mo‘ljallangan:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A320</td>
                      <td>30</td>
                      <td>Ichki reyslar uchun</td>
                    </tr>
                    <tr>
                      <td>Airbus A321</td>
                      <td>10</td>
                      <td>Xalqaro reyslar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>Vistara 30 dan ortiq yo‘nalishlar bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Dehli – Mumbay:</b> muntazam reyslar.</li>
                  <li><b>Dehli – Singapur:</b> muntazam reyslar.</li>
                  <li><b>Mumbay – Bangkok:</b> xalqaro reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Vistara — yo‘lovchilarga yuqori sifatli xizmat va qulaylikni taklif qiluvchi premium aviakompaniya.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Hindiston</p>
                <h4>Bosh ofis:</h4>
                <p>Gurgaon</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Indira Gandi xalqaro aeroporti (DEL)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.airvistara.com" target="_blank" rel="noreferrer">airvistara.com</a>
              `,
        },
      ],
    },
    gulfAir: {
      pageKey: "gulfAirInfo",
      imageUrl: "/images/AK/GF.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Gulf Air</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Gulf Air 1950-yilda tashkil etilgan va Bahrayn milliy aviakompaniyasi hisoblanadi. Kompaniya mintaqaviy va xalqaro yo‘nalishlarda yuqori sifatli xizmatlarni taqdim etadi.</p>
                <h3>Gulf Air samolyot parki</h3>
                <p>Gulf Air floti zamonaviy va samarali samolyotlardan iborat bo‘lib, turli yo‘nalishlarga moslashtirilgan:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 787-9</td>
                      <td>10</td>
                      <td>Uzoq masofali xalqaro reyslar uchun</td>
                    </tr>
                    <tr>
                      <td>Airbus A321neo</td>
                      <td>8</td>
                      <td>O‘rta masofali reyslar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>Gulf Air 50 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Manama – Dubay:</b> muntazam reyslar.</li>
                  <li><b>Manama – London:</b> muntazam reyslar.</li>
                  <li><b>Manama – Mumbai:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalar bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
                <h3>Xulosa</h3>
                <p>Gulf Air – yuqori sifatli xizmat va qulay parvozlarni ta’minlovchi aviakompaniya bo‘lib, mintaqaviy va xalqaro yo‘nalishlarda ishonchli tanlovdir.</p>
              </article> `,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Bahrayn</p>
                <h4>Bosh ofis:</h4>
                <p>Manama</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Bahrayn xalqaro aeroporti (BAH)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.gulfair.com" target="_blank" rel="noreferrer">gulfair.com</a>
              `,
        },
      ],
    },
    omanAir: {
      pageKey: "omanAirInfo",
      imageUrl: "/images/AK/WY.png",
      generalInfo: [
        {
          content: ` <article>
                <h1>Oman Air</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Oman Air 1993-yilda Omon milliy aviakompaniyasi sifatida tashkil etilgan. Aviakompaniya ichki va xalqaro reyslarni amalga oshiradi, uzoq masofalarga hamda qo‘shni mamlakatlarga qulay parvozlarni ta’minlaydi.</p>
                <h3>Oman Air samolyot parki</h3>
                <p>Oman Air zamonaviy havo kemalaridan iborat flotga ega bo‘lib, xalqaro yo‘nalishlar uchun foydalaniladi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boeing 787 Dreamliner</td>
                      <td>5</td>
                      <td>Uzoq masofali xalqaro reyslar uchun</td>
                    </tr>
                    <tr>
                      <td>Airbus A330</td>
                      <td>6</td>
                      <td>O‘rta va uzoq masofali yo‘nalishlar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>Oman Air 50 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Maskat – Dubay:</b> muntazam reyslar.</li>
                  <li><b>Maskat – London:</b> muntazam reyslar.</li>
                  <li><b>Maskat – Singapur:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Oman Air qulay va sifatli xizmatlar bilan Omonni dunyo bilan bog‘laydigan xalqaro yo‘nalishlarni taqdim etadi.</p>
              </article> `,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Omon</p>
                <h4>Bosh ofis:</h4>
                <p>Maskat</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Maskat xalqaro aeroporti (MCT)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.omanair.com" target="_blank" rel="noreferrer">omanair.com</a>
              `,
        },
      ],
    },
    salamAir: {
      pageKey: "salamAirInfo",
      imageUrl: "/images/AK/OV.png",
      generalInfo: [
        {
          content: `<article>
                <h1>SalamAir</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>SalamAir 2016-yilda tashkil etilgan va Ummonning birinchi arzon aviakompaniyasi hisoblanadi. Kompaniya ichki va xalqaro yo‘nalishlarda byudjet parvozlarini ta’minlashga yo‘naltirilgan.</p>
                <h3>SalamAir samolyot parki</h3>
                <p>SalamAir floti iqtisodiy va qulay samolyotlardan iborat bo‘lib, mintaqaviy hamda xalqaro reyslarni amalga oshirishga moslashtirilgan:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A320neo</td>
                      <td>8</td>
                      <td>Mintaqaviy va qisqa masofali reyslar uchun</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>SalamAir 30 dan ortiq yo‘nalish bo‘yicha parvozlarni amalga oshiradi, jumladan:</p>
                <ul>
                  <li><b>Maskat – Dubay:</b> muntazam reyslar.</li>
                  <li><b>Maskat – Jidda:</b> muntazam reyslar.</li>
                  <li><b>Maskat – Ko‘chin:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalar bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
                <h3>Xulosa</h3>
                <p>SalamAir – arzon narxlarda qulay parvozlarni ta’minlovchi aviakompaniya bo‘lib, byudjet sayohatchilar uchun ideal tanlovdir.</p>
              </article> `,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Ummon</p>
                <h4>Bosh ofis:</h4>
                <p>Maskat</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Maskat xalqaro aeroporti (MCT)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.salamair.com" target="_blank" rel="noreferrer">salamair.com</a>
              `,
        },
      ],
    },
    kishAir: {
      pageKey: "kishAirInfo",
      imageUrl: "/images/AK/Y9.jpeg",
      generalInfo: [
        {
          content:` <article>
            <h1>Kish Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Kish Air 1993-yilda tashkil etilgan Eron aviakompaniyasidir. Bu kompaniya Kishda eng yirik tashuvchi bo‘lib, Eronning boshqa shaharlariga va xalqaro yo‘nalishlarga muntazam reyslar amalga oshiradi.</p>
            <h3>Kish Air parki</h3>
            <p>Kompaniya parki hududiy va xalqaro reyslar uchun samolyotlardan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>5</td>
                  <td>180 yo‘lovchigacha sig‘adigan o‘rta masofali reyslar</td>
                </tr>
                <tr>
                  <td>Fokker 100</td>
                  <td>2</td>
                  <td>100 yo‘lovchigacha sig‘adigan hududiy reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Kish Air quyidagi yo‘nalishlarda reyslar amalga oshiradi:</p>
            <ul>
              <li><b>Kish – Tehron:</b> muntazam reyslar.</li>
              <li><b>Kish – Sharja:</b> xalqaro reyslar.</li>
              <li><b>Kish – Dubay:</b> muntazam reyslar.</li>
            </ul>
            <p>Eng so‘nggi jadval va chipta bron qilish kompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Kish Air Kishda va Eronning boshqa shaharlarida, shuningdek, Yaqin Sharq mamlakatlariga sifatli reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Eron</p>
            <h4>Bosh ofis:</h4>
            <p>Tehron</p>
            <h4>Asosiy markaz:</h4>
            <p>Kish xalqaro aeroporti (KIH)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://kishairlines.ir" target="_blank" rel="noreferrer">kishairlines.ir</a>
          `,
        },
      ],
    },
    qeshmAir: {
      pageKey: "qeshmAirInfo",
      imageUrl: "/images/AK/QB.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Qeshm Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Qeshm Air 1993-yilda tashkil etilgan Eron aviakompaniyasidir. U Eron ichida va xalqaro yo‘nalishlarda reyslar amalga oshiradi.</p>
            <h3>Qeshm Air parki</h3>
            <p>Kompaniya parki turli masofalar uchun mo‘ljallangan samolyotlardan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>4</td>
                  <td>180 yo‘lovchigacha sig‘adigan o‘rta masofali reyslar</td>
                </tr>
                <tr>
                  <td>Fokker 100</td>
                  <td>3</td>
                  <td>100 yo‘lovchigacha sig‘adigan hududiy reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Qeshm Air quyidagi yo‘nalishlarda reyslar amalga oshiradi:</p>
            <ul>
              <li><b>Tehron – Kish:</b> muntazam reyslar.</li>
              <li><b>Tehron – Sharja:</b> xalqaro reyslar.</li>
              <li><b>Tehron – Dubay:</b> muntazam reyslar.</li>
            </ul>
            <p>Eng so‘nggi jadval va chipta bron qilish kompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Qeshm Air Eron ichida va Yaqin Sharq davlatlariga qulay reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Eron</p>
            <h4>Bosh ofis:</h4>
            <p>Tehron</p>
            <h4>Asosiy markaz:</h4>
            <p>Kish xalqaro aeroporti (KIH)</p>
            <h4>Veb-sayt:</h4>
            <a href="http://www.qeshm-air.com" target="_blank" rel="noreferrer">qeshm-air.com</a>
          `,
        },
      ],
    },
    iranAsemanAirlines: {
      pageKey: "iranAsemanAirlinesInfo",
      imageUrl: "/images/AK/IB.png",
      generalInfo: [
        {
          content:` <article>
            <h1>Iran Aseman Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Iran Aseman Airlines 1980-yilda tashkil etilgan Eron aviakompaniyasidir. Bu kompaniya Eronning eng yirik tashuvchilaridan biri bo‘lib, ichki va xalqaro yo‘nalishlarda muntazam reyslarni amalga oshiradi. Aviakompaniya dunyo bo‘ylab 40 dan ortiq yo‘nalishlarni qamrab oladi.</p>
            <h3>Iran Aseman Airlines parki</h3>
            <p>Kompaniya parki har xil turdagi reyslar uchun mo‘ljallangan keng va tor fuzelyajli samolyotlardan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ATR 72-600</td>
                  <td>5</td>
                  <td>Hududiy reyslar uchun iqtisodiy samolyotlar</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>6</td>
                  <td>180 yo‘lovchigacha sig‘adigan o‘rta masofali reyslar</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Iran Aseman Airlines quyidagi yo‘nalishlarda reyslar amalga oshiradi:</p>
            <ul>
              <li><b>Tehron – Sharja:</b> muntazam reyslar.</li>
              <li><b>Tehron – Damashq:</b> xalqaro reyslar.</li>
              <li><b>Tehron – Bag‘dod:</b> muntazam reyslar.</li>
            </ul>
            <p>Eng so‘nggi jadval va chipta bron qilish kompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Iran Aseman Airlines ichki va xalqaro yo‘nalishlarda keng imkoniyatlar taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Eron</p>
            <h4>Bosh ofis:</h4>
            <p>Tehron</p>
            <h4>Asosiy markaz:</h4>
            <p>Tehron xalqaro aeroporti (IKA)</p>
            <h4>Veb-sayt:</h4>
            <a href="http://www.apa-air.com" target="_blank" rel="noreferrer">apa-air.com</a>
          `,
        },
      ],
    },
    syrianAir: {
      pageKey: "syrianAirInfo",
      imageUrl: "/images/AK/RB.png",
      generalInfo: [
        {
          content: `<article>
                <h1>SyrianAir</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>SyrianAir — Suriyaning milliy aviakompaniyasi bo‘lib, 1946-yilda tashkil etilgan. Aviakompaniya ichki va xalqaro reyslarni amalga oshiradi hamda Yaqin Sharq mintaqasi uchun muhim tashuvchi hisoblanadi.</p>
                <h3>SyrianAir samolyot parki</h3>
                <p>Aviakompaniya ichki va xalqaro yo‘nalishlar uchun bir nechta turdagi samolyotlardan foydalanadi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A320</td>
                      <td>4</td>
                      <td>180 yo‘lovchigacha sig‘adigan o‘rta masofali reyslar</td>
                    </tr>
                    <tr>
                      <td>Boeing 737</td>
                      <td>2</td>
                      <td>Mintaqaviy va xalqaro reyslar</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Yo‘nalishlar tarmog‘i</h3>
                <p>SyrianAir quyidagi yo‘nalishlar bo‘yicha reyslarni amalga oshiradi:</p>
                <ul>
                  <li><b>Damashq – Bayrut:</b> muntazam reyslar.</li>
                  <li><b>Damashq – Moskva:</b> xalqaro reyslar.</li>
                  <li><b>Damashq – Qohira:</b> muntazam reyslar.</li>
                </ul>
                <p>Eng dolzarb parvoz jadvali va chiptalarni aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>SyrianAir mintaqadagi muhim aviakompaniyalardan biri bo‘lib, Yaqin Sharq va Yevropa davlatlariga reyslarni amalga oshiradi.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat:</h4>
                <p>Suriya</p>
                <h4>Bosh ofis:</h4>
                <p>Damashq</p>
                <h4>Asosiy havo bandargohi:</h4>
                <p>Damashq xalqaro aeroporti (DAM)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.syriaair.com" target="_blank" rel="noreferrer">syriaair.com</a>
              `,
        },
      ],
    },
    middleEastAirlines: {
      pageKey: "middleEastAirlinesInfo",
      imageUrl: "/images/AK/ME.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Middle East Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Middle East Airlines (MEA) — Livanning milliy aviakompaniyasi bo‘lib, 1945-yilda tashkil etilgan. Bu Yaqin Sharqdagi eng yirik aviakompaniyalardan biri bo‘lib, mahalliy va xalqaro reyslarni amalga oshiradi.</p>
            <h3>Middle East Airlines samolyot parki</h3>
            <p>Kompaniya zamonaviy xalqaro yo‘nalishlar uchun quyidagi samolyotlarni ishlatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>8</td>
                  <td>O‘rta masofali reyslar, 180 yo‘lovchigacha sig‘imi</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>4</td>
                  <td>Uzoq masofali reyslar uchun</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo‘nalishlar tarmog‘i</h3>
            <p>Middle East Airlines dunyo bo‘ylab 30 dan ortiq yo‘nalishda parvozlar amalga oshiradi, jumladan:</p>
            <ul>
              <li><b>Beirut – Parij:</b> muntazam reyslar.</li>
              <li><b>Beirut – Nyu-York:</b> xalqaro reyslar.</li>
              <li><b>Beirut – Dubay:</b> muntazam reyslar.</li>
            </ul>
            <p>Joriy jadval va chiptalar bron qilish rasmiy veb-saytda mavjud.</p>
            <h3>Xulosa</h3>
            <p>Middle East Airlines Livanning muhim aviakompaniyasi bo‘lib, Yevropa, Amerika va Yaqin Sharq bo‘ylab keng yo‘nalishlarni taklif etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Livan</p>
            <h4>Bosh ofis:</h4>
            <p>Beirut</p>
            <h4>Asosiy havo bandargohi:</h4>
            <p>Beirut xalqaro aeroporti (BEY)</p>
            <h4>Veb-sayt:</h4>
            <a href="http://www.mea.com.lb" target="_blank" rel="noreferrer">mea.com.lb</a>
          `,
        },
      ],
    },
    aircompanyArmenia: {
      pageKey: "aircompanyArmeniaInfo",
      imageUrl: "/images/AK/JI.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Aircompany Armenia</h1>
            <h2>Asos solingan sana va tarix</h2>
            <p>Aircompany Armenia — Armaniston aviakompaniyasi bo'lib, 2003 yilda tashkil etilgan. Kompaniya ichki va xalqaro yo'lovchi tashish xizmatlarini taqdim etadi, shu jumladan Yevropa, Osiyo va Yaqin Sharq yo'nalishlarida reyslarni amalga oshiradi.</p>
            <h3>Aircompany Armenia havo kemalari parki</h3>
            <p>Aviakompaniyaning floti qisqa va o'rta masofaga mo'ljallangan samolyotlardan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>2</td>
                  <td>O'rta masofali reyslar, 180 yo'lovchigacha sig'im</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>Qisqa masofali yo'nalishlar, 140 yo'lovchigacha sig'im</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalishlar tarmog'i</h3>
            <p>Aircompany Armenia quyidagi yo'nalishlarda parvozlar amalga oshiradi:</p>
            <ul>
              <li><b>Yerevan – Moskva:</b> muntazam reyslar.</li>
              <li><b>Yerevan – Istanbul:</b> xalqaro reyslar.</li>
            </ul>
            <p>Aktual jadval va chipta bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Aircompany Armenia Yevropa va Yaqin Sharqqa qulay va ishonchli reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Armaniston</p>
            <h4>Bosh ofis:</h4>
            <p>Yerevan</p>
            <h4>Asosiy xab:</h4>
            <p>Zvartnots xalqaro aeroporti (EVN)</p>
            <h4>Vebsayt:</h4>
            <a href="http://www.aircompanyarmenia.am" target="_blank" rel="noreferrer">aircompanyarmenia.am</a>
          `,
        },
      ],
    },
    arianaAfghanAirlines: {
      pageKey: "arianaAfghanAirlinesInfo",
      imageUrl: "/images/AK/FG.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Ariana Afghan Airlines</h1>
            <h2>Asos solingan sana va tarix</h2>
            <p>Ariana Afghan Airlines — Afg'oniston milliy aviakompaniyasi bo'lib, 1955 yilda tashkil etilgan. Bu mamlakatdagi eng qadimgi aviakompaniya bo'lib, Afg'oniston ichida hamda Osiyo, Yevropa va Yaqin Sharq mamlakatlariga xalqaro reyslarni amalga oshiradi.</p>
            <h3>Ariana Afghan Airlines havo kemalari parki</h3>
            <p>Aviakompaniya o'rta va uzoq masofali parvozlar uchun quyidagi samolyotlarga ega:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A310</td>
                  <td>2</td>
                  <td>O'rta masofaga mo'ljallangan reyslar, 280 yo'lovchigacha sig'im</td>
                </tr>
                <tr>
                  <td>Boeing 737-400</td>
                  <td>3</td>
                  <td>Mintaqaviy va xalqaro reyslar, 160 yo'lovchigacha sig'im</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalishlar tarmog'i</h3>
            <p>Ariana Afghan Airlines quyidagi yo'nalishlar bo'yicha reyslarni amalga oshiradi:</p>
            <ul>
              <li><b>Kobul – Dubay:</b> haftasiga bir necha marta muntazam reyslar.</li>
              <li><b>Kobul – Peshovar:</b> haftasiga bir necha marta parvozlar.</li>
              <li><b>Kobul – Islomobod:</b> xalqaro reyslar.</li>
            </ul>
            <p>Aktual jadval va chipta bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Ariana Afghan Airlines Afg'oniston ichki va xalqaro yo'nalishlari bo'yicha ishonchli va qulay reyslarni taklif etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Afg'oniston</p>
            <h4>Bosh ofis:</h4>
            <p>Kobul</p>
            <h4>Asosiy xab:</h4>
            <p>Kobul xalqaro aeroporti (KBL)</p>
            <h4>Vebsayt:</h4>
            <a href="http://www.flyariana.com" target="_blank" rel="noreferrer">flyariana.com</a>
          `,
        },
      ],
    },
    georgianAirways: {
      pageKey: "georgianAirwaysInfo",
      imageUrl: "/images/AK/GNN.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Georgian Airways</h1>
            <h2>Asos solingan sana va tarix</h2>
            <p>Georgian Airways — Gruziyaning milliy aviakompaniyasi bo'lib, 1994 yilda tashkil etilgan. Aviakompaniya xalqaro va ichki yo'nalishlar bo'yicha muntazam yo'lovchi reyslarini amalga oshiradi, gruziyalik va xalqaro yo'lovchilar uchun transport xizmatlarining mavjudligini ta'minlaydi.</p>
            <h3>Georgian Airways havo kemalari ilkarparki</h3>
            <p>Aviakompaniyaning havo kemalari xalqaro va mintaqaviy reyslarga xizmat ko'rsatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Embraer 190</td>
                  <td>2</td>
                  <td>O'rta masofaga mo'ljallangan reyslar, 100 yo'lovchigacha sig'im</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>O'rta masofali reyslar, 140 yo'lovchigacha sig'im</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalishlar tarmog'i</h3>
            <p>Georgian Airways quyidagi muntazam reyslarni amalga oshiradi:</p>
            <ul>
              <li><b>Tbilisi – Moskva:</b> haftasiga bir necha marta.</li>
              <li><b>Tbilisi – Istanbul:</b> muntazam reyslar.</li>
              <li><b>Tbilisi – Milan:</b> haftasiga bir necha marta xalqaro reyslar.</li>
            </ul>
            <p>Aktual jadval va chipta bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>Georgian Airways Gruziya va ko'plab xalqaro yo'nalishlar o'rtasida qulay va ishonchli reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Gruziya</p>
            <h4>Bosh ofis:</h4>
            <p>Tbilisi</p>
            <h4>Asosiy xab:</h4>
            <p>Tbilisi xalqaro aeroporti (TBS)</p>
            <h4>Vebsayt:</h4>
            <a href="http://www.georgian-airways.com" target="_blank" rel="noreferrer">georgian-airways.com</a>
          `,
        },
      ],
    },
    myWayAirlines: {
      pageKey: "myWayAirlinesInfo",
      imageUrl: "/images/AK/MJ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>MyWay Airlines</h1>
            <h2>Asos solingan sana va tarix</h2>
            <p>MyWay Airlines — 2017 yilda tashkil etilgan gruziyalik aviakompaniya bo'lib, xalqaro tashuvlarga ixtisoslashgan. Kompaniya Gruziya va boshqa mintaqaviy davlatlar o'rtasidagi havo transportining mavjudligini oshirish maqsadida yaratilgan.</p>
            <h3>MyWay Airlines havo kemalari parki</h3>
            <p>Aviakompaniya yo'lovchilar uchun qulay sharoit yaratish maqsadida quyidagi zamonaviy samolyotlardan foydalanadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminiy)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>2</td>
                  <td>O'rta masofaga mo'ljallangan reyslar, 180 yo'lovchigacha sig'im</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>1</td>
                  <td>Xalqaro reyslar uchun mos, iqtisodiy jihatdan samarali</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalishlar tarmog'i</h3>
            <p>MyWay Airlines quyidagi reyslarni amalga oshiradi:</p>
            <ul>
              <li><b>Tbilisi – Istanbul:</b> haftasiga bir necha marta muntazam reyslar.</li>
              <li><b>Tbilisi – Moskva:</b> haftasiga bir necha marta reyslar.</li>
            </ul>
            <p>Aktual jadval va chipta bron qilish aviakompaniyaning rasmiy veb-saytida mavjud.</p>
            <h3>Xulosa</h3>
            <p>MyWay Airlines yuqori sifatli xizmat bilan xalqaro reyslarni taqdim etadi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Mamlakat:</h4>
            <p>Gruziya</p>
            <h4>Bosh ofis:</h4>
            <p>Tbilisi</p>
            <h4>Asosiy xab:</h4>
            <p>Tbilisi xalqaro aeroporti (TBS)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.mywayairlines.ge" target="_blank" rel="noreferrer">mywayairlines.ge</a>
          `,
        },
      ],
    },
    koreanAir: {
      pageKey: "koreanAirInfo",
      imageUrl: "/images/AK/KE.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Korean Air</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>Korean Air — Janubiy Koreyaning eng yirik aviakompaniyasidir, 1969 yilda tashkil topgan. U mamlakatning milliy tashuvchisi bo'lib, keng ko'lamli xalqaro va ichki reyslarni taklif etadi.</p>
            <h3>Korean Air floti</h3>
            <p>Aviakompaniya yuqori darajadagi qulaylik va xavfsizlikni ta'minlash maqsadida zamonaviy samolyotlardan foydalanadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Miqdori (taxminan)</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~25</td>
                  <td>Uzoq masofali yo'nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A380</td>
                  <td>~10</td>
                  <td>Flagman xalqaro reyslar</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~15</td>
                  <td>Zamonaviy yoqilg'i tejovchi samolyotlar</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Korean Air turli yo'nalishlar bo'yicha reyslar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Seul (ICN) – Nyu-York (JFK): </b>har kuni.
              </li>
              <li>
                <b>Seul (ICN) – London (LHR): </b>har kuni.
              </li>
              <li>
                <b>Seul (ICN) – Tokio (NRT): </b>kuniga bir necha marta.
              </li>
            </ul>
            <p>Joriy jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> saytidan yoki aviakompaniyaning rasmiy saytida aniqlang.</p>
            <h3>Xulosa</h3>
            <p>Korean Air — yuqori xizmat darajasi va keng yo'nalishlarga ega bo'lgan yetakchi aviakompaniya. Aviabiletlarni eng yaxshi narxlarda <a href="https://biletbor.me">Biletbor.me</a> saytidan buyurtma qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Janubiy Koreya</p> 
            <h4>Markaz: </h4> 
            <p>Seul</p> 
            <h4>Asosiy xab: </h4>   
            <p>Incheon xalqaro aeroporti (ICN)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.koreanair.com" target="_blank" rel="noreferrer">www.koreanair.com</a>
          `,
        },
      ],
    },
    jazeeraAirways: {
      pageKey: "jazeeraAirwaysInfo",
      imageUrl: "/images/AK/J9.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Jazeera Airways</h1>
            <h2>Tashkil etilish va tarix</h2>
            <p>Jazeera Airways — Kuvaytning byudjetli aviakompaniyasi bo'lib, 2004 yilda tashkil etilgan. Kompaniya, Asiyo, Yaqin Sharq va Yevropa bo'ylab muntazam va charterni reyslar bilan qulay va arzon parvozlar taqdim etadi.</p>
            <h3>Jazeera Airways floti</h3>
            <p>Aviakompaniya yo'lovchilarning xavfsizligi va qulayligini ta'minlash uchun zamonaviy samolyotlarni ishlatadi:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Son (taxminan)</th>
                  <th>Maxsus xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~10</td>
                  <td>Yaqin va o'rta masofali yo'nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A320neo</td>
                  <td>~5</td>
                  <td>Yo'qotishlar kamaytirilgan yonilg'i sarfi</td>
                </tr>
              </tbody>
            </table>
            <h3>Reyslar jadvali</h3>
            <p>Jazeera Airways bir nechta mamlakatlarga, jumladan O'zbekistonga reyslar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Kuvayt – Toshkent (TAS): </b>haftada 1-2 marta.
              </li>
            </ul>
            <p>Aktual jadvalni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy saytida tekshirib ko'ring.</p>
            <h3>Xulosa</h3>
            <p>Jazeera Airways — asosiy yo'nalishlar bo'yicha arzon va qulay parvozlar. Eng yaxshi narxlarda aviakirishlarni <a href="https://biletbor.me">Biletbor.me</a> orqali bron qiling.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Kuvayt</p> 
            <h4>Markaz: </h4> 
            <p>Kuvayt shahri</p> 
            <h4>Asosiy xab: </h4>   
            <p>Kuvayt xalqaro aeroporti (KWI)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.jazeeraairways.com" target="_blank" rel="noreferrer">www.jazeeraairways.com</a>
          `,
        },
      ],
    },
    mahanAir: {
      pageKey: "mahanAirInfo",
      imageUrl: "/images/AK/W5.png",
      generalInfo: [
        {
          content: `<article>
                <h1>Mahan Air</h1>
                <h2>Asos solingan sana va tarixi</h2>
                <p>Mahan Air — 1991-yilda tashkil etilgan xususiy Eron aviakompaniyasi. Kompaniya ichki va xalqaro reyslarni amalga oshirib, yo‘lovchilarga yuqori darajadagi qulaylik va keng yo‘nalishlar tanlovini taklif qiladi.</p>
                <h3>Mahan Air samolyot parki</h3>
                <p>Aviakompaniya uzoq va o‘rta masofali parvozlar uchun turli xil samolyotlardan foydalanadi:</p>
                <table>
                  <thead>
                    <tr>
                      <th>Samolyot turi</th>
                      <th>Taxminiy soni</th>
                      <th>Xususiyatlari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airbus A310</td>
                      <td>~5</td>
                      <td>Uzoq masofali reyslar</td>
                    </tr>
                    <tr>
                      <td>Airbus A300</td>
                      <td>~10</td>
                      <td>O‘rta masofali reyslar</td>
                    </tr>
                    <tr>
                      <td>Airbus A340</td>
                      <td>~7</td>
                      <td>Xalqaro uzoq masofali yo‘nalishlar</td>
                    </tr>
                    <tr>
                      <td>BAe 146</td>
                      <td>~5</td>
                      <td>Mahalliy va mintaqaviy reyslar</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Parvoz jadvali</h3>
                <p>Mahan Air bir nechta davlatlarga, jumladan O‘zbekistonga ham parvozlarni amalga oshiradi:</p>
                <ul>
                  <li>
                    <b>Tehron – Toshkent (TAS): </b> haftasiga 1-2 marta.
                  </li>
                </ul>
                <p>Eng so‘nggi parvoz jadvali va chiptalar haqida ma’lumotni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki aviakompaniyaning rasmiy veb-saytidan bilib olishingiz mumkin.</p>
                <h3>Xulosa</h3>
                <p>Mahan Air — Eronning eng yirik aviakompaniyalaridan biri bo‘lib, yuqori sifatli xizmatlar va keng yo‘nalishlar tanlovini taqdim etadi. Eng yaxshi narxlardagi aviachiptalarni <a href="https://biletbor.me">Biletbor.me</a> saytidan band qiling.</p>
              </article>`,
        },
      ],
      origin: [
        {
          content: `
                <h4>Davlat: </h4>
                <p>Eron</p> 
                <h4>Bosh ofis: </h4> 
                <p>Tehron</p> 
                <h4>Asosiy havo bandargohi: </h4>   
                <p>Imom Xumayniy xalqaro aeroporti (IKA)</p>
                <h4>Veb-sayt:</h4>
                <a href="https://www.mahan.aero" target="_blank" rel="noreferrer">www.mahan.aero</a>
              `,
        },
      ],
    },
    irAero: {
      pageKey: "irAeroInfo",
      imageUrl: "/images/AK/IO.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>IR AERO</h1>
            <h2>Asos solinish va tarix</h2>
            <p>IR AERO — Rossiyaning aviakompaniyasi bo'lib, 2011-yilda tashkil etilgan. Aviakompaniya mintaqaviy reyslarni amalga oshirish va Rossiyaning kichik va o'rta shaharlari bilan aloqalarni ta'minlashga yo'naltirilgan. Birinchi tijorat reysi 2011-yilda amalga oshirilgan va o'sha vaqtdan buyon aviakompaniya mamlakat bo'ylab yo'nalish tarmog'ini kengaytirib kelmoqda.</p>
            <h3>IR AERO floti</h3>
            <p>Aviakompaniya zamonaviy havo kemalariga ega:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>Maxsus xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAAB 340</td>
                  <td>5</td>
                  <td>34 nafar yo'lovchiga mo'ljallangan, qisqa va mintaqaviy yo'nalishlar uchun ideal</td>
                </tr>
                <tr>
                  <td>Antonov An-24</td>
                  <td>3</td>
                  <td>40 nafar yo'lovchiga mo'ljallangan, kichik aeroportlar va uzoq hududlar uchun mos</td>
                </tr>
              </tbody>
            </table>
            <h3>Yo'nalish tarmog'i</h3>
            <p>IR AERO Rossiyaning turli shaharlari o'rtasida reyslar amalga oshiradi, jumladan:</p>
            <ul>
              <li><b>Москва – Сочи:</b> har hafta bir necha marta reyslar.</li>
              <li><b>Мурманск – Архангельск:</b> bu yo'nalishlarda muntazam reyslar.</li>
              <li><b>Владивосток – Хабаровск:</b> 2023-yildan boshlab muntazam reyslar.</li>
            </ul>
            <p>Yangilangan jadval va chiptalarni bron qilish uchun aviakompaniyaning rasmiy saytiga tashrif buyurishingiz mumkin.</p>
            <h3>Xulosa</h3>
            <p>IR AERO Rossiya bo'ylab qulay va ishonchli uchishlarni taqdim etadi, mintaqaviy yo'nalishlar va kichik aeroportlarga xizmat ko'rsatish bo'yicha ixtisoslashgan.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat:</h4>
            <p>Rossiya</p>
            <h4>Shaharda bosh qarorgoh:</h4>
            <p>Moskva</p>
            <h4>Asosiy hub:</h4>
            <p>Domodedovo xalqaro aeroporti (DME)</p>
            <h4>Vebsayt:</h4>
            <a href="https://www.iraero.ru" target="_blank" rel="noreferrer">iraero.ru</a>
          `,
        },
      ],
    },
    indigoAirlines: {
      pageKey: "indigoInfo_uz",
      imageUrl: "/images/AK/6E.png",
      generalInfo: [
        {
          content: `<article>
            <h1>IndiGo Airlines</h1>
            <h2>Asos solingan sana va tarixi</h2>
            <p>IndiGo — Hindistonning eng yirik aviakompaniyasi bo'lib, 2006 yilda tashkil etilgan. 
            Ichki va xalqaro parvozlarni amalga oshiradi, arzon va qulay uchish imkoniyatlarini taklif etadi.</p>
            <h3>Flot</h3>
            <p>IndiGo Airlines o'z parkida Airbus samolyotlarini ishlatadi.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Son (taxminan)</th>
                  <th>Xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~180</td>
                  <td>Qisqa va o'rta masofali parvozlar</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~80</td>
                  <td>Kengaytirilgan yo'lovchi sig'imi</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>~40</td>
                  <td>Hududiy parvozlar</td>
                </tr>
              </tbody>
            </table>
            <h3>Marshrutlar va parvozlar</h3>
            <p>IndiGo Hindistonda ichki parvozlarni amalga oshiradi, shuningdek Osiyo, Yaqin Sharq va Yevropaga xalqaro parvozlar bajaradi.</p>
            <h3>Qisqacha xulosa</h3>
            <p>IndiGo Airlines — Hindistonning etakchi aviakompaniyasi bo'lib, ichki va xalqaro parvozlar uchun arzon va qulay uchishlarni taklif etadi.</p>
            </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Hindiston</p> 
            <h4>Markaz: </h4> 
            <p>Gurgaon</p> 
            <h4>Asosiy hub: </h4>   
            <p>Indira Gandi nomidagi Xalqaro aeroporti, Delh</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.goindigo.in" target="_blank" rel="noreferrer">www.goindigo.in</a>
          `,
        },
      ],
    },
  
  alrosaAirlines: {
    pageKey: "alrosaInfo_uz",
    imageUrl: "/images/AK/6R.png",
    generalInfo: [
      {
        content: `<article>
          <h1>Alrosa Airlines</h1>
          <h2>Таъсис этилган йили ва тарих</h2>
          <p>Alrosa Airlines Россиянинг Якутия ҳудудида жойлашган авиакомпания бўлиб, 1995 йилда ташкил этилган. 
          Асосан ички рейслар ва айрим халқаро йўналишлар бўйича парвозлар амалга оширади.</p>
          <h3>Флоти</h3>
          <p>Alrosa Airlines ўз флотида Boeing ва совет даврида ишлаб чиқарилган самолётлардан фойдаланади.</p>
          <table>
            <thead>
              <tr>
                <th>Самолёт тури</th>
                <th>Миқдори (тахминан)</th>
                <th>Хусусиятлари</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boeing 737</td>
                <td>~5</td>
                <td>Ички ва қисқа масофали халқаро рейслар</td>
              </tr>
              <tr>
                <td>Tupolev Tu-154</td>
                <td>~3</td>
                <td>Тарихий моделлардан бири, айрим йўналишларда қўлланилади</td>
              </tr>
            </tbody>
          </table>
          <h3>Маршрутлар ва рейслар</h3>
          <p>Alrosa Airlines асосан Россия бўйлаб ва Марказий Осиёнинг айрим шаҳарларига парвоз қилади.</p>
          <h3>Қисқа хулоса</h3>
          <p>Alrosa Airlines – Якутия марказланган авиакомпания бўлиб, Россия ва қўшни мамлакатлар бўйлаб парвозлар амалга оширади.</p>
          </article>`,
      },
    ],
    origin: [
      {
        content: `
          <h4>Давлат: </h4>
          <p>Россия</p> 
          <h4>Бош қароргоҳ: </h4> 
          <p>Мирний</p> 
          <h4>Асосий хаб: </h4>   
          <p>Мирний аэропорти</p>
          <h4>Веб-сайт:</h4>
          <a href="https://www.alrosa.aero" target="_blank" rel="noreferrer">www.alrosa.aero</a>
        `,
      },
    ],
  },
  turkmenistanAirlines: {
    pageKey: "turkmenistanInfo_uz",
    imageUrl: "/images/AK/T5.png",
    generalInfo: [
      {
        content: `<article>
          <h1>Turkmenistan Airlines</h1>
          <h2>Таъсис этилган йили ва тарих</h2>
          <p>Turkmenistan Airlines (Türkmenhowaýollary) 1992 йилда ташкил этилган ва Туркманистоннинг миллий авиакомпаниясидир. 
          Халқаро ва ички йўналишлар бўйича парвозларни амалга оширади.</p>
          <h3>Флоти</h3>
          <p>Turkmenistan Airlines ўз флотида Boeing самолётларидан фойдаланади.</p>
          <table>
            <thead>
              <tr>
                <th>Самолёт тури</th>
                <th>Миқдори (тахминан)</th>
                <th>Хусусиятлари</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boeing 737</td>
                <td>~10</td>
                <td>Қисқа ва ўрта масофаларда парвоз қилади</td>
              </tr>
              <tr>
                <td>Boeing 777</td>
                <td>~4</td>
                <td>Халқаро рейслар учун</td>
              </tr>
            </tbody>
          </table>
          <h3>Маршрутлар ва рейслар</h3>
          <p>Turkmenistan Airlines Ашхободдан Европа, Осиё ва МДҲ мамлакатларига парвозлар амалга оширади.</p>
          <h3>Қисқа хулоса</h3>
          <p>Turkmenistan Airlines — Туркманистоннинг асосий авиакомпанияси бўлиб, ички ва халқаро рейслар қўллайди.</p>
          </article>`,
      },
    ],
    origin: [
      {
        content: `
          <h4>Давлат: </h4>
          <p>Туркманистон</p> 
          <h4>Бош қароргоҳ: </h4> 
          <p>Ашхобод</p> 
          <h4>Асосий хаб: </h4>   
          <p>Ашхобод халқаро аэропорти</p>
          <h4>Веб-сайт:</h4>
          <a href="https://www.turkmenistanairlines.tm" target="_blank" rel="noreferrer">www.turkmenistanairlines.tm</a>
        `,
      },
    ],
  },
  saudiaAirlines: {
    pageKey: "saudiaInfo_ru",
    imageUrl: "/images/AK/SV.png",
    generalInfo: [
      {
        content: `<article>
          <h1>Saudia Airlines</h1>
          <h2>Дата основания и история</h2>
          <p>Saudia Airlines была основана в 1945 году и является национальной авиакомпанией Саудовской Аравии. 
          Она предлагает широкий выбор международных и внутренних рейсов.</p>
          <h3>Флот</h3>
          <p>Saudia использует современные самолёты Airbus и Boeing.</p>
          <table>
            <thead>
              <tr>
                <th>Тип самолёта</th>
                <th>Количество (примерно)</th>
                <th>Особенности</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Airbus A320</td>
                <td>~60</td>
                <td>Короткие и средние расстояния</td>
              </tr>
              <tr>
                <td>Boeing 777</td>
                <td>~30</td>
                <td>Международные маршруты</td>
              </tr>
              <tr>
                <td>Boeing 787</td>
                <td>~15</td>
                <td>Комфортные условия для пассажиров</td>
              </tr>
            </tbody>
          </table>
          <h3>Маршруты и рейсы</h3>
          <p>Saudia Airlines выполняет прямые рейсы из Ташкента в Джидду и Эр-Рияд.</p>
          <h3>Краткий вывод</h3>
          <p>Saudia Airlines — это современный авиапарк и высокий уровень обслуживания.</p>
          </article>`,
      },
    ],
    origin: [
      {
        content: `
          <h4>Страна: </h4>
          <p>Саудовская Аравия</p> 
          <h4>Штаб-квартира: </h4> 
          <p>Джидда</p> 
          <h4>Основной хаб: </h4>   
          <p>Международный аэропорт имени короля Абдул-Азиза</p>
          <h4>Вебсайт:</h4>
          <a href="https://www.saudia.com" target="_blank" rel="noreferrer">www.saudia.com</a>
        `,
      },
    ],
  },
    aeroflot: {
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aeroflot</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Aeroflot (PAO «Aeroflot — Rossiya havo yo‘llari») dunyodagi eng eski aviakompaniyalardan biri bo‘lib, 1923-yilda asos solingan. Bu davr mobaynida u sobiq ittifoq davridagi monopol aviakompaniyadan tortib, hozirda butun dunyoga parvoz qiladigan yirik xalqaro tashuvchiga aylandi. Aviabilet uzbekistan yo‘nalishlarida ham ko‘p qulay reyslar mavjud.</p>
            <h3>Aeroflot flotiga qisqacha nazar</h3>
            <p>Aeroflot floti Airbus A320/321 kabi qisqa va o‘rta masofali samolyotlar, shuningdek, Boeing 777 va Airbus A330 kabi uzoq masofali samolyotlardan iborat. Zamonaviy laynerlar parvozni yanada qulay qiladi, ayniqsa aviabilet narxlari Toshkent yoki boshqa shaharlardan izlayotgan bo‘lsangiz.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>O'ziga xos xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Uzoq masofali reyslar, oshirilgan qulaylik</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Qit’alararo yo‘nalishlar, keng salonlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Aeroflot quyidagi shaharlar orqali Moskva (Sheremetyevo) yo‘nalishida parvozlar amalga oshiradi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS): </b>odatda har kuni (haftasiga 7 kun).
              </li>
              <li>
                <b>Samarqand (SKD): </b>haftasiga 2–3 marta (masalan, seshanba, payshanba, shanba).
              </li>
              <li>
                <b>Buxoro (BHK): </b>haftada 1–2 marta (ko‘pincha chorshanba va yakshanba).
              </li>
            </ul>
            <p>Aniq sana va vaqtlarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a> yoki rasmiy saytdan toping (Moskva aviabilet narxlari va boshqalar).</p>
            <h3>Xulosa</h3>
            <p>Aeroflot uzoq tarix, keng ko‘lamli yo‘nalishlar va zamonaviy samolyotlar bilan mashhur. Agar sizga aviabilet дешевые yoki moskva aviabilet narxlari kerak bo‘lsa, biletbor.me yoki aviakompaniyaning rasmiy sayti orqali izlashni tavsiya etamiz.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xablar: </h4>   
            <p>Sheremetyevo aeroporti (B/C terminallari), xalqaro va mintaqaviy reyslarning ko‘pchiligi shu yerdan amalga oshiriladi.</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
    airastana: {
      name: "airastana",
      pageKey: "airastanaInfo",
      imageUrl: "/images/AK/KC/KC.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Astana</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air Astana — Qozog‘istonning milliy aviakompaniyasi bo‘lib, 2001-yilda asos solingan. Kompaniya ishga tushirilgan paytdan boshlab xizmatlar sifatini yaxshilab, aviabilet дешевые va premium tariflarini taqdim etib keladi.</p>
            <h3>Flot</h3>
            <p>Air Astana Airbus (A320, A321, A321LR/neo), Boeing (757, 767) va Embraer (E190-E2) samolyotlaridan foydalanadi. Bu turdagi zamonaviy laynerlar yo‘lovchilarga qulay parvoz imkonini beradi, xususan aviabilet narxlari borasida tanlov kengligi yuqori.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320 / A321 / A321LR</td>
                  <td>Qisqa va o‘rta masofalarga mo‘ljallangan zamonaviy laynerlar</td>
                </tr>
                <tr>
                  <td>Boeing 757 / 767</td>
                  <td>Uzoq masofali, yuqori ishonchlilikka ega samolyotlar</td>
                </tr>
                <tr>
                  <td>Embraer E190-E2</td>
                  <td>Qisqa masofaga uchish uchun qulay regiоnal samolyotlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air Astana O‘zbekistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslar taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Olmaota yoki Astana (haftada bir necha marta)
              </li>
              <li>
                <b>Samarqand (SKD):</b> mavsumiy reyslar (aniq ma’lumotni biletbor.me saytidan bilib oling)
              </li>
            </ul>
            <p>Ushbu reyslardan keyin Moskvaga (aviabilet moskva), Sankt-Peterburgga (aviabilet sankt peterburg) yoki boshqa yo‘nalishlarga uchishni <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a> saytida davom ettirish mumkin.</p>
            <h3>Xulosa</h3>
            <p>Air Astana — Qozog‘istonning zamonaviy va jadal rivojlanayotgan aviakompaniyasi bo‘lib, O‘zbekiston hamda dunyoning boshqa ko‘plab shaharlariga qulay reyslar taqdim etadi. Agar siz qulaylik va maqbul aviabilet narxlarini qidirsangiz, Air Astana reyslariga e’tibor berishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Страна: </h4>
            <p>Казахстан</p> 
            <h4>Штаб-квартира: </h4> 
            <p>Алматы</p> 
            <h4>Основной хаб: </h4>   
            <p>Международные аэропорты Алматы и Астаны
              Air Astana осуществляет рейсы в страны СНГ, Азии, Ближнего Востока и Европы, давая пассажирам возможность сравнивать aviabilet moskva и aviabilet sankt peterburg с другими направлениями
            </p>
            <h4>Вебсайт:</h4>
            <a href="https://www.airastana.com" target="_blank" rel="noreferrer">www.airastana.com</a>
          `,
        },
      ],
    },
    airchina: {
      name: "airchina",
      pageKey: "airchinaInfo",
      imageUrl: "/images/AK/CA.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air China</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air China — Xitoyning milliy aviakompaniyasi bo‘lib, 1988-yildan boshlab bir nechta mintaqaviy kompaniyalarni birlashtirish natijasida shakllangan. Hozirda Air China Osiyodagi eng yirik aviakompaniyalardan biri sifatida aviabilet дешевые dan tortib premium xizmatlargacha keng turdagi imkoniyatlar taqdim etadi.</p>
            <h3>Air China floti</h3>
            <p>Air China turli samolyot turlaridan foydalanadi: Boeing 737, 777, 787 hamda Airbus A320, A330, A350. Ushbu park uzoq masofali va mahalliy reyslarni yuqori darajadagi xavfsizlik va xizmat sifati bilan amalga oshirishga imkon beradi. Aviabilet narxlari har xil bo‘lib, ekonomdan tortib biznes yoki birinchi klassgacha tanlov mavjud.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737, 777, 787</td>
                  <td>O‘rta va uzoq masofali reyslar, turli qulaylik darajalari</td>
                </tr>
                <tr>
                  <td>Airbus A320, A330, A350</td>
                  <td>Ichki va xalqaro yo‘nalishlar uchun keng qamrovli zamonaviy laynerlar</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air China Uzbеkistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslarni taklif etishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> ko‘pincha Pekin yoki boshqa xab orqali (to‘liq ma’lumotni <a href="https://biletbor.me">biletbor.me</a> da toping).</a>.
              </li>
              <li>
                <b>Boshqa shaharlar: </b>mavsumiy yoki charter reyslar bo‘lishi ehtimoli bor.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Air China — Osiyodagi yirik aviakompaniya bo‘lib, O‘zbekiston va Xitoy o‘rtasida qulay yo‘nalishlar taqdim etadi. Agar siz uzoq masofaga qulay parvoz yoki aviabilet narxlari bo‘yicha raqobatbardosh taklif qidirsangiz, Air China reyslari sizga mos kelishi mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Xitoy</p> 
            <h4>Bosh ofis: </h4> 
            <p>Pekin</p> 
            <h4>Asosiy xab: </h4>   
            <p>Pekin poytaxt xalqaro aeroporti (PEK)
               Shuningdek, Chendu, Shanxay va boshqa shaharlardagi aeroportlar qo‘shimcha xab sifatida foydalaniladi. Yo‘lovchilar aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlarni ham qulay ulanishlar bilan tanlashlari mumkin.
            </p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.aircairo.com" target="_blank" rel="noreferrer">www.aircairo.com</a>
          `,
        },
      ],
    },
    airmanas: {
      name: "airmanas",
      pageKey: "airmanasInfo",
      imageUrl: "/images/AK/ZM.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Air Manas</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Air Manas — Qirg‘iziston aviakompaniyasi bo‘lib, 2006-yilda faoliyatini boshlagan. Dastlab ichki va qo‘shni davlatlarga parvoz qilgan kompaniya, hozirda yo‘lovchilarga aviabilet дешевые va qulay shart-sharoitlar taklif qiladi.</p>
            <h3>Air Manas floti</h3>
            <p>Air Manas odatda Boeing 737 turidagi samolyotlardan foydalanadi. Ushbu laynerlar qisqa va o‘rta masofaga mo‘ljallangan bo‘lib, aviabilet narxlari bo‘yicha hamyonbop parvozlarni amalga oshirishga imkon beradi.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Air Manas O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Bishkek yo‘nalishida reyslar (mavsumga va talabga qarab o‘zgarishi mumkin)..
              </li>
              <li>
                <b>Boshqa shaharlar: </b>ba’zan mavsumiy yoki charter reyslar ham bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Air Manas — Qirg‘iziston va O‘zbekiston o‘rtasida parvozni rejalashtirayotgan yoki iqtisodiy jihatdan aviabilet narxlari qulay bo‘lgan reys qidirayotganlar uchun yaxshi tanlov. biletbor.me orqali tariflarni solishtirish mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Qirg‘iziston</p> 
            <h4>Bosh ofis: </h4> 
            <p>Bishkek</p> 
            <h4>Asosiy xab: </h4>   
            <p>Manas xalqaro aeroporti (FRU)
               Air Manas MDH davlatlari va mintaqadagi boshqa davlatlarga parvozlar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlarda ham qulay ulanish imkonini beradi.
            </p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.airmanas.com" target="_blank" rel="noreferrer">www.airmanas.com</a>
          `,
        },
      ],
    },
    asianaAirlines: {
      name: "asianaAirlines",
      pageKey: "asianaAirlinesInfo",
      imageUrl: "/images/AK/OZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Asiana Airlines</h1>
            <h2>Asiana Airlines tashkil topgan yili va qisqacha tarix</h2>
            <p>Asiana Airlines — 1988-yilda asos solingan Koreya Respublikasi (Janubiy Koreya)ning yirik aviakompaniyalaridan biri. Kompaniya yuqori darajadagi xizmat va xavfsizlikni ta’minlagani bois e’tibor qozongan, shu jumladan, aviabilet дешевые va turli toifadagi tariflarni O‘zbekiston yo‘nalishiga ham taklif etadi.</p>
            <h3>Flot</h3>
            <p>Asiana Airlines Airbus (A320, A350) va Boeing (B747, B777) samolyotlaridan foydalanadi. Bu turli yo‘nalishlar — qisqa masofadan tortib uzoq masofagacha — uchun qulay. Aviabilet narxlariga ko‘ra, ekonom, biznes yoki birinchi klassni tanlash imkoniyati mavjud.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Asiana Airlines O‘zbekistondan to‘g‘ridan-to‘g‘ri yoki ulanishli reyslarni taklif qilishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> ko‘pincha Seul (Incheon) orqali (mavsumga qarab).
              </li>
              <li>
                <b>Boshqa shaharlar: </b>mavsumiy charter reyslar bo‘lishi ehtimoli bor.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Asiana Airlines Koreya bozoridagi eng mashhur aviakompaniyalardan biri bo‘lib, O‘zbekiston yo‘lovchilari uchun ham qulay parvozlar taklif qiladi. Agar siz uzoq masofali yoki aviabilet narxlari bo‘yicha tanlovni qidirsangiz, Asiana Airlines’ning rasmiy sayti yoki <a href="https://biletbor.me">biletbor.me</a> sizga yordam beradi.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Koreya Respublikasi</p> 
            <h4>Bosh ofis: </h4> 
            <p>Seul</p> 
            <h4>Asosiy xab: </h4>   
            <p>Incheon xalqaro aeroporti (ICN)
               Asiana Airlines Seul Gimpo aeroporti va boshqa shaharlardan ham reyslar amalga oshiradi. Bu orqali aviabilet moskva yoki aviabilet sankt peterburg yo‘nalishlarida qulay ulanishlar topish mumkin.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.flyasiana.com" target="_blank" rel="noreferrer">www.flyasiana.com</a>
          `,
        },
      ],
    },
    aviaTraffic: {
      name: "aviaTraffic",
      pageKey: "aviaTrafficInfo",
      imageUrl: "/images/AK/AT.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Avia Traffic Company</h1>
            <h2>Avia Traffic Company tashkil topgan yili va qisqacha tarix</h2>
            <p>Avia Traffic Company — Qirg‘iziston aviakompaniyasi bo‘lib, 2003-yildan buyon faoliyat yuritadi. U bosqichma-bosqich o‘z yo‘nalishlarini kengaytirib, aviabilet дешевые va turli qulay xizmatlarni taqdim etishga intiladi.</p>
            <h3>Flot</h3>
            <p>Avia Traffic Company floti odatda Boeing 737 va Airbus A320 samolyotlaridan iborat:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofalardagi yo‘nalishlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass, qulay salon</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Avia Traffic Company O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Bishkek (va qaytish) yo‘nalishida reyslar, mavsumga qarab turli chastota.
              </li>
              <li>
                <b>Boshqa shaharlar: </b>Ba’zan charter yoki mavsumiy reyslar mavjud bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Avia Traffic Company — Qirg‘iziston va O‘zbekiston o‘rtasida parvoz qiluvchi qulay aviakompaniya. Agar siz tejamkor aviabilet narxlari va hamyonbop tariflarni qidirsangiz, <a href="https://biletbor.me">biletbor.me</a> orqali ushbu kompaniya reyslarini ko‘rib chiqishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Qirg‘iziston</p> 
            <h4>Bosh ofis: </h4> 
            <p>Bishkek</p> 
            <h4>Asosiy xab: </h4>   
            <p>Manas xalqaro aeroporti (FRU)
               Kompaniya MDH davlatlariga muntazam va charter reyslar amalga oshiradi. Kelajakda yo‘nalishlar sonini ko‘paytirish rejalari bor, bu esa aviabilet moskva yoki aviabilet sankt peterburg yo‘nalishlarini ham qulay ulanish bilan ta’minlashi mumkin.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.aero.kg/" target="_blank" rel="noreferrer">www.aviatraffic.com</a>
          `,
        },
      ],
    },
    azal: {
      name: "azal",
      pageKey: "azalInfo",
      imageUrl: "/images/AK/J2.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azal Havo Yo'llari</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>AZAL (Azerbaijan Airlines) — Ozarbayjonning milliy aviakompaniyasi bo‘lib, 1992-yilda davlat aviatsiyasi bazasida tashkil etilgan. Shundan beri kompaniya aviabilet дешевые va qulay tariflarni taklif qilib, bir qator xalqaro yo‘nalishlarda faoliyat yuritadi.</p>
            <h3>Flot</h3>
            <p>AZAL floti asosan Airbus (A319, A320, A321) va Boeing (757, 767, 787) samolyotlaridan iborat. Bu turli masofalarga mo‘ljallangan reyslarni amalga oshirishga imkon beradi va aviabilet narxlarini turlicha shakllantiradi.</p>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>AZAL O‘zbekistondan:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Boku yo‘nalishi (haftadagi qatnovlar mavsum va talabga qarab o‘zgarishi mumkin).
              </li>
              <li>
                <b>Boshqa shaharlar:</b> ba’zi mavsumiy yoki charter reyslar bo‘lishi mumkin.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>AZAL (Azerbaijan Airlines) O‘zbekiston va Ozarbayjonni bog‘lovchi qulay aviakompaniya. Boku orqali uzoq masofalarga uchish uchun ham qulay ulanishlarni taqdim etadi. Eng yaxshi aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> sayti orqali qidirishingiz mumkin.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Ozarbayjon</p> 
            <h4>Bosh ofis: </h4> 
            <p>Boku</p> 
            <h4>Asosiy xab: </h4>   
            <p>Geydar Aliyev xalqaro aeroporti (GYD)
               AZAL Yevropa, Osiyo va MDH davlatlariga reyslar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlar bo‘yicha ham qulay ulanishni ta’minlaydi, xususan O‘zbekistondan uchmoqchi bo‘lganlar uchun.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
          `,
        },
      ],
    },
    azimuth: {
      name: "azimuth",
      pageKey: "azimuthInfo",
      imageUrl: "/images/AK/A4.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azimut</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Azimut — 1993-yilda tashkil etilgan Rossiya aviakompaniyasi. Tashkil topganidan buyon kompaniya mintaqaviy parvozlar bilan shug‘ullanadi, yo‘lovchilarga aviabilet дешевые va qulay sharoitlarni taqdim etadi. Azimuth Rossiya va qo‘shni davlatlarga yo‘nalishlar bo‘yicha keng xizmat ko‘rsatadi.</p>
            <h3>Flot</h3>
            <p>Azimut floti zamonaviy Embraer ERJ-145 va ATR 72 samolyotlaridan iborat. Ushbu samolyotlar qisqa va o‘rta masofadagi yo‘nalishlar uchun juda mos keladi, yo‘lovchilarga qulay va xavfsiz parvozlarni ta’minlaydi. Aviabilet narxlarini tanlashda ekonom va biznes klasslarini topish mumkin.</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xizmat xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Embraer ERJ-145</td>
                  <td>Qisqa va o‘rta masofadagi yo‘nalishlar uchun ideal</td>
                </tr>
                <tr>
                  <td>ATR 72</td>
                  <td>Qisqa parvozlar uchun iqtisodiy samolyot</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Azimut O‘zbekistondan quyidagi shaharlar orqali reyslarni taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Domodedovo (Moskva) yo‘nalishi bilan parvozlar (mavsumga qarab chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Moskva va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <h3>Xulosa</h3>
            <p>Azimut — Rossiya aviakompaniyasi bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Rossiyaga yoki MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Azimutning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Ozarbayjon</p> 
            <h4>Bosh ofis: </h4> 
            <p>Boku</p> 
            <h4>Asosiy xab: </h4>   
            <p>Geydar Aliyev xalqaro aeroporti (GYD)
               AZAL Yevropa, Osiyo va MDH davlatlariga reyslar amalga oshiradi. Bu aviabilet moskva yoki aviabilet sankt peterburg kabi yo‘nalishlar bo‘yicha ham qulay ulanishni ta’minlaydi, xususan O‘zbekistondan uchmoqchi bo‘lganlar uchun.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://www.azal.az/" target="_blank" rel="noreferrer">www.azal.az</a>
          `,
        },
      ],
    },
    azurair: {
      name: "azurair",
      pageKey: "azurairInfo",
      imageUrl: "/images/AK/ZF.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Azur Air</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Azur Air — 2006-yilda tashkil etilgan Rossiya aviakompaniyasi. Tashkil topganidan buyon kompaniya turist yo‘nalishlaridagi charter reyslarga ixtisoslashgan. So‘nggi yillarda Azur Air o‘z yo‘nalishlar tarmog‘ini kengaytirdi, muntazam reyslar va yangi yo‘nalishlarni taqdim etib, yo‘lovchilarga aviabilet дешевые va qulay parvoz sharoitlarini taqdim etish imkoniyatini yaratdi.</p>
            <h3>Flot</h3>
            <p>Azur Air floti asosan zamonaviy Airbus A320 va Boeing 737 samolyotlaridan iborat. Ushbu samolyotlar qisqa va o‘rta masofadagi yo‘nalishlar uchun komfort va xavfsizlikni ta’minlaydi. Flotning xilma-xilligi Azur Air’ga turli toifadagi aviabilet narxlarini taklif etishga imkon beradi (ekonom, biznes).</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass uchun qulay sharoitlar</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar uchun ishonchlilik va xavfsizlik</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Azur Air O‘zbekistondan quyidagi shaharlar orqali reyslarni taklif etadi:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Domodedovo (Moskva) yo‘nalishi bilan parvozlar (mavsumga bog‘liq chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Moskva va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <p>Akkurat jadval va aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> yoki aviakompaniya rasmiy saytidan tekshirish tavsiya etiladi.</p>
            <h3>Xulosa</h3>
            <p>Azur Air — ishonchli Rossiya aviakompaniyasi bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Rossiyaga yoki MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Azur Air’ning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xab: </h4>   
            <p>Domodedovo xalqaro aeroporti (DME)
               Azur Air Moskva va Rossiyaning boshqa yirik shaharlardan turli turist yo‘nalishlariga reyslar amalga oshiradi. Bu yo‘lovchilarga aviabilet moskva, aviabilet sankt peterburg va boshqa yo‘nalishlarda qulay ulanishlar bilan tanlash imkonini beradi.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://azur-airlines.com/" target="_blank" rel="noreferrer">www.azur-airlines.com</a>
          `,
        },
      ],
    },
    belavia: {
      name: "belavia",
      pageKey: "belaviaInfo",
      imageUrl: "/images/AK/B2.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Belavia</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Belavia — Belarus Respublikasining milliy aviakompaniyasi bo‘lib, 1996-yilda sobiq Sovet aviakompaniyasi Aeroflot asosida tashkil etilgan. Tashkil topganidan buyon Belavia faol ravishda rivojlanib, yo‘nalishlar tarmog‘ini kengaytirib, yo‘lovchilarga yuqori sifatli xizmat ko‘rsatishni maqsad qilgan. Kompaniya yo‘lovchilarga aviabilet дешевые va qulay parvoz sharoitlarini taqdim etishga intiladi.</p>
            <h3>Flot</h3>
            <p>Belavia floti zamonaviy Airbus (A320, A330) va Boeing (737, 767) samolyotlaridan iborat. Ushbu samolyotlar qisqa va uzoq masofalardagi yo‘nalishlar uchun yuqori darajadagi komfort va xavfsizlikni ta’minlaydi. Flotning xilma-xilligi Belavia’ga turli toifadagi aviabilet narxlarini taklif etishga imkon beradi (ekonom, biznes).</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot</th>
                  <th>Xususiyatlari</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>Ekonom va biznes-klass uchun qulay sharoitlar</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>Qisqa va o‘rta masofali yo‘nalishlar uchun ishonchlilik va xavfsizlik</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Belavia O‘zbekistondan quyidagi shaharlar orqali reyslarni amalga oshirishi mumkin:</p>
            <ul>
              <li>
                <b>Toshkent (TAS):</b> Minsk yo‘nalishi bilan parvozlar (mavsumga qarab chastota o‘zgarishi mumkin).
              </li>
              <li>
                <b>Samarqand (SKD):</b> Minsk va boshqa yo‘nalishlarga mavsumiy reyslar.
              </li>
            </ul>
            <p>Aniq jadval va aviabilet narxlarini <a href="https://biletbor.me">biletbor.me</a> yoki aviakompaniya rasmiy saytidan tekshirish tavsiya etiladi.</p>
            <h3>Xulosa</h3>
            <p>Belavia — ishonchli aviakompaniya bo‘lib, yo‘lovchilarga arzon aviabilet narxlari va qulay sharoitlarni taqdim etadi. Agar siz O‘zbekistonlik bo‘lsangiz va Belarusga yoki boshqa MDH davlatlariga sayohat qilmoqchi bo‘lsangiz, Belavia’ning takliflarini <a href="https://biletbor.me">biletbor.me</a> orqali solishtiring va eng mos reysni tanlang.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Belarus</p> 
            <h4>Bosh ofis: </h4> 
            <p>Minsk</p> 
            <h4>Asosiy xab: </h4>   
            <p>Minsk xalqaro aeroporti (MSQ)
               Belavia Minskdan Yevropa, Osiyo va Yaqin Sharqning ko‘plab shaharlarga reyslar amalga oshiradi, shuningdek, turistik yo‘nalishlarga charter reyslarni ham taqdim etadi. Bu yo‘lovchilarga aviabilet moskva, aviabilet sankt peterburg kabi yo‘nalishlarni qulay ulanishlar bilan tanlash imkonini beradi.
            </p>
            <h4>Vebsayt:</h4>
            <a href="https://belavia.by/" target="_blank" rel="noreferrer">www.belavia.by</a>
          `,
        },
      ],
    },
    uzairways: {
      name: "uzairways",
      pageKey: "uzairwaysInfo",
      imageUrl: "/images/AK/HY.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>O'zbekiston Havo Yo'llari</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>Uzbekistan Airways 1992 yilda tashkil etilgan bo‘lib, O‘zbekistonning milliy aviakompaniyasidir. Kompaniya keng yo‘nalish tarmog‘i va yuqori xizmat ko‘rsatish darajasi bilan mashhur.</p>
              <h2>Uzbekistan Airways samolyot parki</h2>
        <table>
            <thead>
                <tr>
                    <th>Samolyot turi</th>
                    <th>Soni</th>
                    <th>Yo‘nalishlar</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Boeing 787 Dreamliner</td>
                    <td>7</td>
                    <td>Uzoq masofali reyslar</td>
                </tr>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>10</td>
                    <td>O‘rta masofali yo‘nalishlar</td>
                </tr>
                <tr>
                    <td>Boeing 767-300</td>
                    <td>6</td>
                    <td>Xalqaro reyslar</td>
                </tr>
            </tbody>
        </table>
        
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Sankt-Peterburg:</strong> haftasiga 3 marta</li>
            <li><strong>Samarqand – Dubay:</strong> haftasiga 2 marta</li>
            <li><strong>Buxoro – Istanbul:</strong> haftasiga 2 marta</li>
        </ul>
              <h2>Xulosa</h2>
        <p>Uzbekistan Airways yo‘lovchilarga keng yo‘nalishlar tarmog‘ini va yuqori darajadagi xizmatni taklif etadi. Chipta narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>O‘zbekiston</p> 
        <h4>Bosh ofis:</h4> 
        <p>Toshkent</p> 
        <h4>Asosiy xab:</h4>   
        <p>Toshkent xalqaro aeroporti (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.uzairways.com" target="_blank" rel="noreferrer">www.uzairways.com</a>
      `,
        },
      ],
    },
    s7: {
      imageUrl: "/images/AK/S7.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>S7 Airlines</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>S7 Airlines 1992 yilda tashkil etilgan bo‘lib, Rossiyaning eng yirik xususiy aviakompaniyalaridan biridir. Kompaniya muntazam ravishda xizmat tarmog‘ini kengaytirib bormoqda.</p>
              <h2>S7 Airlines samolyot parki</h2>
              <p>S7 Airlines zamonaviy samolyotlarga ega:</p>
        <table>
            <thead>
                <tr>
                    <th>Samolyot turi</th>
                    <th>Soni</th>
                    <th>Yo‘nalishlar</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Airbus A320neo</td>
                    <td>20</td>
                    <td>O‘rta va uzoq masofali reyslar</td>
                </tr>
                <tr>
                    <td>Boeing 737-800</td>
                    <td>23</td>
                    <td>Ichki va xalqaro yo‘nalishlar</td>
                </tr>
                <tr>
                    <td>Embraer E170</td>
                    <td>17</td>
                    <td>Mintaqaviy reyslar</td>
                </tr>
            </tbody>
        </table>
        
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Novosibirsk:</strong> haftasiga 2 marta</li>
            <li><strong>Samarqand – Moskva:</strong> 1-2 marta haftasiga</li>
        </ul>
              <h2>Xulosa</h2>
        <p>S7 Airlines – O‘zbekiston va Rossiya o‘rtasidagi ishonchli havo yo‘lovchi tashuvchisi. Aviabilet narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>Rossiya</p> 
        <h4>Bosh ofis:</h4> 
        <p>Moskva</p> 
        <h4>Asosiy xab:</h4>   
        <p>Moskva (Domodedovo, DME), Novosibirsk (Tolmachëvo, OVB)</p>
        <h4>Вебсайт:</h4>
        <a href="https://www.s7.ru/" target="_blank" rel="noreferrer">www.s7.ru</a>
      `,
        },
      ],
    },
    qanotsharq: {
      name: "qanotsharq",
      pageKey: "qanotsharqInfo",
      imageUrl: "/images/AK/HH.svg",
      generalInfo: [
        {
          content: `
            <article>
              <h1>Qanot Sharq</h1>
              <h2>Tashkil topgan yili va tarixi</h2>
              <p>Qanot Sharq aviakompaniyasi 1998 yilda tashkil etilgan bo‘lib, yo‘lovchi va yuk tashish xizmatlarini ko‘rsatadi. Yillar davomida kompaniya ishonchli tashuvchi sifatida o‘z mavqeini mustahkamladi.</p>
              <h2>Qanot Sharq samolyot parki</h2>
              <p>Bugungi kunda kompaniya quyidagi samolyotlarni ekspluatatsiya qiladi:</p>
              <table>
                  <thead>
                      <tr>
                          <th>Samolyot turi</th>
                          <th>Soni</th>
                          <th>Asosiy yo‘nalishlar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>Airbus A320</td>
                          <td>2</td>
                          <td>Xalqaro va mahalliy reyslar</td>
                      </tr>
                      <tr>
                          <td>Boeing 757-200</td>
                          <td>1</td>
                          <td>Uzoq masofali yo‘nalishlar</td>
                      </tr>
                  </tbody>
              </table>
        <h2>O‘zbekistondan reyslar jadvali</h2>
        <ul>
            <li><strong>Toshkent – Moskva:</strong> har kuni</li>
            <li><strong>Toshkent – Sankt-Peterburg:</strong> haftada 4 marta</li>
            <li><strong>Toshkent – Dubay:</strong> haftada 3 marta</li>
            <li><strong>Toshkent – Istanbul:</strong> haftada 2 marta</li>
        </ul>
        <h2>Xulosa</h2>
        <p>Qanot Sharq – mashhur yo‘nalishlar bo‘ylab qulay va arzon parvozlarni taklif etadi. Aviabiletlarni onlayn band qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring!</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
        <h4>Davlat:</h4>
        <p>O‘zbekiston</p> 
        <h4>Bosh ofis:</h4> 
        <p>Toshkent</p> 
        <h4>Asosiy xab:</h4>   
        <p>Toshkent xalqaro aeroporti (TAS)</p>
        <h4>Вебсайт:</h4>
        <a href="https://qanotsharq.com/" target="_blank" rel="noreferrer">www.qanotsharq.com</a>
      `,
        },
      ],
    },
    ural: {
      pageKey: "uralInfo",
      imageUrl: "/images/AK/U6.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Ural Havo Yo'llari</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p>Ural Airlines 1943 yilda tashkil etilgan bo‘lib, dastlab «Aeroflot» tarkibida bo‘lgan. 1993 yildan boshlab mustaqil aviakompaniya sifatida faoliyat yuritmoqda. Kompaniya Rossiya, MDH, Yevropa va Osiyoga muntazam parvozlarni amalga oshiradi.</p>
            <h3>Ural Airlines samolyot parki</h3>
            <p>Ural Airlines zamonaviy samolyotlarga ega:</p>
            <table>
              <thead>
                <tr>
                  <th>Samolyot turi</th>
                  <th>Soni (taxminan)</th>
                  <th>O'ziga xos xususiyatlar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~20</td>
                  <td>o‘rta va uzoq masofali yo‘nalishlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~15</td>
                  <td>keng sig‘imli parvozlar uchun</td>
                </tr>
                <tr>
                  <td>Airbus A319</td>
                  <td>~10</td>
                  <td>qisqa masofali reyslar uchun</td>
                </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Ural Airlines O‘zbekistonning yirik shaharlari bilan bog‘lanishni ta’minlaydi:</p>
            <ul>
              <li>
                <b>Toshkent – Moskva: </b>haftasiga 3 marta.
              </li>
              <li>
                <b>Samarqand – Yekaterinburg: </b>haftasiga 2 marta.
              </li>
              <li>
                <b>Farg‘ona – Moskva: </b>1-2 marta haftasiga.
              </li>
            </ul>
            <p>Aniq sana va vaqtlarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki rasmiy saytdan toping (Moskva aviabilet narxlari va boshqalar).</p>
            <h3>Xulosa</h3>
            <p>Ural Airlines – O‘zbekiston va Rossiya o‘rtasidagi ishonchli havo yo‘lovchi tashuvchisi. Aviabilet narxlari va bron qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Yekaterinburg</p> 
            <h4>Asosiy xablar: </h4>   
            <p>Keltso xalqaro aeroporti (SVX)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.uralairlines.ru/" target="_blank" rel="noreferrer">www.uralairlines.ru</a>
          `,
        },
      ],
    },
    pobeda: {
      pageKey: "pobedaInfo",
      imageUrl: "/images/AK/DP.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Pobeda</h1>
            <h2>Tashkil topgan yili va qisqacha tarix</h2>
            <p><strong>Pobeda</strong> aviakompaniyasi 2014 yilda tashkil etilgan bo‘lib, Rossiyaning eng yirik loukosterlaridan biridir. "Aeroflot" guruhi tarkibiga kiradi va arzon parvozlar taklif etadi.</p>
            <h3>Pobeda samolyot parki</h3>
            <p>Ural Airlines zamonaviy samolyotlarga ega:</p>
            <table>
              <thead>
                  <tr>
                      <th>Samolyot turi</th>
                      <th>Soni</th>
                      <th>Asosiy yo‘nalishlar</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>Boeing 737-800</td>
                      <td>45</td>
                      <td>Rossiya, MDH, Yevropa</td>
                  </tr>
              </tbody>
            </table>
            <h3>O‘zbekistondan parvoz jadvali</h3>
            <p>Ural Airlines O‘zbekistonning yirik shaharlari bilan bog‘lanishni ta’minlaydi:</p>
            <ul>
              <li><strong>Toshkent – Moskva (VKO):</strong> har kuni</li>
              <li><strong>Samarqand – Moskva (VKO):</strong> haftada 3 marta</li>
              <li><strong>Farg‘ona – Moskva (VKO):</strong> haftada 2 marta</li>
            </ul> 
            <p>Aniq sana va vaqtlarni <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> yoki rasmiy saytdan toping (Moskva aviabilet narxlari va boshqalar).</p>
            <h3>Xulosa</h3>
            <p>Pobeda – O‘zbekistondan Moskvaga va boshqa shaharlar bo‘yicha arzon va qulay parvozlarni taklif etadi. Aviabiletlarni onlayn band qilish uchun <a href="https://biletbor.me">Biletbor.me</a> saytiga tashrif buyuring!</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Davlat: </h4>
            <p>Rossiya</p> 
            <h4>Bosh ofis: </h4> 
            <p>Moskva</p> 
            <h4>Asosiy xablar: </h4>   
            <p>Moskva Vnukovo aeroporti (VKO)</p>
            <h4>Veb-sayt:</h4>
            <a href="https://www.flypobeda.ru/" target="_blank" rel="noreferrer">www.flypobeda.ru</a>
          `,
        },
      ],
    },
    spicejet: {
      pageKey: "spicejetInfo",
      imageUrl: "/images/AK/SG.png",
      generalInfo: [
        {
          content: `<article>
          <h1>SpiceJet: Hindistonga va xalqaro arzon aviachiptalar</h1>
          <p><a href="https://biletbor.me/airlines/spicejet" target="_blank" rel="noopener noreferrer">https://biletbor.me/airlines/spicejet</a></p>

          <h2>Tashkil topgan yili va tarix</h2>
          <p>SpiceJet Hindistonning yirik arzon aviatashuvchilaridan biri bo‘lib, 2005 yilda tashkil etilgan. Aviakompaniya Hindiston bo‘ylab va xalqaro yo‘nalishlarda qulay va tejamkor parvozlar taklif qiladi.</p>
          <h2>SpiceJet aviatsiya parki</h2>
          <table>
            <thead>
              <tr>
                <th>Samolyot</th>
                <th>Soni</th>
                <th>Asosiy yo‘nalishlar</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boeing 737</td>
                <td>~50</td>
                <td>Ichki va xalqaro yo‘nalishlar</td>
              </tr>
              <tr>
                <td>Bombardier Q400</td>
                <td>~30</td>
                <td>Qisqa masofali reyslar</td>
              </tr>
            </tbody>
          </table>

          <h2>O‘zbekistondan parvoz jadvali</h2>
          <ul>
            <li><b>Toshkent – Dehli (DEL):</b> haftasiga 3 marta</li>
          </ul>
          <p>Joriy jadval va narxlarni <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a> orqali bilib oling.</p>

          <h2>Xulosa</h2>
          <p>SpiceJet – bu Hindiston va xalqaro yo‘nalishlar uchun qulay va tejamkor aviatashuvchi. Chiptalarni <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a> orqali band qiling.</p>
        </article>`,
        },
      ],
      origin: [
        {
          content: `
          <h4>Mamlakat:</h4>
          <p>Hindiston</p>
          <h4>Bosh ofis:</h4>
          <p>Gurgaun, Haryana</p>
          <h4>Asosiy xablar:</h4>
          <p>Dehli xalqaro aeroporti (DEL), Haydarobod xalqaro aeroporti (HYD)</p>
          <h4>Veb-sayt:</h4>
          <a href="https://www.spicejet.com" target="_blank" rel="noopener noreferrer">www.spicejet.com</a>
        `,
        },
      ],
    },
  },  
  en: {
    auroraAirlines: {
      pageKey: "auroraAirlinesInfo",
      imageUrl: "/images/AK/AU.svg",
      generalInfo: [
          {
              content: `<article>
                  <h1>Aurora Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Aurora Airlines is a Russian airline established in 2013 through the merger of Aeroflot-Nord and Vladivostok Avia. The airline’s headquarters are located in Vladivostok, with a primary focus on providing passenger flights, including both domestic and international routes, especially serving Russia’s Far East region.</p>
                  <h3>Aurora Airlines Fleet</h3>
                  <p>Aurora Airlines operates a modern fleet of aircraft:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Bombardier Q400</td>
                              <td>~8</td>
                              <td>Regional flights, fuel efficient, capacity of up to 78 passengers</td>
                          </tr>
                          <tr>
                              <td>SSJ 100</td>
                              <td>~10</td>
                              <td>Medium-haul flights, capacity of up to 100 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Aurora Airlines operates both domestic and international flights, including:</p>
                  <ul>
                      <li><b>Vladivostok – Moscow:</b> regular flights several times a week.</li>
                      <li><b>Vladivostok – Khabarovsk:</b> multiple flights per day.</li>
                      <li><b>Vladivostok – Seoul:</b> international flights several times a week.</li>
                  </ul>
                  <p>The latest schedules and ticket bookings are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Aurora Airlines offers reliable and convenient flights across Russia’s Far East and beyond, with high-quality service onboard.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Russia</p>
                  <h4>Headquarters:</h4>
                  <p>Vladivostok</p>
                  <h4>Main Hub:</h4>
                  <p>Vladivostok International Airport (VVO)</p>
                  <h4>Website:</h4>
                  <a href="https://www.flyaurora.ru" target="_blank" rel="noreferrer">flyaurora.ru</a>
              `,
          },
      ],
    },
    yamalAirlines: {
      pageKey: "yamalAirlinesInfo",
      imageUrl: "/images/AK/YM.svg",
      generalInfo: [
          {
              content: `<article>
                  <h1>Yamal Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Yamal Airlines is a Russian airline founded in 1997. The airline is based in Salekhard and operates flights across northern Russia, including remote and hard-to-reach areas of the Yamal region, as well as international flights to CIS countries and other destinations. Yamal Airlines plays a crucial role in transportation across the Extreme North.</p>
                  <h3>Yamal Airlines Fleet</h3>
                  <p>The airline operates a modern fleet of aircraft:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>SSJ 100</td>
                              <td>~6</td>
                              <td>Comfortable aircraft for regional routes</td>
                          </tr>
                          <tr>
                              <td>TU-204</td>
                              <td>~4</td>
                              <td>Medium- and long-haul flights</td>
                          </tr>
                          <tr>
                              <td>ATR 72</td>
                              <td>~5</td>
                              <td>Small aircraft for domestic and regional flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Yamal Airlines operates regular flights within Russia and on international routes, including:</p>
                  <ul>
                      <li><b>Moscow – Salekhard:</b> several times a week.</li>
                      <li><b>Tyumen – Novy Urengoy:</b> regular flights.</li>
                      <li><b>Salekhard – Khanty-Mansiysk:</b> direct flights for northern residents.</li>
                  </ul>
                  <p>The latest schedule and ticket bookings are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Yamal Airlines offers reliable and convenient flights across the Extreme North, as well as other domestic and international destinations, ensuring accessibility for passengers in remote areas of Russia.</p>
              </article>`
          }
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Russia</p>
                  <h4>Headquarters:</h4>
                  <p>Salekhard</p>
                  <h4>Main Hub:</h4>
                  <p>Salekhard International Airport (SLY)</p>
                  <h4>Website:</h4>
                  <a href="https://www.yamal.aero" target="_blank" rel="noreferrer">yamal.aero</a>
              `,
          },
      ],
    },
    yakutiaAirlines: {
      pageKey: "yakutiaAirlinesInfo",
      imageUrl: "/images/AK/YA.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Yakutia Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Yakutia Airlines is a Russian airline founded in 2002. The airline is based in Yakutsk and operates domestic flights across Russia, including services to remote areas of Siberia and the Russian Far East. Yakutia Airlines also offers international flights to several Asian countries.</p>
                  <h3>Yakutia Airlines Fleet</h3>
                  <p>The airline operates a modern fleet of aircraft:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>~5</td>
                              <td>Medium-haul flights</td>
                          </tr>
                          <tr>
                              <td>Yak-42</td>
                              <td>~6</td>
                              <td>Local routes, adapted for harsh weather conditions</td>
                          </tr>
                          <tr>
                              <td>Bombardier CRJ-200</td>
                              <td>~3</td>
                              <td>Short-haul and regional routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Yakutia Airlines operates flights across Russia and international routes, including:</p>
                  <ul>
                      <li><b>Yakutsk – Moscow (SVO):</b> several times a week.</li>
                      <li><b>Yakutsk – Vladivostok (VVO):</b> several times a week.</li>
                      <li><b>Yakutsk – Bangkok (BKK):</b> seasonal international flights.</li>
                  </ul>
                  <p>The latest schedule and ticket bookings are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Yakutia Airlines is a reliable carrier offering unique access to remote regions of Siberia and the Russian Far East, along with convenient service on international routes.</p>
              </article>`
          }
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Russia</p>
                  <h4>Headquarters:</h4>
                  <p>Yakutsk</p>
                  <h4>Main Hub:</h4>
                  <p>Yakutsk International Airport (YKS)</p>
                  <h4>Website:</h4>
                  <a href="https://yakutia.aero" target="_blank" rel="noreferrer">yakutia.aero</a>
              `,
          },
      ],
    },
    rossiyaAirlines: {
      pageKey: "rossiyaAirlinesInfo",
      imageUrl: "/images/AK/RO.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Rossiya Airlines</h1>
            <h2>Foundation and History</h2>
            <p>Rossiya Airlines is a Russian airline founded in 1934. It is one of the largest airlines in Russia and a subsidiary of the Aeroflot Group. The company operates both domestic and international flights. Rossiya Airlines is known for its high-quality service and flight safety.</p>
            <h3>Rossiya Airlines Fleet</h3>
            <p>The airline operates a modern fleet for domestic and international flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~40</td>
                  <td>Medium-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~30</td>
                  <td>Short and regional flights</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~10</td>
                  <td>Long-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>Rossiya Airlines operates flights across various destinations, including routes from Russia to Uzbekistan:</p>
            <ul>
              <li><b>Moscow – Tashkent (TAS):</b> several flights per week.</li>
              <li><b>Moscow – Samarkand (SKD):</b> several flights per week.</li>
            </ul>
            <p>Check the latest schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Rossiya Airlines is a reliable carrier offering a wide range of flights, quality service, and safe travel. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Russia</p>
            <h4>Headquarters:</h4>
            <p>Moscow</p>
            <h4>Main Hubs:</h4>
            <p>Sheremetyevo (SVO), Pulkovo (LED)</p>
            <h4>Website:</h4>
            <a href="https://www.rossiya-airlines.com" target="_blank" rel="noreferrer">www.rossiya-airlines.com</a>
          `,
        },
      ],
    },
    flyairstan: {
      pageKey: "flyairstanInfo",
      imageUrl: "/images/AK/FS.png",
      generalInfo: [
        {
          content: `<article>
            <h1>FlyAirstan</h1>
            <h2>Foundation Date and History</h2>
            <p>FlyAirstan is an airline founded in 2020, based in Almaty, Kazakhstan. The airline operates domestic flights within Kazakhstan, as well as international flights to Central Asia and the Middle East. FlyAirstan offers affordable travel options with convenient prices for passengers.</p>
            <h3>FlyAirstan Fleet</h3>
            <p>The airline fleet consists of modern aircraft:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~5</td>
                  <td>Medium-haul flights</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~3</td>
                  <td>Medium-haul flights with extended capacity</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>FlyAirstan operates domestic and international flights, including:</p>
            <ul>
              <li><b>Almaty – Nur-Sultan:</b> several times a week.</li>
              <li><b>Almaty – Tashkent (TAS):</b> regular flights.</li>
              <li><b>Almaty – Dubai (DXB):</b> international flights.</li>
            </ul>
            <p>Current schedule and ticket booking are available on the official website of the airline.</p>
            <h3>Conclusion</h3>
            <p>FlyAirstan offers affordable and quality services for both domestic and international flights, providing great travel options for passengers.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Kazakhstan</p>
            <h4>Headquarters:</h4>
            <p>Almaty</p>
            <h4>Main Hub:</h4>
            <p>Almaty International Airport (ALA)</p>
            <h4>Website:</h4>
            <a href="https://flyairstan.com" target="_blank" rel="noreferrer">flyairstan.com</a>
          `,
        },
      ],
    },
    silkAir: {
      pageKey: "silkAviaInfo",
      imageUrl: "/images/AK/US.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Silk Avia</h1>
            <h2>Foundation and History</h2>
            <p>Silk Avia is Uzbekistan’s first regional airline, founded on July 6, 2021, as a subsidiary of Uzbekistan Airports. The airline was created to stimulate business activity and promote domestic tourism in the country. The first commercial flight took place on April 25, 2023, on the Tashkent – Samarkand route.</p>
            <h3>Silk Avia Fleet</h3>
            <p>The airline operates a modern fleet of aircraft:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ATR 72-600</td>
                  <td>3</td>
                  <td>Capacity up to 70 passengers, efficient for regional routes</td>
                </tr>
                <tr>
                  <td>ATR 42-600</td>
                  <td>2 (planned)</td>
                  <td>Capacity up to 50 passengers, suitable for less busy routes</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>Silk Avia operates regular flights between cities in Uzbekistan, including:</p>
            <ul>
              <li><b>Tashkent – Bukhara:</b> up to 16 flights per week.</li>
              <li><b>Tashkent – Termez:</b> up to 16 flights per week.</li>
              <li><b>Nukus – Muynak:</b> regular flights starting from April 1, 2024.</li>
            </ul>
            <p>Check the latest schedule and book tickets on the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Silk Avia offers comfortable and affordable domestic flights within Uzbekistan, contributing to the development of domestic tourism and providing convenient connections between regions.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Uzbekistan</p>
            <h4>Headquarters:</h4>
            <p>Tashkent</p>
            <h4>Main Hub:</h4>
            <p>Samarkand International Airport (SKD)</p>
            <h4>Website:</h4>
            <a href="https://silk-avia.com" target="_blank" rel="noreferrer">silk-avia.com</a>
          `,
        },
      ],
    },
    pegasusAir: {
      pageKey: "pegasusAirInfo",
      imageUrl: "/images/AK/PC.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Pegasus Airlines</h1>
            <h2>Foundation Date and History</h2>
            <p>Pegasus Airlines is a Turkish budget airline founded in 1990. The airline specializes in providing affordable flights to Europe, the Middle East, and other regions.</p>
            <h3>Pegasus Airlines Fleet</h3>
            <p>The airline uses modern aircraft to ensure efficient and comfortable flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~50</td>
                  <td>For short and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~25</td>
                  <td>High fuel efficiency</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Pegasus Airlines operates flights to various destinations:</p>
            <ul>
              <li><b>Istanbul (SAW) – Berlin (BER):</b> Daily flights.</li>
              <li><b>Istanbul (SAW) – London (STN):</b> Several times a week.</li>
              <li><b>Ankara (ESB) – Dubai (DXB):</b> Regular flights.</li>
            </ul>
            <p>For the updated schedule, visit <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Pegasus Airlines offers affordable flights to popular destinations. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Turkey</p>
            <h4>Headquarters:</h4>
            <p>Istanbul</p>
            <h4>Main Hub:</h4>
            <p>Sabiha Gökçen International Airport (SAW)</p>
            <h4>Website:</h4>
            <a href="https://www.flypgs.com" target="_blank" rel="noreferrer">www.flypgs.com</a>
          `,
        },
      ],
    },
    lufthansaAir: {
      pageKey: "lufthansaAirInfo",
      imageUrl: "/images/LH/LH.png  ",
      generalInfo: [
        {
          content:` <article>
            <h1>Lufthansa</h1>
            <h2>Foundation Date and History</h2>
            <p>Lufthansa is Germany's flagship airline, founded in 1953. It is one of the largest and most respected airlines in the world, offering flights to numerous international destinations.</p>
            <h3>Lufthansa Fleet</h3>
            <p>The airline operates a diverse and modern fleet for maximum efficiency and passenger comfort:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~80</td>
                  <td>Short and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Boeing 747</td>
                  <td>~30</td>
                  <td>Long-haul international flights</td>
                </tr>
                <tr>
                  <td>Airbus A350</td>
                  <td>~20</td>
                  <td>Fuel-efficient, long-haul operations</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Lufthansa offers flights to major destinations worldwide:</p>
            <ul>
              <li><b>Frankfurt (FRA) – New York (JFK):</b> Daily flights.</li>
              <li><b>Munich (MUC) – Tokyo (HND):</b> Several times a week.</li>
              <li><b>Berlin (BER) – Los Angeles (LAX):</b> Regular flights.</li>
            </ul>
            <p>For the updated schedule, visit <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Lufthansa provides premium service and extensive international connectivity. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Germany</p>
            <h4>Headquarters:</h4>
            <p>Frankfurt</p>
            <h4>Main Hub:</h4>
            <p>Frankfurt Airport (FRA)</p>
            <h4>Website:</h4>
            <a href="https://www.lufthansa.com" target="_blank" rel="noreferrer">www.lufthansa.com</a>
          `,
        },
      ],
    },

    nordwindAir: {
      pageKey: "nordwindAirInfo",
      imageUrl: "/images/AK/N4.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Nordwind Airlines</h1>
            <h2>Foundation Date and History</h2>
            <p>Nordwind Airlines is a Russian airline established in 2008. The airline is known for charter and regular international flights, offering affordable flights to popular tourist destinations.</p>
            <h3>Nordwind Airlines Fleet</h3>
            <p>The airline operates modern aircraft to ensure comfort and safety for its passengers:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~5</td>
                  <td>For long-haul flights</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>~10</td>
                  <td>For medium-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~15</td>
                  <td>For short and medium-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Nordwind Airlines operates flights to various destinations:</p>
            <ul>
              <li><b>Moscow (SVO) – Antalya (AYT):</b> Daily flights.</li>
              <li><b>Moscow (SVO) – Dubai (DXB):</b> Several times a week.</li>
              <li><b>St. Petersburg (LED) – Sochi (AER):</b> Regular flights.</li>
            </ul>
            <p>For the current schedule, check <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Nordwind Airlines offers convenient and affordable flights to popular tourist destinations. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Russia</p>
            <h4>Headquarters:</h4>
            <p>Moscow</p>
            <h4>Main Hub:</h4>
            <p>Sheremetyevo International Airport (SVO)</p>
            <h4>Website:</h4>
            <a href="https://www.nordwindairlines.ru" target="_blank" rel="noreferrer">www.nordwindairlines.ru</a>
          `,
        },
      ],
    },
    kamair: {
      pageKey: "kamairInfo",
      imageUrl: "/images/AF/RQ.svg",
      generalInfo: [
        {
          content:` <article>
            <h1>Kam Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Kam Air is a private Afghan airline founded in 2003. It offers domestic and regional flights across Asia and the Middle East.</p>
            <h3>Kam Air Fleet</h3>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~5</td>
                  <td>Medium-haul routes</td>
                </tr>
                <tr>
                  <td>Airbus A340</td>
                  <td>~2</td>
                  <td>Long-haul international flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Kam Air operates flights to major destinations:</p>
            <ul>
              <li><b>Kabul (KBL) – Dubai (DXB):</b> Regular flights.</li>
              <li><b>Kabul (KBL) – Istanbul (IST):</b> Weekly flights.</li>
              <li><b>Kabul (KBL) – Delhi (DEL):</b> Several times a week.</li>
            </ul>
            <h3>Conclusion</h3>
            <p>Kam Air is a growing airline in the Afghan aviation sector.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Afghanistan</p>
            <h4>Headquarters:</h4>
            <p>Kabul</p>
            <h4>Main Hub:</h4>
            <p>Hamid Karzai International Airport (KBL)</p>
            <h4>Website:</h4>
            <a href="https://www.kamair.com" target="_blank" rel="noreferrer">www.kamair.com</a>
            `,
        },
      ],
    },
    tajikAir: {
      pageKey: "tajikAirInfo",
      imageUrl: "/images/TJ/TJ.svg",
      generalInfo: [
        {
          content: ` <article>
            <h1>Tajik Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Tajik Air is the national airline of Tajikistan, established in 1924. It is one of the oldest airlines in Central Asia, operating both domestic and international flights.</p>
            <h3>Tajik Air Fleet</h3>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 757</td>
                  <td>~2</td>
                  <td>Long-haul flights</td>
                </tr>
                <tr>
                  <td>An-28</td>
                  <td>~3</td>
                  <td>Short-haul regional flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Tajik Air operates flights to key destinations:</p>
            <ul>
              <li><b>Dushanbe (DYU) – Moscow (DME):</b> Regular flights.</li>
              <li><b>Dushanbe (DYU) – Dubai (DXB):</b> International flights.</li>
              <li><b>Khujand (LBD) – Bishkek (FRU):</b> Regional flights.</li>
            </ul>
            <h3>Conclusion</h3>
            <p>Tajik Air plays a vital role in Tajikistan’s transportation system, connecting cities and international destinations.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Tajikistan</p>
            <h4>Headquarters:</h4>
            <p>Dushanbe</p>
            <h4>Main Hub:</h4>
            <p>Dushanbe International Airport (DYU)</p>
            <h4>Website:</h4>
            <a href="https://www.tajikair.tj" target="_blank" rel="noreferrer">www.tajikair.tj</a>
           ` ,
        },
      ],
    },
    somonAir: {
      pageKey: "somonAirInfo",
      imageUrl: "/images/TJ/SZ.svg",
      generalInfo: [
        {
          content: ` <article>
            <h1>Somon Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Somon Air is a Tajik airline founded in 2008. It is the first private airline in Tajikistan, providing both domestic and international flights.</p>
            <h3>Somon Air Fleet</h3>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~6</td>
                  <td>Short to medium-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Somon Air operates flights to key destinations:</p>
            <ul>
              <li><b>Dushanbe (DYU) – Moscow (DME):</b> Frequent flights.</li>
              <li><b>Dushanbe (DYU) – Dubai (DXB):</b> Regular flights.</li>
              <li><b>Khujand (LBD) – Istanbul (IST):</b> Seasonal flights.</li>
            </ul>
            <h3>Conclusion</h3>
            <p>Somon Air is known for its high safety standards and growing international presence.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `-
            <h4>Country:</h4>
            <p>Tajikistan</p>
            <h4>Headquarters:</h4>
            <p>Dushanbe</p>
            <h4>Main Hub:</h4>
            <p>Dushanbe International Airport (DYU)</p>
            <h4>Website:</h4>
            <a href="https://www.somonair.com" target="_blank" rel="noreferrer">www.somonair.com</a>
            `,
        },
      ],
    },
    ilkarAir: {
      pageKey: "ilkarAirInfo",
      imageUrl: "/images/AK/EO.png",
      generalInfo: [
        {
          content:`  <article>
            <h1>Ilkar Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Ilkar Air is a private airline founded in 1995. It specializes in both domestic and international flights, offering premium services to passengers.</p>
            <h3>Ilkar Air Fleet</h3>
            <p>The airline operates a modern fleet for long-haul and regional flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A330</td>
                  <td>8</td>
                  <td>Long-haul international flights</td>
                </tr>
                <tr>
                  <td>Embraer E190</td>
                  <td>12</td>
                  <td>Regional and short-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>Ilkar Air serves over 30 destinations, including:</p>
            <ul>
              <li><b>Main Hub – London:</b> Frequent flights.</li>
              <li><b>Main Hub – Dubai:</b> Regular flights.</li>
              <li><b>Main Hub – New York:</b> Long-haul international flights.</li>
            </ul>
            <p>Updated schedules and ticket bookings are available on the official airline website.</p>
            <h3>Conclusion</h3>
            <p>Ilkar Air is known for its reliable service and international expansion, making it a key player in the aviation industry.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Unknown</p>
            <h4>Headquarters:</h4>
            <p>Main Hub City</p>
            <h4>Main Hub:</h4>
            <p>Main International Airport</p>
            <h4>Website:</h4>
            <a href="https://www.ilkarair.com" target="_blank" rel="noreferrer">ilkarair.com</a>
          `,
        },
      ],
    },
    flydubaiAir: {
      pageKey: "flydubaiAirInfo",
      imageUrl: "/images/AK/FZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Flydubai</h1>
            <h2>Foundation Date and History</h2>
            <p>Flydubai is a budget airline from the UAE, founded in 2008. The airline offers affordable international flights, covering over 100 destinations worldwide.</p>
            <h3>Flydubai Fleet</h3>
            <p>The airline uses modern aircraft for efficient and comfortable flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737-800</td>
                  <td>~30</td>
                  <td>Medium-haul routes</td>
                </tr>
                <tr>
                  <td>Boeing 737 MAX</td>
                  <td>~40</td>
                  <td>Modern technology and fuel efficiency</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Flydubai operates flights between Dubai and various countries, including Uzbekistan:</p>
            <ul>
              <li>
                <b>Tashkent – Dubai (DXB):</b> daily.
              </li>
              <li>
                <b>Samarkand – Dubai (DXB):</b> 2-3 times a week.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>Flydubai offers affordable flights with convenient schedules and quality service. Book your flights at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>United Arab Emirates</p>
            <h4>Headquarters:</h4>
            <p>Dubai</p>
            <h4>Main Hub:</h4>
            <p>Dubai International Airport (DXB)</p>
            <h4>Website:</h4>
            <a href="https://www.flydubai.com" target="_blank" rel="noreferrer">www.flydubai.com</a>
          `,
        },
      ],
    },
    kuwaitAirways: {
      pageKey: "kuwaitAirwaysInfo",
      imageUrl: "/images/AK/KU.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Kuwait Airways</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Kuwait Airways is the national airline of Kuwait, founded in 1953. It is the largest carrier in Kuwait, offering both regional and international flights.</p>
                  <h3>Kuwait Airways Fleet</h3>
                  <p>The airline’s fleet includes aircraft for long-haul and regional flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 777</td>
                              <td>6</td>
                              <td>For long-haul flights</td>
                          </tr>
                          <tr>
                              <td>Airbus A320</td>
                              <td>13</td>
                              <td>For regional flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Kuwait Airways serves over 40 destinations, including:</p>
                  <ul>
                      <li><b>Kuwait – London:</b> regular flights.</li>
                      <li><b>Kuwait – Dubai:</b> regular flights.</li>
                      <li><b>Kuwait – New York:</b> long-haul flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Kuwait Airways offers quality services on international flights and is a leading carrier in the region.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Kuwait</p>
                  <h4>Headquarters:</h4>
                  <p>Kuwait City</p>
                  <h4>Main Hub:</h4>
                  <p>Kuwait International Airport (KWI)</p>
                  <h4>Website:</h4>
                  <a href="https://www.kuwaitairways.com" target="_blank" rel="noreferrer">kuwaitairways.com</a>
              `,
          },
      ],
  },
    emiratesAir: {
      pageKey: "emiratesAirInfo",
      imageUrl: "/images/AK/EK.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Emirates</h1>
            <h2>Foundation Date and History</h2>
            <p>Emirates is one of the largest airlines in the world, founded in 1985 in Dubai (UAE). The airline is known for its high level of service and operates flights to over 150 destinations worldwide.</p>
            <h3>Emirates Fleet</h3>
            <p>The airline operates one of the most modern and comfortable fleets:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A380</td>
                  <td>~120</td>
                  <td>Double-decked aircraft for long-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~140</td>
                  <td>Flexibility for long and medium-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Emirates operates regular flights between Dubai and major cities around the world, including Uzbekistan:</p>
            <ul>
              <li>
                <b>Tashkent – Dubai (DXB):</b> daily.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>Emirates is a premium airline offering convenient routes and first-class service. Book your flights at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>United Arab Emirates</p>
            <h4>Headquarters:</h4>
            <p>Dubai</p>
            <h4>Main Hub:</h4>
            <p>Dubai International Airport (DXB)</p>
            <h4>Website:</h4>
            <a href="https://www.emirates.com" target="_blank" rel="noreferrer">www.emirates.com</a>
          `,
        },
      ],
    },
    centrumAir: {
      pageKey: "centrumAirInfo",
      imageUrl: "/images/AK/C6.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Centrum Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Centrum Air is an Uzbek airline founded in 2022. The company focuses on budget and charter flights, providing affordable travel both within the country and internationally.</p>
            <h3>Centrum Air Fleet</h3>
            <p>The airline operates modern aircraft for efficient and comfortable flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 737</td>
                  <td>~5</td>
                  <td>Medium-haul routes</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Centrum Air operates flights to various destinations:</p>
            <ul>
              <li>
                <b>Tashkent – Moscow (VKO):</b> several times a week.
              </li>
              <li>
                <b>Tashkent – Dubai (DXB):</b> 2-3 times a week.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>Centrum Air offers affordable flights with a convenient schedule. Book your flights at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Uzbekistan</p>
            <h4>Headquarters:</h4>
            <p>Tashkent</p>
            <h4>Main Hub:</h4>
            <p>Tashkent International Airport (TAS)</p>
            <h4>Website:</h4>
            <a href="https://www.centrumair.uz" target="_blank" rel="noreferrer">www.centrumair.uz</a>
          `,
        },
      ],
    },
    egyptAir: {
      pageKey: "egyptAirInfo",
      imageUrl: "/images/AK/MS.png",
      generalInfo: [
        {
          content: `<article>
            <h1>EgyptAir</h1>
            <h2>Foundation Date and History</h2>
            <p>EgyptAir is the national airline of Egypt, founded in 1932. It is one of the oldest airlines in the world and the first airline in the Middle East. EgyptAir is a member of the Star Alliance and operates regular flights worldwide.</p>
            <h3>EgyptAir Fleet</h3>
            <p>The airline uses modern aircraft for both domestic and international flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~20</td>
                  <td>Medium-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~30</td>
                  <td>Short and regional routes</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~10</td>
                  <td>Long-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~6</td>
                  <td>High comfort for international flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>EgyptAir operates flights between Egypt and various countries, including Uzbekistan:</p>
            <ul>
              <li>
                <b>Tashkent – Cairo (CAI):</b> 2 times a week.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>EgyptAir is a leading airline in the Middle East offering convenient flights and a high level of service. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Egypt</p>
            <h4>Headquarters:</h4>
            <p>Cairo</p>
            <h4>Main Hub:</h4>
            <p>Cairo International Airport (CAI)</p>
            <h4>Website:</h4>
            <a href="https://www.egyptair.com" target="_blank" rel="noreferrer">www.egyptair.com</a>
          `,
        },
      ],
    },
    chinaSouthern: {
      pageKey: "chinaSouthernInfo",
      imageUrl: "/images/AK/CZ.png",
      generalInfo: [
        {
          content: `<article>
            <h1>China Southern Airlines</h1>
            <h2>Foundation Date and History</h2>
            <p>China Southern Airlines (中国南方航空) is one of the largest airlines in China, founded in 1988. It is the country's flagship carrier and one of the leading airlines in the world by passenger turnover. The airline operates domestic and international flights, including routes to Uzbekistan.</p>
            <h3>China Southern Airlines Fleet</h3>
            <p>The airline operates a wide range of modern aircraft:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~200</td>
                  <td>Short- and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Boeing 737</td>
                  <td>~300</td>
                  <td>Flexible for domestic and regional flights</td>
                </tr>
                <tr>
                  <td>Airbus A350</td>
                  <td>~20</td>
                  <td>Long-haul flights with high comfort</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~40</td>
                  <td>Energy-efficient long-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule from Uzbekistan</h3>
            <p>China Southern Airlines operates flights from Uzbekistan to China:</p>
            <ul>
              <li>
                <b>Tashkent – Guangzhou (CAN):</b> 2-3 times a week.
              </li>
              <li>
                <b>Tashkent – Beijing (PEK):</b> 1-2 times a week.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>China Southern Airlines is a leading Chinese airline with an extensive network and modern fleet. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>China</p>
            <h4>Headquarters:</h4>
            <p>Guangzhou</p>
            <h4>Main Hubs:</h4>
            <p>Guangzhou Baiyun (CAN), Beijing Daxing (PKX), Shanghai Pudong (PVG)</p>
            <h4>Website:</h4>
            <a href="https://www.csair.com" target="_blank" rel="noreferrer">www.csair.com</a>
          `,
        },
      ],
    },
    pia: {
      pageKey: "piaInfo",
      imageUrl: "/images/AK/PK.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>Pakistan International Airlines (PIA)</h1>
                  <h2>Foundation Date and History</h2>
                  <p>PIA was founded in 1955 and serves as the national airline of Pakistan. It operates a wide range of international and domestic flights, representing Pakistan on the global stage.</p>
                  <h3>PIA Fleet</h3>
                  <p>The PIA fleet consists of aircraft suitable for both long-haul and medium-haul flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 777</td>
                              <td>5</td>
                              <td>For long-haul flights</td>
                          </tr>
                          <tr>
                              <td>Airbus A320</td>
                              <td>10</td>
                              <td>For domestic and regional routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>PIA operates flights to more than 30 destinations, including:</p>
                  <ul>
                      <li><b>Islamabad – London:</b> regular flights.</li>
                      <li><b>Karachi – Dubai:</b> regular flights.</li>
                      <li><b>Lahore – New York:</b> long-haul flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>PIA offers a variety of destinations for travelers, covering both long-haul and regional routes with quality services.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Pakistan</p>
                  <h4>Headquarters:</h4>
                  <p>Islamabad</p>
                  <h4>Main Hub:</h4>
                  <p>Jinnah International Airport (KHI)</p>
                  <h4>Website:</h4>
                  <a href="https://www.piac.com.pk" target="_blank" rel="noreferrer">piac.com.pk</a>
              `,
          },
      ],
    },
    airblue: {
      pageKey: "airblueInfo",
      imageUrl: "/images/AK/PA.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Airblue</h1>
            <h2>Foundation Date and History</h2>
            <p>Airblue, founded in 2003, is one of Pakistan's largest private airlines. The airline operates both domestic and international flights.</p>
            <h3>Airblue Fleet</h3>
            <p>Airblue's fleet includes aircraft suitable for regional and international flights:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>7</td>
                  <td>For regional flights</td>
                </tr>
                <tr>
                  <td>Airbus A321</td>
                  <td>4</td>
                  <td>For long-haul flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>Airblue operates flights to over 15 destinations, including:</p>
            <ul>
              <li><b>Islamabad – Lahore:</b> Regular flights.</li>
              <li><b>Karachi – Dubai:</b> Regular flights.</li>
              <li><b>Islamabad – Manchester:</b> Regular flights.</li>
            </ul>
            <p>For the updated schedule and ticket booking, visit the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>Airblue provides convenient and affordable flights for both domestic and international travelers.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Pakistan</p>
            <h4>Headquarters:</h4>
            <p>Islamabad</p>
            <h4>Main Hub:</h4>
            <p>Islamabad International Airport (ISB)</p>
            <h4>Website:</h4>
            <a href="https://www.airblue.com" target="_blank" rel="noreferrer">airblue.com</a>
          `,
        },
      ],
    },
    sereneAir: {
      pageKey: "sereneAirInfo",
      imageUrl: "/images/AK/ER.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>SereneAir</h1>
                  <h2>Foundation Date and History</h2>
                  <p>SereneAir was founded in 2017 and is a private airline in Pakistan. The airline operates both domestic and international flights, offering convenience and affordable fares for passengers.</p>
                  <h3>SereneAir Fleet</h3>
                  <p>The SereneAir fleet includes aircraft designed for short and medium-haul flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 737-800</td>
                              <td>6</td>
                              <td>For domestic and regional flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>SereneAir operates flights to more than 10 destinations, including:</p>
                  <ul>
                      <li><b>Islamabad – Karachi:</b> regular flights.</li>
                      <li><b>Lahore – Dubai:</b> regular flights.</li>
                      <li><b>Islamabad – Jeddah:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>SereneAir offers comfortable and affordable flights for both domestic and international routes.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Pakistan</p>
                  <h4>Headquarters:</h4>
                  <p>Islamabad</p>
                  <h4>Main Hub:</h4>
                  <p>Islamabad International Airport (ISB)</p>
                  <h4>Website:</h4>
                  <a href="https://www.sereneair.com" target="_blank" rel="noreferrer">sereneair.com</a>
              `,
          },
      ],
    },
    airIndia: {
      pageKey: "airIndiaInfo",
      imageUrl: "/images/AK/AI.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Air India</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Air India was founded in 1932 and is the national carrier of India. The airline operates a wide range of international and domestic flights, making it one of the largest airlines in South Asia.</p>
                  <h3>Air India Fleet</h3>
                  <p>The Air India fleet consists of modern aircraft for long-haul flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 777</td>
                              <td>6</td>
                              <td>For long-haul routes, fuel efficiency</td>
                          </tr>
                          <tr>
                              <td>Airbus A320</td>
                              <td>30</td>
                              <td>For domestic and regional routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Air India operates flights to over 50 destinations, including:</p>
                  <ul>
                      <li><b>Delhi – New York:</b> regular flights.</li>
                      <li><b>Mumbai – London:</b> regular flights.</li>
                      <li><b>Delhi – Singapore:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Air India offers a wide selection of destinations and high-quality services for passengers around the world.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>India</p>
                  <h4>Headquarters:</h4>
                  <p>New Delhi</p>
                  <h4>Main Hub:</h4>
                  <p>Indira Gandhi International Airport (DEL)</p>
                  <h4>Website:</h4>
                  <a href="https://www.airindia.in" target="_blank" rel="noreferrer">airindia.in</a>
              `,
          },
      ],
    },
    vistara: {
      pageKey: "vistaraInfo",
      imageUrl: "/images/AK/UK.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Vistara</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Vistara was founded in 2013 as a joint venture between Tata Sons and Singapore Airlines. It offers premium services and operates both international and domestic flights.</p>
                  <h3>Vistara Fleet</h3>
                  <p>Vistara's fleet consists of modern aircraft designed for both medium and long-haul flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>30</td>
                              <td>For domestic routes</td>
                          </tr>
                          <tr>
                              <td>Airbus A321</td>
                              <td>10</td>
                              <td>For international routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Vistara operates flights to more than 30 destinations, including:</p>
                  <ul>
                      <li><b>Delhi – Mumbai:</b> regular flights.</li>
                      <li><b>Delhi – Singapore:</b> regular flights.</li>
                      <li><b>Mumbai – Bangkok:</b> international flights.</li>
                  </ul>
                  <p>The latest schedules and ticket bookings are available on the airline's official website.</p>
                  <h3>Conclusion</h3>
                  <p>Vistara is a premium airline known for its high-quality service and passenger comfort.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>India</p>
                  <h4>Headquarters:</h4>
                  <p>Gurgaon</p>
                  <h4>Main Hub:</h4>
                  <p>Indira Gandhi International Airport (DEL)</p>
                  <h4>Website:</h4>
                  <a href="https://www.airvistara.com" target="_blank" rel="noreferrer">airvistara.com</a>
              `,
          },
      ],
    },
    gulfAir: {
      pageKey: "gulfAirInfo",
      imageUrl: "/images/AK/GF.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Gulf Air</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Gulf Air was founded in 1950 and is the national airline of Bahrain. The company provides quality services on both regional and international flights.</p>
                  <h3>Gulf Air Fleet</h3>
                  <p>The Gulf Air fleet consists of modern and efficient aircraft, adapted for various destinations:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 787-9</td>
                              <td>10</td>
                              <td>For long-haul international flights</td>
                          </tr>
                          <tr>
                              <td>Airbus A321neo</td>
                              <td>8</td>
                              <td>For medium-haul flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Gulf Air serves over 50 destinations, including:</p>
                  <ul>
                      <li><b>Manama – Dubai:</b> regular flights.</li>
                      <li><b>Manama – London:</b> regular flights.</li>
                      <li><b>Manama – Mumbai:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Gulf Air is an airline providing quality services and comfortable flights on both regional and international routes.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Bahrain</p>
                  <h4>Headquarters:</h4>
                  <p>Manama</p>
                  <h4>Main Hub:</h4>
                  <p>Bahrain International Airport (BAH)</p>
                  <h4>Website:</h4>
                  <a href="https://www.gulfair.com" target="_blank" rel="noreferrer">gulfair.com</a>
              `,
          },
      ],
    },
    omanAir: {
      pageKey: "omanAirInfo",
      imageUrl: "/images/AK/WY.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Oman Air</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Oman Air was founded in 1993 as the national airline of Oman. The airline operates both domestic and international flights, ensuring comfortable long-haul travel and connections to neighboring countries.</p>
                  <h3>Oman Air Fleet</h3>
                  <p>The Oman Air fleet consists of modern aircraft for international flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Boeing 787 Dreamliner</td>
                              <td>5</td>
                              <td>For long-haul flights</td>
                          </tr>
                          <tr>
                              <td>Airbus A330</td>
                              <td>6</td>
                              <td>For medium and long routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Oman Air operates flights to more than 50 destinations, including:</p>
                  <ul>
                      <li><b>Muscat – Dubai:</b> regular flights.</li>
                      <li><b>Muscat – London:</b> regular flights.</li>
                      <li><b>Muscat – Singapore:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Oman Air offers comfortable and convenient flights connecting Oman with various international destinations, providing high-quality services.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Oman</p>
                  <h4>Headquarters:</h4>
                  <p>Muscat</p>
                  <h4>Main Hub:</h4>
                  <p>Muscat International Airport (MCT)</p>
                  <h4>Website:</h4>
                  <a href="https://www.omanair.com" target="_blank" rel="noreferrer">omanair.com</a>
              `,
          },
      ],
    },
    salamAir: {
      pageKey: "salamAirInfo",
      imageUrl: "/images/AK/OV.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>SalamAir</h1>
                  <h2>Foundation Date and History</h2>
                  <p>SalamAir was founded in 2016 and is Oman’s first low-cost carrier. The airline focuses on providing affordable flights within the region and beyond.</p>
                  <h3>SalamAir Fleet</h3>
                  <p>The SalamAir fleet consists of efficient aircraft for regional and international flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>A320neo</td>
                              <td>8</td>
                              <td>For regional and short-haul flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>SalamAir operates flights to more than 30 destinations, including:</p>
                  <ul>
                      <li><b>Muscat – Dubai:</b> regular flights.</li>
                      <li><b>Muscat – Jeddah:</b> regular flights.</li>
                      <li><b>Muscat – Kochi:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>SalamAir offers budget-friendly and convenient flights for travelers seeking affordable airfares.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Oman</p>
                  <h4>Headquarters:</h4>
                  <p>Muscat</p>
                  <h4>Main Hub:</h4>
                  <p>Muscat International Airport (MCT)</p>
                  <h4>Website:</h4>
                  <a href="https://www.salamair.com" target="_blank" rel="noreferrer">salamair.com</a>
              `,
          },
      ],
    },
    kishAir: {
      pageKey: "kishAirInfo",
      imageUrl: "/images/AK/Y9.jpeg",
      generalInfo: [
          {
              content: `<article>
                  <h1>Kish Air</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Kish Air is an Iranian airline founded in 1993. The airline is the largest carrier on Kish Island and operates regular flights to other cities in Iran as well as international destinations.</p>
                  <h3>Kish Air Fleet</h3>
                  <p>The airline's fleet consists of aircraft suitable for regional and international flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>5</td>
                              <td>Medium-haul flights with capacity of up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Fokker 100</td>
                              <td>2</td>
                              <td>Regional flights with capacity of up to 100 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Kish Air operates flights on the following routes:</p>
                  <ul>
                      <li><b>Kish – Tehran:</b> regular flights.</li>
                      <li><b>Kish – Sharjah:</b> international flights.</li>
                      <li><b>Kish – Dubai:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Kish Air offers reliable and high-quality flights connecting Kish Island with other cities in Iran and neighboring countries in the Middle East.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Iran</p>
                  <h4>Headquarters:</h4>
                  <p>Tehran</p>
                  <h4>Main Hub:</h4>
                  <p>Kish International Airport (KIH)</p>
                  <h4>Website:</h4>
                  <a href="https://kishairlines.ir" target="_blank" rel="noreferrer">kishairlines.ir</a>
              `,
          },
      ],
    },
    qeshmAir: {
      pageKey: "qeshmAirInfo",
      imageUrl: "/images/AK/QB.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>Qeshm Air</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Qeshm Air is an Iranian airline founded in 1993. The airline operates domestic flights within Iran as well as international routes, connecting Iran with neighboring countries and regions.</p>
                  <h3>Qeshm Air Fleet</h3>
                  <p>The airline's fleet consists of modern aircraft suitable for various types of flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>4</td>
                              <td>Medium-haul routes with a capacity of up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Fokker 100</td>
                              <td>3</td>
                              <td>Regional flights with a capacity of up to 100 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Qeshm Air operates flights on the following routes:</p>
                  <ul>
                      <li><b>Tehran – Kish:</b> regular flights.</li>
                      <li><b>Tehran – Sharjah:</b> international flights.</li>
                      <li><b>Tehran – Dubai:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Qeshm Air offers convenient flights within Iran and to neighboring countries.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Iran</p>
                  <h4>Headquarters:</h4>
                  <p>Tehran</p>
                  <h4>Main Hub:</h4>
                  <p>Kish International Airport (KIH)</p>
                  <h4>Website:</h4>
                  <a href="http://www.qeshm-air.com" target="_blank" rel="noreferrer">qeshm-air.com</a>
              `,
          },
      ],
    },
    iranAsemanAirlines: {
      pageKey: "iranAsemanAirlinesInfo",
      imageUrl: "/images/AK/IB.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>Iran Aseman Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Iran Aseman Airlines is an Iranian airline founded in 1980. It is one of the largest carriers in Iran, providing both domestic and international flights. The airline serves over 40 destinations worldwide.</p>
                  <h3>Iran Aseman Airlines Fleet</h3>
                  <p>The company's fleet consists of both wide-body and narrow-body aircraft for various types of flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>ATR 72-600</td>
                              <td>5</td>
                              <td>Economical aircraft for regional flights</td>
                          </tr>
                          <tr>
                              <td>Airbus A320</td>
                              <td>6</td>
                              <td>Medium-haul flights with a capacity of up to 180 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Iran Aseman Airlines operates flights on the following routes:</p>
                  <ul>
                      <li><b>Tehran – Sharjah:</b> regular flights.</li>
                      <li><b>Tehran – Damascus:</b> international flights.</li>
                      <li><b>Tehran – Baghdad:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Iran Aseman Airlines offers a wide selection of domestic and international flights, providing comfortable conditions for passengers.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Iran</p>
                  <h4>Headquarters:</h4>
                  <p>Tehran</p>
                  <h4>Main Hub:</h4>
                  <p>Tehran International Airport (IKA)</p>
                  <h4>Website:</h4>
                  <a href="http://www.apa-air.com" target="_blank" rel="noreferrer">apa-air.com</a>
              `,
          },
      ],
    },
    syrianAir: {
      pageKey: "syrianAirInfo",
      imageUrl: "/images/AK/RB.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>SyrianAir</h1>
                  <h2>Foundation Date and History</h2>
                  <p>SyrianAir is the national airline of Syria, founded in 1946. The airline provides both domestic and international flights, making it an important carrier in the Middle East region.</p>
                  <h3>SyrianAir Fleet</h3>
                  <p>The airline’s fleet includes several aircraft types for domestic and international routes:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>4</td>
                              <td>Medium-haul flights with a capacity of up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Boing 737</td>
                              <td>2</td>
                              <td>Regional and international flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>SyrianAir operates flights on the following routes:</p>
                  <ul>
                      <li><b>Damascus – Beirut:</b> regular flights.</li>
                      <li><b>Damascus – Moscow:</b> international flights.</li>
                      <li><b>Damascus – Cairo:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>SyrianAir is an important carrier in the region, providing flights to countries in the Middle East and Europe.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Syria</p>
                  <h4>Headquarters:</h4>
                  <p>Damascus</p>
                  <h4>Main Hub:</h4>
                  <p>Damascus International Airport (DAM)</p>
                  <h4>Website:</h4>
                  <a href="https://www.syriaair.com" target="_blank" rel="noreferrer">syriaair.com</a>
              `,
          },
      ],
    },
    middleEastAirlines: {
      pageKey: "middleEastAirlinesInfo",
      imageUrl: "/images/AK/ME.png",
      generalInfo: [
          {
              content:`<article>
                  <h1>Middle East Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Middle East Airlines (MEA) is the national airline of Lebanon, founded in 1945. It is one of the largest carriers in the Middle East, offering both regional and international flights.</p>
                  <h3>Middle East Airlines Fleet</h3>
                  <p>The airline’s fleet includes modern aircraft for international routes:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>8</td>
                              <td>Medium-haul flights with a capacity of up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Airbus A330</td>
                              <td>4</td>
                              <td>For long-haul flights</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Middle East Airlines operates over 30 destinations worldwide, including:</p>
                  <ul>
                      <li><b>Beirut – Paris:</b> regular flights.</li>
                      <li><b>Beirut – New York:</b> international flights.</li>
                      <li><b>Beirut – Dubai:</b> regular flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking options are available on the airline’s official website.</p>
                  <h3>Conclusion</h3>
                  <p>Middle East Airlines is a key carrier in Lebanon, offering a wide range of flights to Europe, America, and the Middle East.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Lebanon</p>
                  <h4>Headquarters:</h4>
                  <p>Beirut</p>
                  <h4>Main Hub:</h4>
                  <p>Beirut International Airport (BEY)</p>
                  <h4>Website:</h4>
                  <a href="http://www.mea.com.lb" target="_blank" rel="noreferrer">mea.com.lb</a>
              `,
          },
      ],
    },
    
    aircompanyArmenia: {
      pageKey: "aircompanyArmeniaInfo",
      imageUrl: "/images/AK/JI.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Aircompany Armenia</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Aircompany Armenia is an Armenian airline founded in 2003. The company provides domestic and international passenger services, operating flights to destinations across Europe, Asia, and the Middle East.</p>
                  <h3>Aircompany Armenia Fleet</h3>
                  <p>The airline's fleet consists of aircraft suitable for short and medium-haul routes:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>2</td>
                              <td>Medium-haul flights with capacity for up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Airbus A319</td>
                              <td>1</td>
                              <td>Used for shorter routes with a capacity of up to 140 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Aircompany Armenia operates flights to destinations including:</p>
                  <ul>
                      <li><b>Yerevan – Moscow:</b> regular flights.</li>
                      <li><b>Yerevan – Istanbul:</b> international flights.</li>
                  </ul>
                  <p>The latest schedule and ticket booking are available on the airline's official website.</p>
                  <h3>Conclusion</h3>
                  <p>Aircompany Armenia offers convenient flights to Europe and the Middle East, ensuring comfort and reliability.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Armenia</p>
                  <h4>Headquarters:</h4>
                  <p>Yerevan</p>
                  <h4>Main Hub:</h4>
                  <p>Zvartnots International Airport (EVN)</p>
                  <h4>Website:</h4>
                  <a href="http://www.aircompanyarmenia.am" target="_blank" rel="noreferrer">aircompanyarmenia.am</a>
              `,
          },
      ],
    },
    arianaAfghanAirlines: {
      pageKey: "arianaAfghanAirlinesInfo",
      imageUrl: "/images/AK/FG.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Ariana Afghan Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Ariana Afghan Airlines is the national airline of Afghanistan, founded in 1955. It is the oldest airline in the country, providing passenger transportation both within Afghanistan and on international routes, including destinations across Asia, Europe, and the Middle East.</p>
                  <h3>Ariana Afghan Airlines Fleet</h3>
                  <p>The airline's fleet consists of aircraft suitable for medium and long-haul flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A310</td>
                              <td>2</td>
                              <td>Medium-haul flights with capacity for up to 280 passengers</td>
                          </tr>
                          <tr>
                              <td>Boeing 737-400</td>
                              <td>3</td>
                              <td>Regional and international flights with capacity for up to 160 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Ariana Afghan Airlines operates flights within Afghanistan and to international destinations, including:</p>
                  <ul>
                      <li><b>Kabul – Dubai:</b> regular flights several times a week.</li>
                      <li><b>Kabul – Peshawar:</b> several flights per week.</li>
                      <li><b>Kabul – Islamabad:</b> international service.</li>
                  </ul>
                  <p>The latest schedule and ticket booking are available on the airline's official website.</p>
                  <h3>Conclusion</h3>
                  <p>Ariana Afghan Airlines offers reliable and affordable flights both within Afghanistan and to international destinations.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Afghanistan</p>
                  <h4>Headquarters:</h4>
                  <p>Kabul</p>
                  <h4>Main Hub:</h4>
                  <p>Kabul International Airport (KBL)</p>
                  <h4>Website:</h4>
                  <a href="http://www.flyariana.com" target="_blank" rel="noreferrer">flyariana.com</a>
              `,
          },
      ],
    },
    georgianAirways: {
      pageKey: "georgianAirwaysInfo",
      imageUrl: "/images/AK/GNN.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>Georgian Airways</h1>
                  <h2>Foundation Date and History</h2>
                  <p>Georgian Airways is the national airline of Georgia, founded in 1994. The airline operates regular passenger flights on both international and domestic routes, ensuring accessible transportation services for Georgian and international travelers.</p>
                  <h3>Georgian Airways Fleet</h3>
                  <p>The airline's fleet consists of aircraft that efficiently serve both international and regional flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Embraer 190</td>
                              <td>2</td>
                              <td>Medium-haul flights with capacity for up to 100 passengers</td>
                          </tr>
                          <tr>
                              <td>Airbus A319</td>
                              <td>1</td>
                              <td>Medium-haul flights with capacity for up to 140 passengers</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>Georgian Airways operates regular flights to destinations including:</p>
                  <ul>
                      <li><b>Tbilisi – Moscow:</b> several flights per week.</li>
                      <li><b>Tbilisi – Istanbul:</b> regular flights.</li>
                      <li><b>Tbilisi – Milan:</b> international flights several times per week.</li>
                  </ul>
                  <p>The latest schedule and ticket booking are available on the airline's official website.</p>
                  <h3>Conclusion</h3>
                  <p>Georgian Airways provides convenient and reliable flights between Georgia and numerous international destinations.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Georgia</p>
                  <h4>Headquarters:</h4>
                  <p>Tbilisi</p>
                  <h4>Main Hub:</h4>
                  <p>Tbilisi International Airport (TBS)</p>
                  <h4>Website:</h4>
                  <a href="http://www.georgian-airways.com" target="_blank" rel="noreferrer">georgian-airways.com</a>
              `,
          },
      ],
    },
    myWayAirlines: {
      pageKey: "myWayAirlinesInfo",
      imageUrl: "/images/AK/MJ.png",
      generalInfo: [
          {
              content: `<article>
                  <h1>MyWay Airlines</h1>
                  <h2>Foundation Date and History</h2>
                  <p>MyWay Airlines is a Georgian airline founded in 2017, specializing in international flights. The company was created to enhance the accessibility of air travel between Georgia and other countries in the region.</p>
                  <h3>MyWay Airlines Fleet</h3>
                  <p>The airline's fleet consists of modern aircraft designed for comfortable flights:</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Aircraft Type</th>
                              <th>Quantity (approx.)</th>
                              <th>Features</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>Airbus A320</td>
                              <td>2</td>
                              <td>Medium-haul flights with capacity for up to 180 passengers</td>
                          </tr>
                          <tr>
                              <td>Airbus A319</td>
                              <td>1</td>
                              <td>Cost-effective and economical for international routes</td>
                          </tr>
                      </tbody>
                  </table>
                  <h3>Route Network</h3>
                  <p>MyWay Airlines operates flights to destinations including:</p>
                  <ul>
                      <li><b>Tbilisi – Istanbul:</b> regular flights several times a week.</li>
                      <li><b>Tbilisi – Moscow:</b> several flights per week.</li>
                  </ul>
                  <p>The latest schedule and ticket booking are available on the airline's official website.</p>
                  <h3>Conclusion</h3>
                  <p>MyWay Airlines offers convenient international flights with a high standard of service.</p>
              </article>`,
          },
      ],
      origin: [
          {
              content: `
                  <h4>Country:</h4>
                  <p>Georgia</p>
                  <h4>Headquarters:</h4>
                  <p>Tbilisi</p>
                  <h4>Main Hub:</h4>
                  <p>Tbilisi International Airport (TBS)</p>
                  <h4>Website:</h4>
                  <a href="https://www.mywayairlines.ge" target="_blank" rel="noreferrer">mywayairlines.ge</a>
              `,
          },
      ],
    },
    koreanAir: {
      pageKey: "koreanAirInfo",
      imageUrl: "/images/KR/KE.png",
      generalInfo: [
        {
          content: ` <article>
            <h1>Korean Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Korean Air is the flag carrier of South Korea, founded in 1969. It is one of the largest airlines in Asia, offering international and domestic flights.</p>
            <h3>Korean Air Fleet</h3>
            <p>The airline operates a diverse and modern fleet:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Special Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Boeing 777</td>
                  <td>~25</td>
                  <td>Long-haul international flights</td>
                </tr>
                <tr>
                  <td>Airbus A380</td>
                  <td>~10</td>
                  <td>High passenger capacity</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Korean Air operates flights to key destinations worldwide:</p>
            <ul>
              <li><b>Seoul (ICN) – New York (JFK):</b> Daily flights.</li>
              <li><b>Seoul (ICN) – Tokyo (NRT):</b> Several times a week.</li>
              <li><b>Seoul (ICN) – Paris (CDG):</b> Regular flights.</li>
            </ul>
            <p>For updated schedules, visit the official website.</p>
            <h3>Conclusion</h3>
            <p>Korean Air provides premium services with an extensive global network.</p>
          </article> `,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>South Korea</p>
            <h4>Headquarters:</h4>
            <p>Seoul</p>
            <h4>Main Hub:</h4>
            <p>Incheon International Airport (ICN)</p>
            <h4>Website:</h4>
            <a href="https://www.koreanair.com" target="_blank" rel="noreferrer">www.koreanair.com</a>
            `,
        },
      ],
    },
    jazeeraAirways: {
      pageKey: "jazeeraAirwaysInfo",
      imageUrl: "/images/AK/J9.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Jazeera Airways</h1>
            <h2>Foundation Date and History</h2>
            <p>Jazeera Airways is a budget airline from Kuwait, established in 2004. The airline offers affordable and convenient flights with regular and charter services across Asia, the Middle East, and Europe.</p>
            <h3>Jazeera Airways Fleet</h3>
            <p>The airline operates modern aircraft to ensure passenger safety and comfort:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~10</td>
                  <td>Short and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Airbus A320neo</td>
                  <td>~5</td>
                  <td>Reduced fuel consumption</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Jazeera Airways operates flights to several countries, including Uzbekistan:</p>
            <ul>
              <li>
                <b>Kuwait – Tashkent (TAS):</b> 1-2 times a week.
              </li>
            </ul>
            <p>Check the updated schedule on <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or the official airline website.</p>
            <h3>Conclusion</h3>
            <p>Jazeera Airways offers affordable and comfortable flights on key routes. Book your flights at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Kuwait</p>
            <h4>Headquarters:</h4>
            <p>Kuwait City</p>
            <h4>Main Hub:</h4>
            <p>Kuwait International Airport (KWI)</p>
            <h4>Website:</h4>
            <a href="https://www.jazeeraairways.com" target="_blank" rel="noreferrer">www.jazeeraairways.com</a>
          `,
        },
      ],
    },
    mahanAir: {
      pageKey: "mahanAirInfo",
      imageUrl: "/images/AK/W5.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Mahan Air</h1>
            <h2>Foundation Date and History</h2>
            <p>Mahan Air is a private Iranian airline founded in 1991. The company specializes in both domestic and international flights, offering passengers a high level of comfort and a wide range of destinations.</p>
            <h3>Mahan Air Fleet</h3>
            <p>The airline operates various types of aircraft to cover different flight distances:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A310</td>
                  <td>~5</td>
                  <td>Long-haul flights</td>
                </tr>
                <tr>
                  <td>Airbus A300</td>
                  <td>~10</td>
                  <td>Medium-haul flights</td>
                </tr>
                <tr>
                  <td>Airbus A340</td>
                  <td>~7</td>
                  <td>Long international routes</td>
                </tr>
                <tr>
                  <td>BAe 146</td>
                  <td>~5</td>
                  <td>Regional flights</td>
                </tr>
              </tbody>
            </table>
            <h3>Flight Schedule</h3>
            <p>Mahan Air operates flights to various countries, including Uzbekistan:</p>
            <ul>
              <li>
                <b>Tehran – Tashkent (TAS):</b> 1-2 times per week.
              </li>
            </ul>
            <p>For up-to-date schedules, check <a href="https://biletbor.me" target="_blank" rel="noreferrer">Biletbor.me</a> or visit the airline’s official website.</p>
            <h3>Conclusion</h3>
            <p>Mahan Air is one of the largest airlines in Iran, offering quality service and a wide choice of destinations. Book your tickets at the best prices on <a href="https://biletbor.me">Biletbor.me</a>.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Iran</p>
            <h4>Headquarters:</h4>
            <p>Tehran</p>
            <h4>Main Hub:</h4>
            <p>Imam Khomeini International Airport (IKA)</p>
            <h4>Website:</h4>
            <a href="https://www.mahan.aero" target="_blank" rel="noreferrer">www.mahan.aero</a>
          `,
        },
      ],
    },
    saudiaAirlines: {
      pageKey: "saudiaInfo_en",
      imageUrl: "/images/AK/SV.png",
      generalInfo: [
        {
          content: `<article>
            <h1>Saudia Airlines</h1>
            <h2>Foundation Year and History</h2>
            <p>Saudia Airlines was founded in 1945 and is the national carrier of Saudi Arabia. 
            It operates a vast network of international and domestic flights.</p>
            <h3>Fleet</h3>
            <p>Saudia utilizes modern Airbus and Boeing aircraft.</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Approximate Number</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320</td>
                  <td>~60</td>
                  <td>Short and medium-haul flights</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~30</td>
                  <td>International routes</td>
                </tr>
                <tr>
                  <td>Boeing 787</td>
                  <td>~15</td>
                  <td>Comfortable for economy and business class</td>
                </tr>
              </tbody>
            </table>
            <h3>Routes and Flights</h3>
            <p>Saudia Airlines operates direct flights from Tashkent to Jeddah and Riyadh.</p>
            <h3>Summary</h3>
            <p>Saudia Airlines offers a modern fleet and high service standards.</p>
            </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country: </h4>
            <p>Saudi Arabia</p> 
            <h4>Headquarters: </h4> 
            <p>Jeddah</p> 
            <h4>Main Hub: </h4>   
            <p>King Abdulaziz International Airport</p>
            <h4>Website:</h4>
            <a href="https://www.saudia.com" target="_blank" rel="noreferrer">www.saudia.com</a>
          `,
        },
      ],
    },
    aeroflot: {
      name: "Aeroflot",
      pageKey: "aeroflotInfo",
      imageUrl: "/images/AK/SU.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>Aeroflot</h1>
            <h2>Foundation and History</h2>
            <p>Aeroflot (PJSC "Aeroflot – Russian Airlines") is one of the oldest airlines in the world, founded in 1923. Over its long history, it has evolved from a Soviet-era monopoly to a major international carrier, offering flight tickets (aviabilet) for numerous routes, including services to Uzbekistan.</p>
            <h3>Aeroflot Fleet</h3>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Approx. Number</th>
                  <th>Key Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airbus A320/321</td>
                  <td>~100</td>
                  <td>Short- and medium-haul routes</td>
                </tr>
                <tr>
                  <td>Airbus A330</td>
                  <td>~20</td>
                  <td>Long-haul flights, enhanced comfort</td>
                </tr>
                <tr>
                  <td>Boeing 777</td>
                  <td>~20</td>
                  <td>Intercontinental routes, spacious cabins</td>
                </tr>
              </tbody>
            </table>
            <p>Aeroflot operates a modern fleet primarily consisting of Airbus and Boeing aircraft. This includes the short- and medium-haul Airbus A320/321 series, as well as the long-haul Boeing 777 and Airbus A330.</p>
            <h3>Flight Schedule from Uzbekistan</h3>
            <p>Aeroflot operates direct flights from these Uzbek cities to Moscow (Sheremetyevo):</p>
            <ul>
              <li>
                <b>Tashkent (TAS):</b> typically daily (7 times a week).
              </li>
              <li>
                <b>Samarkand (SKD):</b> 2–3 times a week.
              </li>
              <li>
                <b>Bukhara (BHK):</b> 1–2 times a week.
              </li>
            </ul>
            <p>Check tickets and the latest departure days on the official website or 
            <a href="https://biletbor.me" target="_blank" rel="noreferrer">biletbor.me</a>.</p>
            <h3>Summary</h3>
            <p>Aeroflot combines decades of experience, an extensive route network, and a modern fleet. If you are looking for flight tickets (aviabilet) to Moscow or other destinations, make sure to compare prices and take advantage of available promotions on biletbor.me or through the official website.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Russia</p>
            <h4>Headquarters:</h4>
            <p>Moscow</p>
            <h4>Main Hub:</h4>
            <p>Sheremetyevo International Airport (Terminals B/C), serving most international and regional flights</p>
            <h4>Website:</h4>
            <a href="https://www.aeroflot.ru" target="_blank" rel="noreferrer">www.aeroflot.ru</a>
          `,
        },
      ],
    },
    irAero: {
      pageKey: "irAeroInfo",
      imageUrl: "/images/AK/IO.svg",
      generalInfo: [
        {
          content: `<article>
            <h1>IR AERO</h1>
            <h2>Foundation Date and History</h2>
            <p>IR AERO is a Russian airline founded in 2011. The airline is focused on operating regional flights and connecting small and medium-sized cities within Russia. The first commercial flight was operated in 2011, and since then, the airline has expanded its network across the country.</p>
            <h3>IR AERO Fleet</h3>
            <p>The airline operates modern aircraft:</p>
            <table>
              <thead>
                <tr>
                  <th>Aircraft Type</th>
                  <th>Quantity (approx.)</th>
                  <th>Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAAB 340</td>
                  <td>5</td>
                  <td>Designed for 34 passengers, ideal for short and regional routes</td>
                </tr>
                <tr>
                  <td>Antonov An-24</td>
                  <td>3</td>
                  <td>Designed for 40 passengers, suitable for small airports and remote areas</td>
                </tr>
              </tbody>
            </table>
            <h3>Route Network</h3>
            <p>IR AERO operates flights between various cities in Russia, including:</p>
            <ul>
              <li><b>Moscow – Sochi:</b> Several flights per week.</li>
              <li><b>Murmansk – Arkhangelsk:</b> Regular flights on this route.</li>
              <li><b>Vladivostok – Khabarovsk:</b> Regular flights starting from 2023.</li>
            </ul>
            <p>For updated schedules and ticket booking, visit the airline's official website.</p>
            <h3>Conclusion</h3>
            <p>IR AERO provides convenient and reliable flights across Russia, specializing in regional routes and serving small airports.</p>
          </article>`,
        },
      ],
      origin: [
        {
          content: `
            <h4>Country:</h4>
            <p>Russia</p>
            <h4>Headquarters:</h4>
            <p>Moscow</p>
            <h4>Main Hub:</h4>
            <p>Domodedovo International Airport (DME)</p>
            <h4>Website:</h4>
            <a href="https://www.iraero.ru" target="_blank" rel="noreferrer">iraero.ru</a>
          `,
        },
      ],
    },
    spicejet: {
      pageKey: "spicejetInfo",
      imageUrl: "/images/AK/SG.png",
      generalInfo: [
        {
          content: `<article>
          <h1>SpiceJet: Affordable Flights to India and Beyond</h1>
          <p><a href="https://biletbor.me/airlines/spicejet" target="_blank" rel="noopener noreferrer">https://biletbor.me/airlines/spicejet</a></p>
          <h2>Foundation Year and History</h2>
          <p>SpiceJet is one of India’s largest budget airlines, founded in 2005. The airline offers affordable fares on both domestic and international routes, making air travel accessible to a broad audience.</p>
          <h2>SpiceJet Fleet</h2>
          <table>
            <thead>
              <tr>
                <th>Aircraft</th>
                <th>Number</th>
                <th>Main Routes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boeing 737</td>
                <td>~50</td>
                <td>Domestic and international routes</td>
              </tr>
              <tr>
                <td>Bombardier Q400</td>
                <td>~30</td>
                <td>Short-haul regional routes</td>
              </tr>
            </tbody>
          </table>

          <h2>Flight Schedule from Uzbekistan</h2>
          <ul>
            <li><b>Tashkent – Delhi (DEL):</b> 3 times per week</li>
          </ul>
          <p>For updated schedules and ticket prices, visit <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a>.</p>

          <h2>Conclusion</h2>
          <p>SpiceJet is a convenient and cost-effective choice for travel within India and beyond. Book your tickets now at <a href="https://biletbor.me" target="_blank" rel="noopener noreferrer">biletbor.me</a>.</p>
        </article>`,
        },
      ],
      origin: [
        {
          content: `
          <h4>Country:</h4>
          <p>India</p>
          <h4>Headquarters:</h4>
          <p>Gurgaon, Haryana</p>
          <h4>Main Hubs:</h4>
          <p>Indira Gandhi International Airport (DEL), Rajiv Gandhi International Airport (HYD)</p>
          <h4>Website:</h4>
          <a href="https://www.spicejet.com" target="_blank" rel="noopener noreferrer">www.spicejet.com</a>
        `,
        },
      ],
    },
  },
};

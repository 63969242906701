import { Fragment } from "react";

import classNames from "classnames";
import i18next from "i18next";
import moment from "moment";
import { AiOutlineRollback } from "react-icons/ai";
import { BiChevronRightCircle } from "react-icons/bi";
import { PiHandbagSimple, PiSuitcaseRolling } from "react-icons/pi";
import { RiRepeatFill } from "react-icons/ri";
import { useSelector } from "react-redux";

import { webTransferTimeFormat } from "../../../config";

import {
  checkIsNightFlightAndIsChangeAirport,
  getCityString,
  getDayByFormat,
  getFreeSeatsLabel,
  getServiceClass,
  getTransferTime,
} from "../../../utils";

import { useToggle } from "../../../hooks";

import {
  AirlineAndSitsBlock,
  AirlineLogo,
  ArrivalBlock,
  DepartureBlock,
  DetailsAndRulesBlock,
  DetailsButton,
  DurationBlock,
  FlightDetails,
  FlightNumber,
  FlightNumbersBlock,
  FlightSegment,
  FlightSegmentIcons,
  FlightSegmentTimeAircraft,
  FlightSegmentTimings,
  FlightTimingsBlock,
  Header,
  HeaderClassText,
  HeaderRouteTitle,
  InfoWrapper,
  RulesButton,
  SitsCount,
  TariffItem,
  TariffItemDetails,
  TariffItemIcons,
  TransferBlock,
  TransferTime,
  Wrapper,
} from "./style";

const RouteItemComponent = ({ route, onOpenRulesModal }) => {
  const { included, validatingSupplier } = useSelector(state => state.prebook);
  const { isTrue: isShowedDetails, toggle } = useToggle();

  const { language, t } = i18next;

  const departureSegment = route.segments[0];
  const { is_refund, is_change, baggage, hand_luggage, hand_luggage_weight, baggage_weight } = departureSegment;
  const arrivalSegment = route.segments[route.segments.length - 1];

  const renderDepartureInfo = () => {
    const { time, airport } = departureSegment.departure;

    return (
      <DepartureBlock>
        <span className="airport">{airport}</span>
        <span className="time">{time.split(" ")[1]}</span>
        <span className="date">{getDayByFormat(time, "ddd DD MMM", language)}</span>
      </DepartureBlock>
    );
  };

  const renderArrivalInfo = () => {
    const { time, airport } = arrivalSegment.arrival;

    return (
      <ArrivalBlock>
        <span className="airport">{airport}</span>
        <span className="time">{time.split(" ")[1]}</span>
        <span className="date">{getDayByFormat(time, "ddd DD MMM", language)}</span>
      </ArrivalBlock>
    );
  };

  const renderDurationTime = duration => {
    const hours = Math.trunc(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return `${hours} ${t("hour_short")} ${minutes} ${t("minutes_short")}`;
  };

  const calculatePercents = () => {
    const parts = [];

    route.segments.forEach((segment, index) => {
      if (index === 0) {
        parts.push({
          percent: ((segment.duration * 100) / route.duration).toFixed(2),
          type: "segment",
          info: `${segment.operation_supplier}-${segment.carrier_number}, ${renderDurationTime(segment.duration)}`,
        });
      } else {
        const prevDate = route.segments[segment.index - 1].arrival.time;
        const actualDate = segment.departure.time;
        const prevDateUnix = moment(prevDate, webTransferTimeFormat).unix();
        const actualDateUnix = moment(actualDate, webTransferTimeFormat).unix();
        const unixDifference = actualDateUnix - prevDateUnix;

        parts.push({
          percent: ((unixDifference * 100) / route.duration).toFixed(2),
          type: "transfer",
          info: `${t("transfer")}: ${getCityString(
            included,
            language,
            segment.departure.city,
            segment.departure.airport
          )}, ${segment.departure.airport}, (${getTransferTime(route, index, "ч", "м")})`,
        });
        parts.push({
          percent: ((segment.duration * 100) / route.duration).toFixed(2),
          type: "segment",
          info: `${segment.operation_supplier}-${segment.carrier_number}, ${renderDurationTime(segment.duration)}`,
        });
      }
    });

    return (
      <div className="flight-line">
        {parts.map((part, index) => (
          <div
            style={{
              height: part.type === "transfer" ? 1 : 3,
              width: `${part.percent}%`,
            }}
            key={index}
            title={part.info}
          >
            <span></span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Wrapper>
      <Header isOrangeBorder={route.index === 1 || route.index === 3}>
        <HeaderRouteTitle>
          {getCityString(included, language, departureSegment.departure.city, departureSegment.departure.airport)} ➞{" "}
          {getCityString(included, language, arrivalSegment.arrival.city, arrivalSegment.arrival.airport)}
        </HeaderRouteTitle>
        <HeaderClassText>{getServiceClass(departureSegment.service_class)}</HeaderClassText>
      </Header>

      <InfoWrapper>
        <AirlineAndSitsBlock>
          <AirlineLogo src={`/suppliers/${validatingSupplier}.png`} alt={`supplier-${validatingSupplier}-logo`} />
          <SitsCount>
            {getFreeSeatsLabel(departureSegment.free_seats, t("free_seats_1"), t("free_seats_2_4"), t("free_seats_5"))}
          </SitsCount>
        </AirlineAndSitsBlock>

        <FlightNumbersBlock>
          {route.segments.map((segment, index) => (
            <FlightNumber key={index}>
              {segment.operation_supplier}-{segment.carrier_number}
            </FlightNumber>
          ))}
        </FlightNumbersBlock>

        <FlightTimingsBlock>
          {renderDepartureInfo()}

          <DurationBlock>
            <div className="duration-time">{renderDurationTime(route.duration)}</div>
            {calculatePercents()}
            <div className="segments">
              {route.segments
                .slice(0, -1)
                .map(segment => segment.arrival.airport)
                .join(", ")}
            </div>
          </DurationBlock>

          {renderArrivalInfo()}
        </FlightTimingsBlock>

        <TariffItem>
          <TariffItemIcons>
            <div
              className={classNames({ notActive: !hand_luggage })}
              title={hand_luggage ? t("yes_hand_luggage") : t("no_hand_luggage")}
            >
              <PiHandbagSimple />
              {hand_luggage_weight ? <div className="hand-luggage-weight">{hand_luggage_weight}</div> : null}
            </div>

            <div className={classNames({ notActive: !baggage })} title={baggage ? t("yes_baggage") : t("no_baggage")}>
              <PiSuitcaseRolling />
              {baggage_weight ? <div className="baggage-weight">{baggage_weight}</div> : null}
            </div>

            <div className={classNames({ notActive: !is_change })} title={is_change ? t("yes_change") : t("no_change")}>
              <RiRepeatFill />
            </div>

            <div className={classNames({ notActive: !is_refund })} title={is_refund ? t("yes_refund") : t("no_refund")}>
              <AiOutlineRollback />
            </div>
          </TariffItemIcons>
        </TariffItem>
      </InfoWrapper>

      <DetailsAndRulesBlock>
        <DetailsButton onClick={toggle}>
          {t("flight_details")}{" "}
          <span className={classNames({ active: isShowedDetails })}>
            <BiChevronRightCircle />
          </span>
        </DetailsButton>
        <RulesButton onClick={onOpenRulesModal}>{t("tariff_rules")}</RulesButton>
      </DetailsAndRulesBlock>

      {isShowedDetails && (
        <FlightDetails>
          {route.segments.map((segment, index) => {
            let aircraftName = segment.aircraft;

            if (included.aircraft[segment.aircraft]) {
              aircraftName = included.aircraft[segment.aircraft].name.en;
            } else {
              aircraftName = "";
            }

            const isTransfer = index > 0;
            let transferTime = "";

            if (isTransfer) {
              transferTime = getTransferTime(route, index, t("hour_short"), t("minutes_short"));
            }

            const [isChangeAirport, isNightTransfer] = checkIsNightFlightAndIsChangeAirport(route, index);

            return (
              <Fragment key={index}>
                {isTransfer && (
                  <TransferBlock>
                    <TransferTime>
                      {getCityString(included, language, segment.departure.city, segment.departure.airport)}: Пересадка{" "}
                      {transferTime}
                      {isChangeAirport && `, ${t("change_airport")}`}
                      {isNightTransfer && `, ${t("night_flight")}`}
                    </TransferTime>
                  </TransferBlock>
                )}

                <FlightSegment>
                  <div>
                    <AirlineLogo
                      src={`/suppliers/${segment.operation_supplier}.png`}
                      alt={`supplier-${segment.operation_supplier}-logo`}
                    />
                  </div>

                  <div className="flightInfo">
                    <FlightSegmentTimeAircraft>
                      <div className="flightAndDuration">
                        <div>
                          {t("flight")} {segment.operation_supplier}-{segment.carrier_number}
                        </div>
                        <div className="duration">
                          {t("on_way")} {renderDurationTime(segment.duration)}
                        </div>
                      </div>
                      {aircraftName.length ? <div>{aircraftName}</div> : null}

                      <FlightSegmentTimings>
                        <div className="flightTime">
                          {segment.departure.time.split(" ")[1]}{" "}
                          <span>{getDayByFormat(segment.departure.time, "ddd DD MMM", language)}</span>
                        </div>
                        <div className="flightPlace">
                          <div className="flightPlaceCode">{segment.departure.airport}</div>
                          <div className="flightPlaceName">
                            <span>
                              {getCityString(included, language, segment.departure.city, segment.departure.airport)}
                            </span>
                          </div>
                        </div>
                      </FlightSegmentTimings>

                      <FlightSegmentTimings>
                        <div className="flightTime">
                          {segment.arrival.time.split(" ")[1]}{" "}
                          <span>{getDayByFormat(segment.arrival.time, "ddd DD MMM", language)}</span>
                        </div>
                        <div className="flightPlace">
                          <div className="flightPlaceCode">{segment.arrival.airport}</div>
                          <div className="flightPlaceName">
                            <span>
                              {getCityString(included, language, segment.arrival.city, segment.arrival.airport)}
                            </span>
                          </div>
                        </div>
                      </FlightSegmentTimings>
                    </FlightSegmentTimeAircraft>
                  </div>

                  <div className="baggageCabinInfo">
                    {getServiceClass(segment.service_class)}
                    <FlightSegmentIcons>
                      <div
                        className={classNames({ hasNotBaggage: !segment.hand_luggage })}
                        title={segment.hand_luggage ? t("yes_hand_luggage") : t("no_hand_luggage")}
                      >
                        <PiHandbagSimple />
                      </div>

                      <div
                        className={classNames({ hasNotBaggage: !segment.baggage })}
                        title={segment.baggage ? t("yes_baggage") : t("no_baggage")}
                      >
                        <PiSuitcaseRolling />
                      </div>
                    </FlightSegmentIcons>
                  </div>
                </FlightSegment>
              </Fragment>
            );
          })}
        </FlightDetails>
      )}
    </Wrapper>
  );
};

export default RouteItemComponent;

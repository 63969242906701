export const allAirlines = [
  {
    name: "aeroflot",
    partner: true,
    listName: {
      ru: {
        name: "Аэрофлот",
      },
      uz: {
        name: "Aeroflot",
      },
      en: {
        name: "Aeroflot",
      },
    },
    imageUrl: "/images/AK/SU.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SU.png",
  },
  {
    name: "uzairways",
    partner: true,
    listName: {
      ru: {
        name: "Uzbekistan Airways",
      },
      uz: {
        name: "O'zbekiston Havo Yo'llari",
      },
      en: {
        name: "Uzbekistan Airways",
      },
    },
    imageUrl: "/images/AK/HY.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/HY.png",
  },
  {
    name: "turkish",
    partner: true,
    listName: {
      ru: {
        name: "Турецкие Авиалинии",
      },
      uz: {
        name: "Turk Havo Yo'llari",
      },
      en: {
        name: "Turkish Airlines",
      },
    },
    imageUrl: "/images/AK/TK.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/TK.png",
  },
  {
    name: "belavia",
    partner: true,
    listName: {
      ru: {
        name: "Белавиа",
      },
      uz: {
        name: "Belavia",
      },
      en: {
        name: "Belavia",
      },
    },
    imageUrl: "/images/AK/B2.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/B2.png",
  },
  {
    name: "ural",
    partner: true,
    listName: {
      ru: {
        name: "Уральские Авиалинии",
      },
      uz: {
        name: "Ural Havo Yo'llari",
      },
      en: {
        name: "Ural Airlines",
      },
    },
    imageUrl: "/images/AK/U6.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/U6.png",
  },
  {
    name: "s7",
    partner: true,
    listName: {
      ru: {
        name: "S7 Airlines",
      },
      uz: {
        name: "S7 Havo Yo'llari",
      },
      en: {
        name: "S7 Airlines",
      },
    },
    imageUrl: "/images/AK/S7.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/S7.png",
  },
  {
    name: "qanotsharq",
    partner: true,
    listName: {
      ru: {
        name: "Qanot Sharq",
      },
      uz: {
        name: "Qanot Sharq",
      },
      en: {
        name: "Qanot Sharq",
      },
    },
    imageUrl: "/images/AK/HH.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/HH.png",
  },
  {
    name: "apg",
    partner: true,
    listName: {
      ru: {
        name: "APG Airlines",
      },
      uz: {
        name: "APG Havo Yo'llari",
      },
      en: {
        name: "APG Airlines",
      },
    },
    imageUrl: "/images/AK/GP.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/GP.png",
  },
  {
    name: "pobeda",
    partner: true,
    listName: {
      ru: {
        name: "Победа",
      },
      uz: {
        name: "Pobeda Hava Yo'llari",
      },
      en: {
        name: "Pobeda Airlines",
      },
    },
    imageUrl: "/images/AK/DP.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/DP.png",
  },
  {
    name: "airastana",
    partner: true,
    listName: {
      ru: {
        name: "Air Astana",
      },
      uz: {
        name: "Air Astana",
      },
      en: {
        name: "Air Astana",
      },
    },
    imageUrl: "/images/AK/KC.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/KC.png",
  },
  {
    name: "flyaristan",
    partner: true,
    listName: {
      ru: {
        name: "Fly Aristan",
      },
      uz: {
        name: "Fly Aristan",
      },
      en: {
        name: "Fly Aristan",
      },
    },
    imageUrl: "/images/AK/FS.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/FS.png",
  },
  {
    name: "aircairo",
    partner: false,
    listName: {
      ru: {
        name: "Air Cairo",
      },
      uz: {
        name: "Air Cairo",
      },
      en: {
        name: "Air Cairo",
      },
    },
    imageUrl: "/images/AK/SM.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SM.png",
  },
  {
    name: "airchina",
    partner: false,
    listName: {
      ru: {
        name: "Air China",
      },
      uz: {
        name: "Air China",
      },
      en: {
        name: "Air China",
      },
    },
    imageUrl: "/images/AK/CA.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/CA.png",
  },
  {
    name: "airmanas",
    partner: false,
    listName: {
      ru: {
        name: "Air Manas",
      },
      uz: {
        name: "Air Manasa",
      },
      en: {
        name: "Air Manas",
      },
    },
    imageUrl: "/images/AK/ZM.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ZM.png",
  },
  {
    name: "asianaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Asiana Airlines",
      },
      uz: {
        name: "Asiana Havo Yo'llari",
      },
      en: {
        name: "Asiana Airlines",
      },
    },
    imageUrl: "/images/AK/OZ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/OZ.png",
  },
  {
    name: "aviaTraffic",
    partner: false,
    listName: {
      ru: {
        name: "Avia Traffic",
      },
      uz: {
        name: "Avia Traffic",
      },
      en: {
        name: "Avia Traffic",
      },
    },
    imageUrl: "/images/AK/AT.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/AT.png",
  },
  {
    name: "azal",
    partner: false,
    listName: {
      ru: {
        name: "Azal",
      },
      uz: {
        name: "Azal",
      },
      en: {
        name: "Azal",
      },
    },
    imageUrl: "/images/AK/J2.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/J2.png",
  },
  {
    name: "azimuth",
    partner: false,
    listName: {
      ru: {
        name: "Азимут",
      },
      uz: {
        name: "Azimut",
      },
      en: {
        name: "Azimuth",
      },
    },
    imageUrl: "/images/AK/A4.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/A4.png",
  },
  {
    name: "azurair",
    partner: false,
    listName: {
      ru: {
        name: "Azur Air",
      },
      uz: {
        name: "Azur Air",
      },
      en: {
        name: "Azur Air",
      },
    },
    imageUrl: "/images/AK/ZF.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ZF.png",
  },
  {
    name: "spicejet",
    partner: false,
    listName: {
      ru: {
        name: "SpiceJet",
      },
      uz: {
        name: "SpiceJet",
      },
      en: {
        name: "SpiceJet",
      },
    },
    imageUrl: "/images/AK/SG.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SG.png",
  },
  {
    name: "saudiaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Саудовские авиалинии",
      },
      uz: {
        name: "Saudia Havo Yo'llari",
      },
      en: {
        name: "Saudi Airlines",  
      },
    },
    imageUrl: "/images/AK/SV.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SV.png",
  },
  {
    name: "alrosaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпании Алроса",
      },
      uz: {
        name: "Alrosa Havo Yo'llari",
      },
      en: {
        name: "Alrosa Airlines",  
      },
    },
    imageUrl: "images/AK/6R.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/6R.png",
  },
  {
    name: "turkmenistanAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпании Туркменистана",
      },
      uz: {
        name: "Turkmenistan Havo Yo'llari",
      },
      en: {
        name: " Turkmen Airlines",  
      },
    },
    imageUrl: "images/AK/T5.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/T5.png",
  },
  {
    name: "indigoAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Индиго авиалинии",
      },
      uz: {
        name: "Indigo Havo Yo'llari",
      },
      en: {
        name: "Indigo airlines",
      },
    },
    imageUrl: "/images/AK/6E.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/6E.png",
  },
  {
    name: "irAero",
    partner: false,
    listName: {
      ru: {
        name: "IrAero авиалинии",
      },
      uz: {
        name: "Ir-Aero Havo Yo'llari",
      },
      en: {
        name: "IRAERO airlines",
      },
    },
    imageUrl: "/images/AK/IO.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/IO.svg",
  },
  {
    name: "jazeeraAirways",
    partner: false,
    listName: {
      ru: {
        name: "Жазира авиалинии",
      },
      uz: {
        name: "Jazeera Havo Yo'llari",
      },
      en: {
        name: "Jazeera airlines",
      },
    },
    imageUrl: "images/AK/J9.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/J9.svg",
  },
  {
    name: "mahanAir",
    partner: false,
    listName: {
      ru: {
        name: "Махан Эйр",
      },
      uz: {
        name: "Mahan Havo Yo'llari",
      },
      en: {
        name: "Mahan airlines",
      },
    },
    imageUrl: "/images/AK/W5.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/W5.png",
  },
  {
    name: "koreanAir",
    partner: false,
    listName: {
      ru: {
        name: "Корейские авиалинии",
      },
      uz: {
        name: "Koreya Havo Yo'llari",
      },
      en: {
        name: "Korean airlines",
      },
    },
    imageUrl: "images/AK/KE.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/KE.png",
  },
  {
    name: "arianaAfghanAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Ариана Афган",
      },
      uz: {
        name: "Afghan Havo Yo'llari",
      },
      en: {
        name: " afgan Airlines",  
      },
    },
    imageUrl: "images/AK/FG.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/FG.png",
  },
  {
    name: "georgianAirways",
    partner: false,
    listName: {
      ru: {
        name: "Грузинские авиалинии",
      },
      uz: {
        name: "Gruziya Havo Yo'llari",
      },
      en: {
        name: " georgian Airlines",  
      },
    },
    imageUrl: "images/AK/GNN.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/GNN.png",
  },
  {
    name: "myWayAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпании Myway airlines",
      },
      uz: {
        name: "Myway Havo Yo'llari",
      },
      en: {
        name: " MYWAY Airlines",  
      },
    },
    imageUrl: "images/AK/MJ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/MJ.png",
  },
  {
    name: "aircompanyArmenia",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Армения",
      },
      uz: {
        name: "Armeniya Havo Yo'llari",
      },
      en: {
        name: " armen Airlines",  
      },
    },
    imageUrl: "images/AK/JI.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/JI.png",
  },
  {
    name: "middleEastAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Ближневосточные авиалинии",
      },
      uz: {
        name: "MiddleEast Havo Yo'llari",
      },
      en: {
        name: " middleEastAirlines Airlines",  
      },
    },
    imageUrl: "/images/AK/ME.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ME.png",
  },
  {
    name: "syrianAir",
    partner: false,
    listName: {
      ru: {
        name: "Сириийские авиалинии",
      },
      uz: {
        name: "Siriya Havo Yo'llari",
      },
      en: {
        name: " syrianAir Airlines",  
      },
    },
    imageUrl: "/images/AK/RB.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/RB.png",
  },
  {
    name: "iranAsemanAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Иранские авиалинии",
      },
      uz: {
        name: "Eron Havo Yo'llari",
      },
      en: {
        name: " Iran AsemanAirlines Airlines",  
      },
    },
    imageUrl: "/images/AK/IB.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/IB.png",
  },
  {
    name: "qeshmAir",
    partner: false,
    listName: {
      ru: {
        name: "QeshmAir Авиалинии",
      },
      uz: {
        name: "Qesh Havo Yo'llari",
      },
      en: {
        name: " qeshmAir Airlines",  
      },
    },
    imageUrl: "images/AK/QB.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/QB.png",
  },
  {
    name: "kishAir",
    partner: false,
    listName: {
      ru: {
        name: "KishAir Авиалинии",
      },
      uz: {
        name: "Kish Havo Yo'llari",
      },
      en: {
        name: " kishAir Airlines",  
      },
    },
    imageUrl: "/images/AK/Y9.jpeg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/Y9.jpeg",
  },
  {
    name: "gulfAir",
    partner: false,
    listName: {
      ru: {
        name: "Гульф Авиалинии",
      },
      uz: {
        name: "Gulf Havo Yo'llari",
      },
      en: {
        name: "Gulf India Airlines",  
      },
    },
    imageUrl: "/images/AK/GF.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/GF.png",
  },
  {
    name: "omanAir",
    partner: false,
    listName: {
      ru: {
        name: "Оман Авиалинии",
      },
      uz: {
        name: "Oman Havo Yo'llari",
      },
      en: {
        name: "Oman India Airlines",  
      },
    },
    imageUrl: "images/AK/WY.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/WY.png",
  },
  {
    name: "salamAir",
    partner: false,
    listName: {
      ru: {
        name: "Салам Авиалинии",
      },
      uz: {
        name: "Salam Havo Yo'llari",
      },
      en: {
        name: "Salam India Airlines",  
      },
    },
    imageUrl: "/images/AK/OV.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/OV.png",
  },
  {
    name: "pia",
    partner: false,
    listName: {
      ru: {
        name: "Пиа Авиалинии",
      },
      uz: {
        name: "Pia Havo Yo'llari",
      },
      en: {
        name: "Pia India Airlines",  
      },
    },
    imageUrl: "/images/AK/PK.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/PK.png",
  },
  {
    name: "airblue",
    partner: false,
    listName: {
      ru: {
        name: "Аирблю Авиалинии",
      },
      uz: {
        name: "Airblue Havo Yo'llari",
      },
      en: {
        name: "Airblue India Airlines",  
      },
    },
    imageUrl: "/images/AK/PA.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/PA.png",

  },
  {
    name: "sereneAir",
    partner: false,
    listName: {
      ru: {
        name: "Серен Авиалинии",
      },
      uz: {
        name: "Serene Havo Yo'llari",
      },
      en: {
        name: "Serene India Airlines",  
      },
    },
    imageUrl: "/images/AK/ER.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/ER.png",
  },
  {
    name: "airIndia",
    partner: false,
    listName: {
      ru: {
        name: "Индия Авиалинии",
      },
      uz: {
        name: "Air India Havo Yo'llari",
      },
      en: {
        name: "Air India Airlines",  
      },
    },
    imageUrl: "images/AK/AI.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/AI.png",
  },
  {
    name: "vistara",
    partner: false,
    listName: {
      ru: {
        name: "Вистара Авиалинии",
      },
      uz: {
        name: "Vistara Havo Yo'llari",
      },
      en: {
        name: "Vistara Airlines",  
      },
    },
    imageUrl: "/images/AK/UK.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/UK.png",
  },
    {
    name: "centrumAir",
    pageKey: "centrumInfo",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Centrum",
      },
      uz: {
        name: "Centrum Havo Yo'llari",
      },
      en: {
        name: "CENTRUM",
      },
    },
    imageUrl: "images/AK/C6.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/C6.png",
  },
  {
    name: "chinaSouthern",
    partner: false,
    listName: {
      ru: {
        name: "Китайские южные авиалинии",
      },
      uz: {
        name: "China-Southern Havo Yo'llari",
      },
      en: {
        name: "CHINA-SOUTHERN",
      },
    },
    imageUrl: "/images/AK/CZ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/CZ.png",
  },
  {
    name: "egyptAir",
    partner: false,
    listName: {
      ru: {
        name: "Египетские Авиалинии",
      },
      uz: {
        name: "Egypt Havo Yo'llari",
      },
      en: {
        name: "EGYPTAIR",
      },
    },
    imageUrl: "/images/AK/MS.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/MS.png",
  },
  {
    name: "emiratesAir",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Emirates",
      },
      uz: {
        name: "Emirates Havo Yo'llari",
      },
      en: {
        name: "EMIRATES AIRLINES",
      },
    },
    imageUrl: "/images/AK/EK.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/EK.png",
  },
  {
    name: "kuwaitAirways",
    partner: false,
    listName: {
      ru: {
        name: "Кувейтские Авиалинии",
      },
      uz: {
        name: "Kuwait Havo Yo'llari",
      },
      en: {
        name: "KUWAIT AIRLINES",
      },
    },
    imageUrl: "/images/AK/KU.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/KU.png",
  },
  {
    name: "flydubaiAir",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Flydubai Airlines",
      },
      uz: {
        name: "Flydubai Havo Yo'llari",
      },
      en: {
        name: "FLYDUBAI",
      },
    },
    imageUrl: "/images/AK/FZ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/FZ.png",
  },
  {
    name: "ilkarAir",
    partner: false,
    listName: {
      ru: {
        name: "Авиакомпания Ilkar",
      },
      uz: {
        name: "Ilkar Havo Yo'llari",
      },
      en: {
        name: "ilkar airlines",
      },
    },
    imageUrl: "/images/AK/EO.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/EO.png",
  },
  {
    name: "kamair",
    partner: false,
    listName: {
      ru: {
        name: "Авиалинии Кам ",
      },
      uz: {
        name: "Kam Havo Yo'llari",
      },
      en: {
        name: "Kam airlines",
      },
    },
    imageUrl: "/images/AK/RQ.png",
    smImageUrl: "public/images/AK/RQ.png",
  },
  {
    name: "tajikAir",
    partner: false,
    listName: {
      ru: {
        name: "Таджикские авиалинии",
      },
      uz: {
        name: "Tajik Havo Yo'llari",
      },
      en: {
        name: "Tajik airlines",
      },
    },
    imageUrl: "/images/AK/7J.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/7J.png",
  },
  {
    name: "somonAir",
    partner: false,
    listName: {
      ru: {
        name: "Сомон авиалинии",
      },
      uz: {
        name: "Somon Havo Yo'llari",
      },
      en: {
        name: "Somon airlines",
      },
    },
    imageUrl: "/images/AK/SZ.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/SZ.png",
  },
  {
    name: "silkAir",
    partner: false,
    listName: {
      ru: {
        name: "Силк авиалинии",
      },
      uz: {
        name: "Silk Havo Yo'llari",
      },
      en: {
        name: "Silk airlines",
      },
    },
    imageUrl: "/images/AK/US.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/US.png",
  },
  {
    name: "pegasusAir",
    partner: false,
    listName: {
      ru: {
        name: "Пегас авиалинии",
      },
      uz: {
        name: "Pegasus Havo Yo'llari",
      },
      en: {
        name: "Pegasus airlines",
      },
    },
    imageUrl: "/images/AK/PC.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/PC.png",
  },
  {
    name: "nordwindAir",
    partner: false,
    listName: {
      ru: {
        name: "Северный ветер авиалинии",
      },
      uz: {
        name: "Nordwind Havo Yo'llari",
      },
      en: {
        name: "Nordwind airlines",
      },
    },
    imageUrl: "/images/AK//N4.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/N4.png",
  },
  {
    name: "lufthansaAir",
    partner: false,
    listName: {
      ru: {
        name: "Lufthansa авиалинии",
      },
      uz: {
        name: "Lufthansa Havo Yo'llari",
      },
      en: {
        name: "LufthansaAir airlines",
      },
    },
    imageUrl: "images/AK/LH.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/LH.png",
  },
  {
    name: "auroraAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Aurora Airlines"
      },
      uz: {
        name: "Aurora Airlines"
      },
      en: {
        name: "Aurora Airlines"
      }
    },
    imageUrl: "/images/AK/AU.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/AU.svg"
  },
  {
    name: "yamalAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Yamal Airlines"
      },
      uz: {
        name: "Yamal Airlines"
      },
      en: {
        name: "Yamal Airlines"
      }
    },
    imageUrl: "/images/AK/YM.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/YM.svg"
  },
  {
    name: "yakutiaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Yakutia Airlines"
      },
      uz: {
        name: "Yakutia Airlines"
      },
      en: {
        name: "Yakutia Airlines"
      }
    },
    imageUrl: "/images/AK/YA.png",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/YA.png"
  },
  {
    name: "rossiyaAirlines",
    partner: false,
    listName: {
      ru: {
        name: "Rossiya Airlines"
      },
      uz: {
        name: "Rossiya Airlines"
      },
      en: {
        name: "Rossiya Airlines"
      }
    },
    imageUrl: "/images/AK/RO.svg",
    smImageUrl: "https://images.kiwi.com/airlines/64x64/RO.svg"
  }
  
];

import { useState } from "react";

import { useSelector } from "react-redux";

import { RulesModalComponent } from "../../rulesModal";

import RouteItemComponent from "../RouteItemComponent";

import { Wrapper } from "./style";

const RoutesDataComponent = () => {
  const { routes, fareRules, included } = useSelector(state => state.prebook);

  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  const handleOpenRulesModal = () => {
    setIsRulesModalOpen(true);
  };

  return (
    <>
      <Wrapper>
        {routes.map((route, index) => (
          <RouteItemComponent key={index} route={route} onOpenRulesModal={handleOpenRulesModal} />
        ))}
      </Wrapper>

      {fareRules.length > 0 && (
        <RulesModalComponent
          openModal={isRulesModalOpen}
          handleCloseModal={() => setIsRulesModalOpen(false)}
          fareRules={fareRules}
          routes={routes}
          included={included}
        />
      )}
    </>
  );
};

export default RoutesDataComponent;

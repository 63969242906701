const metaTitles = {
  ru: {
    homePage: "Онлайн-касса BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airastanaInfo: "Air Astana: обзор авиакомпании, aviabilet и направления из Узбекистана",
    aircairoInfo: "Air Cairo: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airchinaInfo: "Air China: обзор авиакомпании, aviabilet и направления из Узбекистана",
    airmanasInfo: "Air Manas: обзор авиакомпании, aviabilet и направления из Узбекистана",
    asianaAirlinesInfo: "Asiana Airlines: обзор авиакомпании, aviabilet и направления из Узбекистана",
    aviaTrafficInfo: "Avia Traffic Company: обзор авиакомпании, aviabilet и направления из Узбекистана",
    azalInfo: "AZAL (Azerbaijan Airlines): обзор авиакомпании, aviabilet и направления из Узбекистана",
    azimuthInfo: "Azimuth: обзор авиакомпании, aviabilet и направления из Узбекистана",
    azurairInfo: "Azur Air: обзор авиакомпании, aviabilet и направления из Узбекистана",
    belaviaInfo: "Belavia: обзор авиакомпании, aviabilet и направления из Узбекистана",
    uzairwaysInfo: "O'zbekiston Havo Yo'llari – chiptalar, jadval, samolyot parki, yo'nalishlar",
    s7Info: "S7 Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
    turkishInfo: "Turkish Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
    qanotsharqInfo: "Qanot Sharq – авиакомпания Узбекистана, маршруты, билеты",
    uralInfo: "Ural Airlines – авиабилеты, флот, расписание рейсов из Узбекистана",
    pobedaInfo: "Pobeda – российский лоукостер, рейсы, авиабилеты, расписание",
    spicejetInfo: "SpiceJet – авиабилеты в Индию по выгодным ценам | Дешевые рейсы из Узбекистана",
    yakutiaInfo: "Yakutia Airlines: обзор авиакомпании, авиабилеты и направления",
    yamalInfo: "Yamal Airlines: обзор авиакомпании, авиабилеты и направления",
    smartaviaInfo: "Smartavia: обзор авиакомпании, авиабилеты и направления",
    auroraInfo: "Aurora Airlines: обзор авиакомпании, авиабилеты и направления",
    alrosaInfo: "Alrosa Airlines: обзор авиакомпании, авиабилеты и направления",
    uvtAeroInfo: "UVT Aero: обзор авиакомпании, авиабилеты и направления",
    flyAristanInfo: "FlyArystan: обзор авиакомпании, авиабилеты и направления",
    tezJetInfo: "TezJet: обзор авиакомпании, авиабилеты и направления",
    turkmenistanAirlinesInfo: "Turkmenistan Airlines: обзор авиакомпании, авиабилеты и направления",
    arianaAfghanInfo: "Ariana Afghan Airlines: обзор авиакомпании, авиабилеты и направления",
    georgianAirwaysInfo: "Georgian Airways: обзор авиакомпании, авиабилеты и направления",
    myWayInfo: "MyWay Airlines: обзор авиакомпании, авиабилеты и направления",
    aircompanyArmeniaInfo: "Aircompany Armenia: обзор авиакомпании, авиабилеты и направления",
    flyOneArmeniaInfo: "FlyOne Armenia: обзор авиакомпании, авиабилеты и направления",
    kishAirInfo: "Kish Air: обзор авиакомпании, авиабилеты и направления",
    qeshmAirInfo: "Qeshm Air: обзор авиакомпании, авиабилеты и направления",
    iranAsemanInfo: "Iran Aseman Airlines: обзор авиакомпании, авиабилеты и направления",
    iraqiAirwaysInfo: "Iraqi Airways: обзор авиакомпании, авиабилеты и направления",
    syrianAirInfo: "SyrianAir: обзор авиакомпании, авиабилеты и направления",
    middleEastAirlinesInfo: "Middle East Airlines: обзор авиакомпании, авиабилеты и направления",
    saudiaInfo: "Saudia: обзор авиакомпании, авиабилеты и направления",
    flynasInfo: "Flynas: обзор авиакомпании, авиабилеты и направления",
    royalJordanianInfo: "Royal Jordanian: обзор авиакомпании, авиабилеты и направления",
    jordanAviationInfo: "Jordan Aviation: обзор авиакомпании, авиабилеты и направления",
    gulfAirInfo: "Gulf Air: обзор авиакомпании, авиабилеты и направления",
    kuwaitAirwaysInfo: "Kuwait Airways: обзор авиакомпании, авиабилеты и направления",
    omanAirInfo: "Oman Air: обзор авиакомпании, авиабилеты и направления",
    salamAirInfo: "SalamAir: обзор авиакомпании, авиабилеты и направления",
    piaInfo: "Pakistan International Airlines: обзор авиакомпании, авиабилеты и направления",
    airblueInfo: "airblue: обзор авиакомпании, авиабилеты и направления",
    sereneAirInfo: "SereneAir: обзор авиакомпании, авиабилеты и направления",
    airIndiaInfo: "Air India: обзор авиакомпании, авиабилеты и направления",
    indigoInfo: "IndiGo: обзор авиакомпании, авиабилеты и направления",
    spiceJetInfo: "SpiceJet: обзор авиакомпании, авиабилеты и направления",
    vistaraInfo: "Vistara: обзор авиакомпании, авиабилеты и направления",
    goFirstInfo: "Go First: обзор авиакомпании, авиабилеты и направления",
    nepalAirlinesInfo: "Nepal Airlines: обзор авиакомпании, авиабилеты и направления",
    bimanBangladeshInfo: "Biman Bangladesh: обзор авиакомпании, авиабилеты и направления",
    sriLankanAirlinesInfo: "SriLankan Airlines: обзор авиакомпании, авиабилеты и направления",
    chinaEasternInfo: "China Eastern Airlines: обзор авиакомпании, авиабилеты и направления",
    hainanAirlinesInfo: "Hainan Airlines: обзор авиакомпании, авиабилеты и направления",
    xiamenAirlinesInfo: "Xiamen Airlines: обзор авиакомпании, авиабилеты и направления",
    beijingCapitalInfo: "Beijing Capital Airlines: обзор авиакомпании, авиабилеты и направления",
    luckyAirInfo: "Lucky Air: обзор авиакомпании, авиабилеты и направления",
    cathayPacificInfo: "Cathay Pacific: обзор авиакомпании, авиабилеты и направления",
    miatInfo: "MIAT Mongolian Airlines: обзор авиакомпании, авиабилеты и направления",
    airSerbiaInfo: "Air Serbia: обзор авиакомпании, авиабилеты и направления",
    airMontenegroInfo: "Air Montenegro: обзор авиакомпании, авиабилеты и направления",
    taromInfo: "Tarom: обзор авиакомпании, авиабилеты и направления",
    bulgariaAirInfo: "Bulgaria Air: обзор авиакомпании, авиабилеты и направления",
    aegeanAirlinesInfo: "Aegean Airlines: обзор авиакомпании, авиабилеты и направления",
    ukraineIntlAirlinesInfo: "Ukraine International Airlines: обзор авиакомпании, авиабилеты и направления",
    windroseAirlinesInfo: "Windrose Airlines: обзор авиакомпании, авиабилеты и направления",
    skyUpAirlinesInfo: "SkyUp Airlines: обзор авиакомпании, авиабилеты и направления",
  },
  en: {
    homePage: "Online ticketing BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
    spicejetInfo: "SpiceJet – Affordable Flights to India | Tashkent to Delhi Tickets",
    airastanaInfo: "Air Astana: airline overview, tickets, and routes from Uzbekistan",
    aircairoInfo: "Air Cairo: airline overview, tickets, and routes from Uzbekistan",
    airchinaInfo: "Air China: airline overview, tickets, and routes from Uzbekistan",
    airmanasInfo: "Air Manas: airline overview, tickets, and routes from Uzbekistan",
    asianaAirlinesInfo: "Asiana Airlines: airline overview, tickets, and routes from Uzbekistan",
    aviaTrafficInfo: "Avia Traffic Company: airline overview, tickets, and routes from Uzbekistan",
    azalInfo: "AZAL (Azerbaijan Airlines): airline overview, tickets, and routes from Uzbekistan",
    azimuthInfo: "Azimuth: airline overview, tickets, and routes from Uzbekistan",
    azurairInfo: "Azur Air: airline overview, tickets, and routes from Uzbekistan",
    belaviaInfo: "Belavia: airline overview, tickets, and routes from Uzbekistan",
    uzairwaysInfo: "Uzbekistan Airways – tickets, schedule, aircraft fleet, routes",
    s7Info: "S7 Airlines – tickets, fleet, flight schedule from Uzbekistan",
    turkishInfo: "Turkish Airlines – tickets, fleet, flight schedule from Uzbekistan",
    qanotsharqInfo: "Qanot Sharq – Uzbekistan airline, routes, tickets",
    uralInfo: "Ural Airlines – tickets, fleet, flight schedule from Uzbekistan",
    rossiyaAirlinesInfo: "Rossiya Airlines: airline overview, tickets, and destinations",
    pobedaInfo: "Pobeda Airlines: airline overview, cheap tickets, and destinations",
    yakutiaInfo: "Yakutia Airlines: airline overview, tickets, and destinations",
    yamalInfo: "Yamal Airlines: airline overview, tickets, and destinations",
    smartaviaInfo: "Smartavia: airline overview, tickets, and destinations",
    auroraInfo: "Aurora Airlines: airline overview, tickets, and destinations",
    alrosaInfo: "Alrosa Airlines: airline overview, tickets, and destinations",
    uvtAeroInfo: "UVT Aero: airline overview, tickets, and destinations",
    flyAristanInfo: "FlyArystan: airline overview, tickets, and destinations",
    tezJetInfo: "TezJet: airline overview, tickets, and destinations",
    turkmenistanAirlinesInfo: "Turkmenistan Airlines: airline overview, tickets, and destinations",
    arianaAfghanInfo: "Ariana Afghan Airlines: airline overview, tickets, and destinations",
    georgianAirwaysInfo: "Georgian Airways: airline overview, tickets, and destinations",
    myWayInfo: "MyWay Airlines: airline overview, tickets, and destinations",
    aircompanyArmeniaInfo: "Aircompany Armenia: airline overview, tickets, and destinations",
    flyOneArmeniaInfo: "FlyOne Armenia: airline overview, tickets, and destinations",
    kishAirInfo: "Kish Air: airline overview, tickets, and destinations",
    qeshmAirInfo: "Qeshm Air: airline overview, tickets, and destinations",
    iranAsemanInfo: "Iran Aseman Airlines: airline overview, tickets, and destinations",
    iraqiAirwaysInfo: "Iraqi Airways: airline overview, tickets, and destinations",
    syrianAirInfo: "SyrianAir: airline overview, tickets, and destinations",
    middleEastAirlinesInfo: "Middle East Airlines: airline overview, tickets, and destinations",
    saudiaInfo: "Saudia: airline overview, tickets, and destinations",
    flynasInfo: "Flynas: airline overview, tickets, and destinations",
    royalJordanianInfo: "Royal Jordanian: airline overview, tickets, and destinations",
    jordanAviationInfo: "Jordan Aviation: airline overview, tickets, and destinations",
    gulfAirInfo: "Gulf Air: airline overview, tickets, and destinations",
    kuwaitAirwaysInfo: "Kuwait Airways: airline overview, tickets, and destinations",
    omanAirInfo: "Oman Air: airline overview, tickets, and destinations",
    salamAirInfo: "SalamAir: airline overview, tickets, and destinations",
    piaInfo: "Pakistan International Airlines: airline overview, tickets, and destinations",
    airblueInfo: "airblue: airline overview, tickets, and destinations",
    sereneAirInfo: "SereneAir: airline overview, tickets, and destinations",
    airIndiaInfo: "Air India: airline overview, tickets, and destinations",
    indigoInfo: "IndiGo: airline overview, tickets, and destinations",
    spiceJetInfo: "SpiceJet: airline overview, tickets, and destinations",
    vistaraInfo: "Vistara: airline overview, tickets, and destinations",
    goFirstInfo: "Go First: airline overview, tickets, and destinations",
    nepalAirlinesInfo: "Nepal Airlines: airline overview, tickets, and destinations",
    bimanBangladeshInfo: "Biman Bangladesh: airline overview, tickets, and destinations",
    sriLankanAirlinesInfo: "SriLankan Airlines: airline overview, tickets, and destinations",
    chinaEasternInfo: "China Eastern Airlines: airline overview, tickets, and destinations",
    hainanAirlinesInfo: "Hainan Airlines: airline overview, tickets, and destinations",
    xiamenAirlinesInfo: "Xiamen Airlines: airline overview, tickets, and destinations",
    beijingCapitalInfo: "Beijing Capital Airlines: airline overview, tickets, and destinations",
    luckyAirInfo: "Lucky Air: airline overview, tickets, and destinations",
    cathayPacificInfo: "Cathay Pacific: airline overview, tickets, and destinations",
    miatInfo: "MIAT Mongolian Airlines: airline overview, tickets, and destinations",
    airSerbiaInfo: "Air Serbia: airline overview, tickets, and destinations",
    airMontenegroInfo: "Air Montenegro: airline overview, tickets, and destinations",
    taromInfo: "Tarom: airline overview, tickets, and destinations",
    bulgariaAirInfo: "Bulgaria Air: airline overview, tickets, and destinations",
    aegeanAirlinesInfo: "Aegean Airlines: airline overview, tickets, and destinations",
    ukraineIntlAirlinesInfo: "Ukraine International Airlines: airline overview, tickets, and destinations",
    windroseAirlinesInfo: "Windrose Airlines: airline overview, tickets, and destinations",
    skyUpAirlinesInfo: "SkyUp Airlines: airline overview, tickets, and destinations",
  },
  uz: {
    homePage: "Onlayn kassa BiletBor.me",
    aeroflotInfo: "Аэрофлот: обзор авиакомпании, aviabilet и направления из Узбекистана",
    uzairwaysInfo: "O'zbekiston Havo Yo'llari – chiptalar, jadval, samolyot parki, yo'nalishlar",
    s7Info: "S7 Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
    turkishInfo: "Turkish Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
    qanotsharqInfo: "Qanot Sharq – O‘zbekiston aviakompaniyasi, reyslar va chipta narxlari",
    uralInfo: "Ural Airlines – aviabiletlar, yo‘nalishlar, O‘zbekistondan reyslar",
    pobedaInfo: "Pobeda – Rossiya loukosteri, reyslar, aviabiletlar, jadval",
    spicejetInfo: "SpiceJet – Hindistonga arzon aviabiletlar | Toshkent – Dehli reyslari",
    yakutiaInfo: "Yakutia Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    yamalInfo: "Yamal Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    smartaviaInfo: "Smartavia: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    auroraInfo: "Aurora Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    alrosaInfo: "Alrosa Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    uvtAeroInfo: "UVT Aero: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    flyAristanInfo: "FlyArystan: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    tezJetInfo: "TezJet: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    turkmenistanAirlinesInfo: "Turkmenistan Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    arianaAfghanInfo: "Ariana Afghan Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    georgianAirwaysInfo: "Georgian Airways: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    myWayInfo: "MyWay Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    aircompanyArmeniaInfo: "Aircompany Armenia: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    flyOneArmeniaInfo: "FlyOne Armenia: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    kishAirInfo: "Kish Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    qeshmAirInfo: "Qeshm Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    iranAsemanInfo: "Iran Aseman Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    iraqiAirwaysInfo: "Iraqi Airways: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    syrianAirInfo: "SyrianAir: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    middleEastAirlinesInfo: "Middle East Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    saudiaInfo: "Saudia: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    flynasInfo: "Flynas: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    royalJordanianInfo: "Royal Jordanian: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    jordanAviationInfo: "Jordan Aviation: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    gulfAirInfo: "Gulf Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    kuwaitAirwaysInfo: "Kuwait Airways: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    omanAirInfo: "Oman Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    salamAirInfo: "SalamAir: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    piaInfo: "Pakistan International Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    airblueInfo: "airblue: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    sereneAirInfo: "SereneAir: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    airIndiaInfo: "Air India: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    indigoInfo: "IndiGo: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    spiceJetInfo: "SpiceJet: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    vistaraInfo: "Vistara: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    goFirstInfo: "Go First: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    nepalAirlinesInfo: "Nepal Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    bimanBangladeshInfo: "Biman Bangladesh: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    sriLankanAirlinesInfo: "SriLankan Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    chinaEasternInfo: "China Eastern Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    hainanAirlinesInfo: "Hainan Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    xiamenAirlinesInfo: "Xiamen Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    beijingCapitalInfo: "Beijing Capital Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    luckyAirInfo: "Lucky Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    cathayPacificInfo: "Cathay Pacific: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    miatInfo: "MIAT Mongolian Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    airSerbiaInfo: "Air Serbia: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    airMontenegroInfo: "Air Montenegro: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    taromInfo: "Tarom: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    bulgariaAirInfo: "Bulgaria Air: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    aegeanAirlinesInfo: "Aegean Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    ukraineIntlAirlinesInfo: "Ukraine International Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    windroseAirlinesInfo: "Windrose Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
    skyUpAirlinesInfo: "SkyUp Airlines: aviakompaniya sharhi, aviabiletlar va yo'nalishlar",
  },
};

export default metaTitles;

import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({}));

export const Header = styled("div")(({ theme, isOrangeBorder }) => ({
  ...theme.mixins.flexCenterBetween,
  ...theme.paddings(0, 10),
  height: 32,
  border: `1px solid ${isOrangeBorder ? "#ff8100" : "#358ed3"}`,
  color: "#151515",
  fontSize: 14,

  [theme.down("sm")]: {
    ...theme.paddings(6, 10),
    flexWrap: "wrap",
    height: "auto",
    gap: 10,
  },
}));

export const HeaderRouteTitle = styled("div")(({ theme }) => ({}));

export const HeaderClassText = styled("div")(({ theme }) => ({
  [theme.down("sm")]: {
    textAlign: "right",
    flexGrow: 1,
  },
}));

export const InfoWrapper = styled("div")(({ theme }) => ({
  ...theme.paddings(10, 10, 0),
  display: "flex",
  gap: 30,

  [theme.down("sm")]: {
    ...theme.paddings(10, 0, 0),
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

export const AirlineAndSitsBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  alignItems: "center",
  gap: 5,
  width: 90,

  [theme.down("sm")]: {
    width: "auto",
  },
}));

export const SitsCount = styled("div")(({ theme }) => ({
  color: "#484848",
  fontSize: 11,
}));

export const AirlineLogo = styled("img")(({ theme }) => ({
  width: 90,
  height: 30,
}));

export const FlightNumbersBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  alignItems: "center",
  justifyContent: "center",
  gap: 5,
  width: 60,

  [theme.down("sm")]: {
    width: "auto",
  },
}));

export const FlightNumber = styled("div")(({ theme }) => ({
  color: "#151515",
  fontSize: 13,
  lineHeight: 1,
}));

export const FlightTimingsBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  justifyContent: "space-between",
  flexGrow: 1,
  gap: 30,

  [theme.down("sm")]: {
    width: "100%",
    order: 4,
    gap: 15,
  },
}));

export const DepartureBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  color: "#151515",

  "& > span.airport": {
    fontSize: 13,
    lineHeight: "10px",
  },

  "& > span.time": {
    fontSize: 18,
  },

  "& > span.date": {
    fontSize: 11,
    lineHeight: "10px",
  },
}));

export const DurationBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  flexGrow: 1,
  gap: 5,

  "& > div.duration-time": {
    color: "#484848",
    fontSize: 11,
    textAlign: "center",
  },

  "& > div.flight-line": {
    ...theme.mixins.flexAlignCenter,
    borderRadius: 2.5,
    height: 5,

    "& > div": {
      backgroundColor: "#dfe5ec",
      borderRadius: 2,
      margin: "0 1px",

      "&:hover": {
        backgroundColor: "#ff8100",
        cursor: "default",
      },
    },
  },

  "& > div.segments": {
    fontSize: 10,
    fontWeight: 600,
    color: "#9e9e9e",
    textAlign: "center",
  },
}));

export const ArrivalBlock = styled(DepartureBlock)(({ theme }) => ({
  textAlign: "right",
}));

export const TariffItem = styled("div")(({ theme }) => ({
  ...theme.paddings(10, 0),
  ...theme.mixins.flexColumn,
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  border: "2px solid transparent",
  cursor: "pointer",

  "&.single": {
    ...theme.paddings(0),
  },

  "&.active": {
    borderColor: "#ff8100",
  },

  "&.single.active": {
    borderColor: "transparent",
  },
}));

export const TariffItemPrice = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: "#777",
  fontWeight: 700,
  textTransform: "uppercase",
  paddingBottom: 5,
}));

export const TariffItemIcons = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  gap: 3,
  fontSize: 26,
  lineHeight: 1,
  color: "#0b4da2",

  "& .notActive": {
    color: "#aaa",
  },

  "& > div": {
    display: "flex",
    position: "relative",
  },

  "& .hand-luggage-weight": {
    position: "absolute",
    top: 9,
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1,
  },

  "& .baggage-weight": {
    position: "absolute",
    top: 8,
    left: 7,
    width: 12,
    height: 12,
    textAlign: "center",
    backgroundColor: "#fff",
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: 1,
  },
}));

export const TariffItemDetails = styled("div")(({ theme }) => ({
  fontSize: 10,
  color: "#777",
  borderBottom: "1px solid #333",
}));

export const TariffItemTitle = styled(TariffItemPrice)(({ theme }) => ({
  paddingBottom: 0,
}));

export const DetailsAndRulesBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  position: "relative",
  height: 33,
  paddingTop: 10,
  paddingBottom: 5,
}));

export const DetailsButton = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  border: "none",
  backgroundColor: "transparent",
  color: "#358ed3",
  fontSize: 13,
  lineHeight: 1,
  cursor: "pointer",

  "& > span": {
    fontSize: 11,
    lineHeight: 1,
  },

  "& > span.active": {
    transform: "rotate(90deg)",
  },
}));

export const RulesButton = styled("button")(({ theme }) => ({
  position: "absolute",
  right: 10,
  bottom: 5,
  fontSize: 10,
  border: "none",
  backgroundColor: "transparent",
  textDecoration: "underline",

  "&:hover": {
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export const FlightDetails = styled("div")(({ theme }) => ({
  ...theme.mixins.flexColumn,
  gap: 10,
  padding: 10,
}));

export const FlightSegment = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 25,
  backgroundColor: "#4086e40a",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .5)",
  padding: 10,
  color: "#333",
  fontSize: 12,

  [theme.down("sm")]: {
    flexWrap: "wrap",
  },

  "& > .flightInfo": {
    flexGrow: 1,

    [theme.down("sm")]: {
      order: 3,
      width: "100%",
    },
  },

  "& > .baggageCabinInfo": {
    textTransform: "capitalize",
    minWidth: 80,
    textAlign: "center",

    [theme.down("sm")]: {
      order: 2,
    },
  },
}));

export const FlightSegmentTimeAircraft = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: 10,

  "& > .flightAndDuration": {
    ...theme.mixins.flexAlignCenter,
    gap: 10,

    "& > .duration": {
      fontSize: 10,
    },
  },
}));

export const FlightSegmentTimings = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 20,
  width: "100%",

  "& > .flightTime": {
    color: "#333",
    fontSize: 14,

    "& > span": {
      color: "#9f9f9f",
      fontSize: 12,
      paddingLeft: 5,
    },
  },

  "& > .flightPlace": {
    display: "flex",
    gap: 10,

    "& > .flightPlaceCode": {
      fontSize: 12,
      color: "#151515",
      textTransform: "uppercase",
    },

    "& > .flightPlaceName": {
      ...theme.mixins.flexColumn,

      "& > span": {
        color: "#9f9f9f",
        fontSize: 12,
        lineHeight: "20px",
      },
    },
  },
}));

export const FlightSegmentIcons = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  gap: 3,
  paddingTop: 5,
  fontSize: 20,
  lineHeight: 1,
  color: "#0b4da2",

  "& .hasNotBaggage": {
    color: "#cd0f0f",
  },
}));

export const TransferBlock = styled("div")(({ theme }) => ({}));

export const TransferTime = styled("div")(({ theme }) => ({
  color: "#358ed3",
  fontSize: 14,
}));
